//content for navbar

export default [
	{
		name: 'Contacts',
		icon: 'fa fa-users'
	},
	{
		name: 'Chats',
		icon: 'fa fa-comments'
	},
	{
		name: 'Settings',
		icon: 'fa fa-cog'
	}
]
