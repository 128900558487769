<template>
  <div class="singleNotif">
    <span class="text-danger dot">●</span>
    <img src="../assets/images/notif.svg" alt="" />
    <div class="content mt-2">
      <h6 class="p-0 m-0 ps-2">
        {{ notif.desc }}
        <span v-show=" notif.book_id != null" class="more" @click="goToApproval">See More</span>
      </h6>
      <span class="ps-2 pt-1">{{
        calculateRelativeTime(notif.created_at)
      }}</span>
    </div>
  </div>
  <!-- <div class="singleNotif">
    <span class="text-danger dot">●</span>
    <img src="../assets/images/notif.svg" alt="" />
    <div class="content mt-2">
      <h6 class="p-0 m-0 ps-2">test desc</h6>
      <span class="ps-2 pt-1">22/20/2022</span>
    </div>
    <span class="more" @click="goTo">See More</span>
  </div> -->
  <div v-show="notif.book && notif.book?.status == 'waiting' && isPending" class="pending mb-2">
    <button @click="changeStatus('accept')" class="approve">
      <img src="../assets/images/done.svg" alt="" />
      <span>Approve</span>
    </button>
    <button @click="changeStatus('decline')" class="decline">
      <img src="../assets/images/close.svg" alt="" />
      <span>Decline</span>
    </button>
  </div>
</template>

<script>
// import {  } from 'vue';
import { useStore } from "vuex";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

export default {
  props: {
    notif: Array,
  },
  setup(props) {
    const store = useStore();
    //eslint-disable-next-line
    var { accessToken, authenticated } = useAccessToken(store);

    // const route = useRoute();
    const router = useRouter();
    // var id = route.params.id;
    const isLoading = ref(false);
    const isPending = ref(true);

    const myUserId = computed(() => {
      return store.state.userId;
    });
    function calculateRelativeTime(timestamp) {
      const currentDate = new Date();
      const notificationDate = new Date(timestamp);
      const timeDifference = currentDate - notificationDate;
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);

      if (seconds < 60) {
        return `${seconds} seconds ago`;
      } else if (minutes < 60) {
        return `${minutes} minutes ago`;
      } else if (hours < 24) {
        return `${hours} hours ago`;
      } else if (days < 7) {
        return `${days} days ago`;
      } else {
        return `${weeks} weeks ago`;
      }
    }
    function changeStatus(status) {
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      isLoading.value = true;

      axios({
        method: "POST",
        url: `https://api.atdeloop.com/api/book/change-status/${props.notif.book_id}`,
        data: {
          status: status,
        },
      })
        .then(function (response) {
          isLoading.value = false;

          if (response.status === 200) {
           isPending.value = false

            sendAlert("changeStatus  ", "success");
          }
        })
        .catch(function (error) {
          // //Loading.remove();
          isLoading.value = false;

          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }
    function goToApproval() {
      if (
        props.notif.book_id != null &&
        props.notif.book.rental.user_id == myUserId.value
      ) {
        router.push({
          name: "ApproveRequestPage",
          params: { bookId: props.notif.book_id },
        });
      } else if (props.notif.book.rental.kind == "rental") {
        router.push({
          name: "BookedRental",
          params: { id: props.notif.rental.id },
        });
      } else if (props.notif.book.rental.kind == "request") {
        router.push({
          name: "BookedRequest",
          params: { id: props.notif.rental.id },
        });
      }
    }

    return { calculateRelativeTime, changeStatus, goToApproval ,isPending};
  },
};
</script>
<style lang="scss" scoped>
.singleNotif {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: center;

  padding: 11px 23px;
  gap: 12px;
  border-top: solid 1px #ccc;

  img {
    width: 31px;
    height: 31px;
    flex-shrink: 0;
  }

  .dot {
    font-size: 8px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    // align-items: center;
    h6 {
      color: #6f6e6e;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    span {
      color: #9a9a9a;
      font-family: Inter;
      font-size: 7px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.pending {
  display: flex;
  justify-content: center;
  gap: 5%;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    min-width: 88px;
    border-radius: 10px;
    height: 19px;
  }
}
.approve {
  background-color: #64a100;
  border: none;
  font-size: 9px;
  font-weight: 700;
  color: #ffffff;
}
.decline {
  background-color: #d9d9d9;
  border: none;
  font-size: 9px;
  font-weight: 700;
  color: #646464;
}
.more {
  color: #5d9600 !important;
  text-align: justify !important;
  font-family: Inter !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  text-decoration-line: underline;
  cursor: pointer;
}
</style>
