<template>
  <div class="page position-relative h-100 bg-white">
    <base-header
      :pageTitle="pageTitle"
      :leftArrowClick="goToSpecialRoute"
    ></base-header>
    <!-- <base-nav></base-nav> -->
    <div class="h-100 d-flex justify-content-between titleBox py-3 px-2">
      <h3 class="text-center">Total view</h3>
      <span>{{ total || 0 }}</span>
    </div>

    <div class="mainContent">
      <div class="d-flex justify-content-center align-items-center w-100 mt-4">
        <Line ref="chart" :data="chartData" :options="options" />
      </div>
      <div class="d-flex justify-content-center" style="max-width: 100%"></div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */

import router from "@/router";
import { ref, computed, onMounted, reactive, toRefs } from "vue";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
import { useRoute, useRouter } from "vue-router";
import "leaflet/dist/leaflet.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
const pageTitle = ref("Add view Status");
const store = useStore();
//eslint-disable-next-line
var { accessToken, authenticated } = useAccessToken(store);
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import { set } from "@vueuse/core";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const labels = ref([]);
const data = ref([]);
const chart = ref(null);
const chartData = computed(() => ({
  labels: labels.value,
  datasets: [
    {
      label: "views",
      backgroundColor: "#72B700",
      data: data.value,
      steppedLine: "after",
      borderColor: "#7e9b1e",
      fill: true,pointStyle: 'circle', // Set point style to circle
      pointBackgroundColor: '#fff'
    },
  ],
  options: {
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid lines
        },
      },
      y: {
        grid: {
          display: false, // Hide y-axis grid lines
        },
        ticks: {
          stepSize: 1, // Set the step size between ticks to 1
        },
      },
    },
  },
}));

const total = computed(() => {
  let total = 0;
  data.value.forEach((value) => {
    total += value;
  });
  return total;
});
const options = {
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
        tickMarkLength: 1, borderWidth: 2
        // Hide x-axis grid lines
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false, borderWidth: 2 // Hide y-axis grid lines
      },
      ticks: {
        stepSize: 1, // Set the step size between ticks to 1
      },
      axis: {
          borderWidth: 2 // Set the width of y-axis line
        }
    },
  },
  plugins: {
      tooltip: {
        bodyFontStyle: 'normal',
         backgroundColor: '#76b20e', // Set background color of tooltip to transparent
      }
    }
};
const route = useRoute();
const goToSpecialRoute = () => {
  router.go(-1);
};
const isLoading = ref(false);

function loadChart() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  isLoading.value = true;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/profile-rentals-request/visit-chart/${route.params.id}`,
  })
    .then(function (response) {
      isLoading.value = false;

      if (response.status === 200) {
        const newLabels = Object.keys(response.data);
        const newData = Object.values(response.data);

        data.value = newData;
        labels.value = newLabels;
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}

onMounted(() => {
  loadChart();
});
</script>

<style lang="scss" scoped>
.titleBox {
  border-bottom: solid 1.5px #b3b3b3;
  h3 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  span {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.mainContent {
  .graph {
    width: 280px;
  }
}
</style>
