<template>
  <div class="page position-relative h-100">
    <base-header :pageTitle="pageTitle"
    :leftArrowClick="goToSpecialRoute"
    ></base-header>
    <!-- <base-nav></base-nav> -->
    <div class="h-100 d-flex justify-content-end selectCategory">
      <h3 class="w-100 text-center">Please select the category</h3>
    </div>

    <div class="mainContent">
      <div class="requestBox d-flex row">
        <div v-for="(category, index) in categories" 
        :key="index"
         class="col-6 d-flex justify-content-center align-items-center mb-4">
        <feature-button
          
          
          @click="setCategory(category)"
          :title="category.title"
          :size="category.size"
          :imgSrc="category.img_url"
          color="grey"
        ></feature-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from "vue";
// import axios from "axios";
// import { useAccessToken } from "@/components/composable/useAccessToken";
//eslint-disable-next-line
// import sendAlert from "@/components/composable/useSendAlert";
// import serverError from "@/components/composable/useServerError";
import router from "@/router";  
export default {
  props:{
    categories:Array
  },
  setup(props, { emit }) {
    const pageTitle = ref("Send a Request");
    //     const categories = [
    //   { label: 'Tools', title: 'Tools', size: 'medium', imgSrc: '../assets/images/request/Tools.svg' },
    //   { label: 'Industrial machinery', title: 'Industrial machinery', size: 'medium', imgSrc: '../assets/images/request/Foundation Drilling Rig.svg' },
    //   { label: 'Business Equipment', title: 'Business Equipment', size: 'medium', imgSrc: '../assets/images/request/Business.svg' },
    //   { label: 'Digital Goods', title: 'Digital Goods', size: 'medium', imgSrc: '../assets/images/request/Cryptocurrency Website.svg' },
    //   { label: 'Kitchen and Home Appliances', title: 'Kitchen and Home Appliances', size: 'medium', imgSrc: '../assets/images/request/Appliances.svg' },
    //   { label: 'Agricultural equipment', title: 'Agricultural equipment', size: 'medium', imgSrc: '../assets/images/request/Tractor.svg' },
    // ];
    function setCategory(category) {
      emit("setCategory", category);
    }
    var items = reactive({ categories: [] });
    function goToSpecialRoute() {
      router.go(-1);
    }
    // function loadCategory() {
    //   // if (authenticated) {
    //   // Loading.pulse();
    //   // isLoading.value = true;

    //   axios.defaults.headers.common["Content-Type"] = "application/json";
    //   axios.defaults.headers.common["Accept"] = "application/json";
    //   axios.defaults.headers.common[
    //     "Authorization"
    //   ] = `Bearer 63|X7A1NTgsCenDXYrLFwh6KUId8l10OQvHpG3AGMFZc5f0c2f0`;

    //   axios({
    //     method: "POST",
    //     url: "https://api.atdeloop.com/api/category",
    //   })
    //     .then(function (response) {
    //       // Loading.remove();
    //       // isLoading.value = false;

    //       if (response.status === 200) {
    //         // 
    //         sendAlert("category loaded ", "success");

    //         items.categories = response.data;
    //       }

    //       // items.first_name = response.data.first_name;
    //       // items.last_name = response.data.last_name;
    //       // items.address = response.data.address;
    //       // items.city = response.data.city;
    //       // items.phone = response.data.phone;
    //       // items.avatar_URL=response.data.
    //     })
    //     .catch(function (error) {
    //       // //Loading.remove();
    //       // isLoading.value = false;

    //       
    //       //sendAlert(error.response.data, "error");
    //       serverError(error);
    //     });
    // }

    onMounted(() => {
      // loadCategory();
    });
    // You can include any other setup logic here

    return {
      pageTitle,
      setCategory,
      // loadCategory,
      items,goToSpecialRoute
    };
  },
};
</script>

<style lang="scss" scoped>
.selectCategory {
  h3 {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 30px;
  }
}
.requestBox {
  padding: 39px;
}
.mainContent {
}
</style>
