<template>
  <div class="position-relative h-100 bg-white">
    <loading :active="isLoading" :is-full-page="true"></loading>

    <main ref="container" class="chatContainer py-12 h-100 d-flex flex-column">
      <chat-header>
        <template v-slot:start>
          <div class="d-flex gap-3 justify-content-center">
            <!-- <i
            
            class="fa fa-chevron-left mr-5 text-xl active:scale-90 duration-300"
            
          ></i> -->
            <img
              style="cursor: pointer"
              class=""
              @click="goToSpecialRoute"
              src="../../../assets/images/back.svg"
              alt=""
            />
            <img
              style="min-width: 54px"
              class="rounded-circle img-fluid"
              src="../../../assets/images/profile1.jpg"
              width="40"
            />
                          <!-- v-if="profile[0].avatar !== null" -->

            <!-- TODO :src="profile[0].avatar" -->
            <!-- <span
              style=""
              class="d-grid justify-content-center align-items-center bg-primary py-3 px-3 font-weight-bold rounded"
            >
          </span> -->
          <!-- {{ profile[0].initial }} -->
            
            <div class="d-flex">
              <h1 class="profileName m-0 d-flex align-items-center">
                {{ items.contact.first_name }}
              </h1>
              <!-- <small class="text-green-500">{{ profile[0].status }}</small> -->
              <small class="text-green-500 visually-hidden">online</small>
            </div>
          </div>
        </template>

        <template v-slot:end>
          <div class="text-xl">
            <img src="../../../assets/images/phone.svg" alt="" />
            <!-- <i class="active:scale-90 duration-300 fa fa-video d-block "></i> -->
          </div>
        </template>
      </chat-header>

      <RoomChat ref="container" />

      <ChatAction v-on:newChat="scrollToBottom" />
    </main>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted, computed,reactive } from "vue";
import { useContacts } from "../stores/contacts";
import { useChats } from "../stores/chats";
import ChatHeader from "../components/ChatHeader.vue";
import ChatAction from "../components/ChatAction.vue";
import RoomChat from "../components/RoomChat.vue";
import { useStore } from "vuex";

import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
export default {
  components: {
    ChatHeader,
    RoomChat,
    ChatAction,
    Loading,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    //eslint-disable-next-line
    var { accessToken, authenticated } = useAccessToken(store);
    const container = ref(null);
    const isLoading = ref(false);

    const state = useContacts();

    const paramsId = computed(() => route.params.id);
    const profile = computed(() =>
      state.contacts.filter((e) => e.id == paramsId.value)
    );
    var items = reactive({contact:[]})
    function goToSpecialRoute() {
      router.push({ name: "Chathome" });
    }
    function loadMessages() {
      // if (authenticated) {
      // Loading.pulse();
      isLoading.value = true;
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/message/conversation-detail",
        data: {
          conversation_id: paramsId.value,
        },
      })
        .then(function (response) {
          // Loading.remove();
          isLoading.value = false;

          if (response.status === 200) {
            //
            // sendAlert("messages loaded ", "success");
            // store.commit("setProfile", response.data);
            useChats().setChats(response.data.message);
            items.contact = response.data.user;
          }
        })
        .catch(function (error) {
          isLoading.value = false;
          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }

    onMounted(() => {
      scrollToBottom();
      loadMessages();
    });

    const scrollToBottom = () => {
      let el = container.value;
      el.scrollIntoView(false, {
        behavior: "smooth",
        block: "end",
      });
      state.contacts.filter((e) => e.id == paramsId.value).unReadMessage = 0;
    };

    return {
      container,
      profile,
      scrollToBottom,
      loadMessages,
      goToSpecialRoute,
      isLoading,items
    };
  },
};
</script>
<style lang="scss" scoped >
.chatContainer {
  height: 100% !important;
  height: 100vh !important;
}
.profileName {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.chathome {
  min-height: 100vh;
}
</style>
