<template>
  <div class="image-selector">
    <div
      v-for="(image, index) in items.images"
      :key="index"
      class="d-flex align-items-center justify-content-center col-4 p-0 position-relative"
    >
      <div
        class="image-container"
        :class="{ uploadedImgStyle: items.images[index] }"
        @mouseover="toggleHover(index, true)"
        @mouseleave="toggleHover(index, false)"
      >
        <img
          class="img-fluid"
          :src="image || placeholderImage"
          :id="index"
          alt="Selected Image"
          @click="selectImage(index)"
        />
        <div
          v-if="hoveredIndex === index && items.images[index]"
          class="image-overlay"
        >
          <img
            src="../assets/images/trash.svg"
            class="trash-icon"
            @click="removeImage(index)"
            alt="Remove Image"
          />
        </div>
        <input
          type="file"
          @change="handleFileChange(index)"
          ref="fileInputRefs"
          style="display: none"
          :id="'inputFile' + index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, watch } from "vue";

export default {
  props: {
    gallery: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    // const images = ref([null, null, null, null, null, null]); // Initialize with empty slots
    const placeholderImage = require("../assets/images/addimage.svg"); // Replace with your placeholder image path
    const fileInputRefs = ref();
    var items = reactive({ images: props.gallery });
    const hoveredIndex = ref(null);

    const selectImage = (index) => {
      const fileInput = fileInputRefs.value[index];

      if (fileInput) {
        // Access the underlying DOM element and trigger the click event
        fileInput.click();
      }
    };
    const toggleHover = (index, isHovered) => {
      hoveredIndex.value = isHovered ? index : null;
    };
    const removeImage = (index) => {
      items.images[index] = null;
    };
    const handleFileChange = (index) => {
      const fileInput = fileInputRefs.value[index];
      const file = fileInput.files[0];

      if (file) {
        emit("setuploadedFile", fileInput.files[0], index);
        // Update the selected image with the chosen file
        const reader = new FileReader();
        reader.onload = () => {
          items.images[index] = reader.result;
          emit("setuploadedImg", items.images[index], index);
        };
        reader.readAsDataURL(file);
      }
    };
    watch(
      () => props.gallery,
      (newValue) => {
        items.images = newValue;
      }
    );
    onMounted(() => {
      items.images = props.gallery;
      // setTimeout(() => {

      // }, 5000);
      // document
      //   .getElementById("uploadButton")
      //   .addEventListener("click", function () {
      //     document.getElementById("fileInput").click();
      //   });
    });

    return {
      items,
      placeholderImage,
      selectImage,
      handleFileChange,
      fileInputRefs,
      toggleHover,
      hoveredIndex,
      removeImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-selector {
  display: flex;
  flex-wrap: wrap;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 94px;
  /* margin: 10px; */
  cursor: pointer;
  border-radius: 10px;
  background: #dedcdc;
  margin-bottom: 10px;
}

img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  /* border: 2px solid #ccc;
  border-radius: 8px; */
  height: 50px;
  width: 50px;
}

input[type="file"] {
  display: none;
}
.uploadedImgStyle {
  background-color: white;
  img {
    width: initial;
    height: auto;
  }
}
.removeImg {
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 2;
  font-size: 16px;
  color: red;
}
.image-overlay {
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-overlay:hover {
  opacity: 1;
}

.trash-icon {
  width: 24px; /* Adjust the size as needed */
  height: 24px;
  cursor: pointer;
}
</style>
