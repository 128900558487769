<template>
  <main class="bg-white">
    <!-- <section
      class="bg-primary items-center p-5 w-full md:px-28 lg:p-5 lg:w-4/12 md:mx-auto xl:w-3/12 px-5 flex justify-between"
    > -->
    <!-- <div
        class="w-10/12 text-gray-100 bg-secondary px-3 py-1 flex items-center rounded-xl"
      > -->
    <!-- <i class="active:scale-90 duration-300 fa fa-link mr-3 text-sm"></i>
        <textarea
          rows="2"
          v-model="textField"
          type="text"
          placeholder="Your message"
          class="bg-secondary w-full px-2 py-1 rounded-xl"
        ></textarea>
      </div>
      <span> -->
    <!-- <EmojiPicker show_arrow:true></EmojiPicker> -->

    <!-- <i
          v-if="textField === ''"
          class="active:scale-90 duration-300 fa fa-microphone text-indigo-600 text-3xl"
        ></i> -->
    <!-- </span> -->
    <!-- </section> -->
    <section class="position-relative">
      <EmojiPicker
        :text="textField.value"
        theme="light"
        picker-type="textarea"
        @select="onSelect"
        @update:text="onChangeText"
        :disabled-groups="[
          'animals_nature',
          'food_drink',
          'activities',
          'travel_places',
          'objects',
          'symbols',
          'flags',
        ]"
        disable-skin-tones
        disable-sticky-group-names
        :disabled="true"
      ></EmojiPicker>
      <div class="rightActionContainer d-flex gap-0">
        <img
          src="../../../assets/images/microphone.svg"
          @click="newChat"
          class="right-0 sendVoice img-fluid active:scale-90 duration-300 fa fa-paper-plane text-indigo-600 text-3xl"
        />
        <img
          src="../../../assets/images/send.svg"
          @click="sendMessage"
          @keyup.enter="sendMessage"
          class="right-0 sendIcon img-fluid active:scale-90 duration-300 fa fa-paper-plane text-indigo-600 text-3xl"
        />
      </div>
    </section>
  </main>
</template>

<script>
import "vue3-emoji-picker/css";
import { useChats } from "../stores/chats";
import { useContacts } from "../stores/contacts";
import { useRoute } from "vue-router";
import { reactive, computed, onMounted, ref } from "vue";
import EmojiPicker from "vue3-emoji-picker";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";//eslint-disable-next-line
// import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";

export default {
  components: {
    EmojiPicker,
  },
  setup(props, { emit }) {
    // const emits = defineEmits(['newChat']);
    const chats = useChats();
    const contacts = useContacts();
    const route = useRoute();
    const textField = reactive({ value: "" });
    const textArea = ref(null);
    const store = useStore();
//eslint-disable-next-line
var { accessToken, authenticated } = useAccessToken(store);

    const paramsId = computed(() => route.params.id);

    const newChat = () => {
      if (textField.value.split("").length > 0) {
        chats.addingNewChat({
          text: textField.value,
          // left: Boolean(Math.floor(Math.random() * 2)),
          left: true,
          id: paramsId.value,
        });

        contacts.setLastMessage(textField.value, paramsId.value);
        emit("newChat");
        textField.value = "";

        emit("update:text", "");
        clearTextArea();
        setTimeout(() => {
          scrollDown();
        }, 100);
      }
    };
    // @param new_text/
    function onChangeText(new_text) {
      textField.value = new_text || "";
    }
    function sendMessage() {
      if (!textField.value == "") {
        // if (authenticated) {
        // Loading.pulse();
        // isLoading.value = true;
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Accept"] = "application/json";
          axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

        axios({
          method: "POST",
          url: "https://api.atdeloop.com/api/message/send-msg",
          data: {
            conversation_id: paramsId.value,
            msg_body: textField.value,
          },
        })
          .then(function (response) {
            // Loading.remove();
            // isLoading.value = false;

            if (response.status === 200) {
              newChat();
              //
              // sendAlert("messages loaded ", "success");
              // store.commit("setProfile", response.data);
              // useChats().setChats(response.data);
            }
          })
          .catch(function (error) {
            //sendAlert(error.response.data, "error");
            serverError(error);
          });
      }
    }
    function scrollDown() {
      var roomChat = document.getElementById("roomChat");
      roomChat.scrollTop = roomChat.scrollHeight;
    }

    onMounted(() => {
      textArea.value = document.querySelector(".v3-emoji-picker-textarea");
      if (textArea.value) {
        textArea.value.addEventListener("input", handleInput);
        textArea.value.addEventListener("keydown", (event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            sendMessage();
          }
        });
      }
    });
    const handleInput = () => {
      // Do something when the input event is triggered
      resizeTextArea();
    };

    const additionalGroups = {
      my_custom_group: [
        { n: ["grinning face", "grinning"], u: "1f600" },
        { n: ["grinning face with smiling eyes", "grin"], u: "1f601" },
      ],
    };
    const clearTextArea = () => {
      var textArea = document.querySelector(".v3-emoji-picker-textarea");
      if (textArea) {
        textArea.value = "";
        resizeTextArea();
      }
    };
    function resizeTextArea() {
      var textArea = document.querySelector(".v3-emoji-picker-textarea");
      textArea.style.height = "20px";

      textArea.style.height = textArea.scrollHeight + "px";
    }

    onMounted(() => {
      var inputPickerIcon = document.querySelector(".v3-input-picker-icon img");

      inputPickerIcon.src = require("@/assets/images/Expressionless.svg");
    });

    return {
      textField,
      newChat,
      onChangeText,
      additionalGroups,
      clearTextArea,
      sendMessage,
    };
  },
};
</script>
<style lang="scss" scoped >
main {
  padding: 29px 25px;
  position: sticky;
  right: 0;
  left: 0;
  bottom: 0;
}
section {
  /* min-height: 55px !important; */
}
.rightActionContainer {
  position: absolute;
  right: 3%;
  bottom: 50%;
  transform: translateY(50%);
  margin-bottom: 3px;
}
.sendIcon {
  cursor: pointer;
  /* position: absolute;
  right: 3%;
  bottom: 50%;
  transform: translateY(50%); */
  padding: 8px;
  background-color: #70b400;
  border-radius: 100%;
}
.sendVoice {
  cursor: pointer;

  /* position: absolute;
  right: 15%;
  bottom: 50%;
  transform: translateY(50%); */
  padding: 8px;
  border-radius: 100%;
}
::v-deep
  .v3-input-emoji-picker
  .v3-input-picker-root
  .v3-emoji-picker-textarea {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  height: 52px;
  min-height: initial !important;
  border-radius: 30px;
  background: #d9d9d9;
  padding-left: 45px;
  padding-right: 90px;
  padding-top: 13px;
  padding-bottom: 15px;
  border: none;
  resize: none;
}

::v-deep
  .v3-input-emoji-picker
  .v3-input-picker-root
  .v3-emoji-picker-textarea
  + .v3-input-picker-wrap
  .v3-input-picker-icon {
  /* margin-top: 20px; */
  top: 50%;
  bottom: 5px;
  left: 16px;
  right: initial;
}
:deep(.v3-input-picker-icon img) {
  width: 18px !important;
  height: 18px !important;
}
</style>
