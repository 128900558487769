<template>
  <loading :active="isLoading" :is-full-page="true"></loading>

  <div class="page position-relative h-100">
    <!-- <base-header :pageTitle="pageTitle"></base-header> -->
    <!-- <base-header :pageTitle="pageTitle"
    :leftArrowClick="goToSpecialRoute"
    ></base-header> -->
    <button class="leftButton" @click="goToSpecialRoute">
      <span>
        <img class="leftArrowBtn" src="../assets/images/LeftArrow.svg" alt="" />
      </span>
    </button>

    <div class="bannerImage position-relative">
      <!-- <img
        class="img-fluid"
        src="../assets/images/rental-item-banner.svg"
        alt=""
      /> -->
      <button class="fullScreenBtn rounded-4">
        <span>
          <img src="../assets/images/full-screen.svg" alt="" />
          <span class="text-white fs11 px-2">{{
            items.rental?.gallery?.length
          }}</span>
        </span>
      </button>

      <div class="carouselBox">
        <Carousel :wrap-around="true">
          <Slide
            style="cursor: pointer"
            v-for="(slide, index) in items.rental.gallery"
            :key="index"
          >
            <div class="carousel__item w-100">
              <img
                :src="slide"
                alt=""
                class="img-fluid w-100 bRadios"
                style="max-height: 250px"
                @click="openModal(slide)"
              />
            </div>
          </Slide>

          <template #addons>
            <Pagination />
          </template>
        </Carousel>
        <img
          v-if="!galleryLength"
          src="../assets/images/gallery/no-photo.jpg"
          alt=""
          class="img-fluid w-100"
          style="max-height: 250px"
        />
      </div>
    </div>
    <div class="mainContent">
      <div class="toolsContainer">
        <div class="d-flex">
          <img src="../assets/images/back.svg" alt="" />
          <span>Tools</span>
        </div>
        <div class="d-flex">
          <span class="bookmarkBtn" @click="setBookmark">
            <img
              v-if="isBookmarked"
              src="../assets/images/bookmarked.svg"
              alt=""
            />
            <img v-else src="../assets/images/bookmark.svg" alt="" />
          </span>
          <span class="shareBtn" @click="copyUrl">
            <img src="../assets/images/share.svg" alt="" />
          </span>
        </div>
      </div>

      <div class="itemDetails">
        <h2 class="fw-700 fs-10">{{ items.rental.title }}</h2>

        <p class="m-0">
          {{ items.rental.description }}
        </p>

        <span class="more mt-2">More</span>
        <div class="priceContainer">
          <span class="priceTitle"> One day price:</span>
          <span class="priceValue"> {{ items.rental.price_per_day }} $ </span>
        </div>

        <div><span class="locationTitle">Location:</span></div>
        <!-- <div class="mt-1">
          <img src="../assets/images/map.svg" alt="" />
        </div> -->
        <div class="mapBox">
          <div style="height: 135px" class="mt-2">
            <LMap
              v-model:zoom="zoom"
              :center="getMapCenter"
              @move="log('move')"
            >
              <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              ></l-tile-layer>
              <!-- <l-control-layers /> -->
              <l-marker
                :lat-lng="getMapCenter"
                :draggable="false"
                @moveend="log('moveend')"
              >
                <l-tooltip> marker</l-tooltip>
              </l-marker>

              <!-- <l-marker :lat-lng="[47.41322, -1.219482]">
              <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
            </l-marker> -->

              <!-- <l-marker :lat-lng="[50, 50]" draggable @moveend="log('moveend')">
              <l-popup> lol </l-popup>
            </l-marker> -->
            </LMap>
          </div>
        </div>

        <div class="mt-2 calendarBox">
          <!-- <img src="../assets/images/calender.svg" alt="" /> -->
          <div class="reservationStatus">
            <p
              v-if="
                !date[0] ||
                !date[1] ||
                !(
                  items.bookRental?.book?.length == 0 ||
                  items.bookRental?.book?.user_id !== myUserId
                )
              "
              class="text-danger fw-bold"
            >
              The reservation date has not been selected
            </p>
          </div>
          <!-- <VueDatePicker
            v-model="date"
            inline
            range
            
            month-name-format="short"
            :enable-time-picker="false"
          ></VueDatePicker> -->
          <VueDatePicker
            :class="
              !(
                items.bookRental?.book?.length == 0 ||
                items.bookRental?.book?.user_id !== myUserId
              )
                ? 'greenSelected'
                : ''
            "
            :min-date="new Date()"
            :model-value="date"
            @update:model-value="setDate"
            inline
            range
            :readonly="
              !(
                items.bookRental?.book?.length == 0 ||
                items.bookRental?.book?.user_id !== myUserId
              )
            "
            auto-apply
            :disabled-dates="reservedDays"
            :preset-dates="presetDates"
            month-name-format="short"
            :enable-time-picker="false"
            @before-update="beforeUpdateHandler"
            focusStartDate
            @day-cell-content="dayCellContent"
          >
          </VueDatePicker>
          <!-- :disabled-dates="disabledDates" -->
        </div>
        <div class="d-flex align-items-center justify-content-between mt-3">
          <span class="d-flex total">Total:</span>
          <base-form readonly :placeholder="calculatedTotal"> </base-form>
        </div>

        <base-button
          v-if="items.bookRental?.book?.length == 0"
          @click="bookRental"
          class="mt-3 bookBtn"
          >Book Now</base-button
        >
        <base-button
          v-if="
            !(items.bookRental?.book?.length == 0) &&
            items.bookRental?.book?.user_id == myUserId &&
            items.bookRental?.book?.status == 'waiting'
          "
          class="mt-3 bookBtn"
          >Waiting</base-button
        >
        <base-button
          @click="pay"
          v-if="
            !(items.bookRental?.book?.length == 0) &&
            items.bookRental?.book?.user_id == myUserId &&
            items.bookRental?.book?.status == 'accept'
          "
          class="mt-3 bookBtn"
          >Pay Now</base-button
        >
        <base-button
          v-if="
            !(items.bookRental?.book?.length == 0) &&
            items.bookRental?.book?.user_id == myUserId &&
            items.bookRental?.book?.status == 'pay'
          "
          class="mt-3 bookBtn"
          >Paid Successfully</base-button
        >
        <base-button
          v-if="
            !(items.bookRental?.book?.length == 0) &&
            items.bookRental?.book?.user_id == myUserId &&
            items.bookRental?.book?.status == 'decline'
          "
          class="mt-3 bookBtn"
          >Declined</base-button
        >
        <div class="d-flex mt-3 gap-1">
          <base-button class="halfBtn">Call</base-button>
          <base-button class="halfBtn" @click="chatWithUser">
            Chat Now</base-button
          >
        </div>
      </div>
    </div>

    <!-- <base-nav></base-nav> -->
  </div>
</template>
components: { Loading, },
<script setup>
import { ref, computed, onMounted, reactive } from "vue";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
import { useRoute, useRouter } from "vue-router";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet";
import { Carousel, Slide } from "vue3-carousel";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

//eslint-disable-next-line
const imageSrc = computed(() => require(`@/${props.image}`));
const galleryLength = computed(() => {
  return items.rental?.gallery?.length;
});
// const pageTitle = ref("Transactions");
const store = useStore();
//eslint-disable-next-line
var { accessToken, authenticated } = useAccessToken(store);
const route = useRoute();
const router = useRouter();
var id = route.params.id;
// const currentSlide = ref(0);
// function slideTo(val) {
//   currentSlide.value = val;
// }
const myUserId = computed(() => {
  return store.state.userId;
});
var items = reactive({ rental: [], slides: [], bookRental: [] });
// const slides = reactive({ img_url: [1, 2, 3, 4, 5] });
const dayCellContent = ({ date, disabled }) => {
  // console.log("hello");
  // Check if the date is disabled
  if (disabled) {
    // Check if the date is in the reservedDays array
    const isReserved = reservedDays.value.some(
      (d) => d.getTime() === date.getTime()
    );
    if (isReserved) {
      // Apply custom style for reserved days
      return { class: "reserved-day" };
    }
  }
};
const isBookmarked = ref(false);
const isLoading = ref(false);

const zoom = ref(7);
const date = ref([new Date(), null]);
const reservedDays = ref([null, null]);
//eslint-disable-next-line
const log = (a) => {};
function pay() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  isLoading.value = true;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/book/pay/${items.bookRental.book.id}`,
  })
    .then(function (response) {
      isLoading.value = false;

      if (response.status === 200) {
        //
        sendAlert("pay succesfully  ", "success");
        location.reload();
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}

function setDate(value) {
  if (value) {
    const startDate = value[0];
    const endDate = value[1];

    // Check if any selected date falls within the reserved range
    const overlap = reservedDays.value.some(
      (date) => date >= startDate && date <= endDate
    );

    // If there is an overlap, prevent the selection
    if (overlap) {
      sendAlert("please pick from available dates", "error");
      date.value = [new Date(), null];
    } else date.value = value;
  }
}
const getMapCenter = computed(() => {
  const lat = parseFloat(items.rental.locations_lat);
  const lng = parseFloat(items.rental.locations_lng);

  // Check if both latitude and longitude are valid numbers
  if (!isNaN(lat) && !isNaN(lng)) {
    return [lat, lng];
  } else {
    // Return a default center or handle invalid values as needed
    return [0, 0];
  }
});

function goToSpecialRoute() {
  const currentRoute = router.currentRoute.value;
  if (currentRoute.fullPath.includes("SavedItems")) {
    router.push({ name: "SavedItems" });
  } else if (currentRoute.fullPath.includes("RentalsBookedByMe")) {
    router.push({ name: "RentalsBookedByMe" });
  } else {
    router.push({ name: "TheAdvertisements" });
  }
}

function loadRental() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  isLoading.value = true;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/main-rental-request/${id}`,
  })
    .then(function (response) {
      isLoading.value = false;

      if (response.status === 200) {
        //
        // sendAlert("data loaded ", "success");
        items.rental = response.data.rental;
        // const startDate = new Date(items.rental.start_day_rent);
        // const endDate = new Date(items.rental.end_date_rent);
        // date.value = [startDate, endDate];
        isBookmarked.value = response.data.isBookmarked;
        const start_date = new Date(
          response.data.booked_date_range_reserved.start_date_at
        );
        const end_date = new Date(
          response.data.booked_date_range_reserved.end_date_at
        );
        const days = [];

        while (start_date <= end_date) {
          days.push(new Date(start_date));
          start_date.setDate(start_date.getDate() + 1);
        }
        reservedDays.value = days;
        // console.log("🚀 ~ days:", days);

        // reservedDays.value = [
        //   new Date(response.data.booked_date_range_reserved.start_date_at),
        //   new Date(response.data.booked_date_range_reserved.end_date_at),
        // ];
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
function loadBookRental() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  isLoading.value = true;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/book/show-rental-book/${id}`,
  })
    .then(function (response) {
      isLoading.value = false;

      if (response.status === 200) {
        //
        // sendAlert("data loaded ", "success");
        items.bookRental = response.data;
        // const startDate = new Date(items.rental.start_day_rent);
        // const endDate = new Date(items.rental.end_date_rent);
        // date.value = [startDate, endDate];
        // isBookmarked.value = response.data.isBookmarked;
        if (
          items.bookRental?.book?.length != 0 &&
          items.bookRental?.book?.user_id == myUserId.value
        ) {
          const start_date = new Date(items.bookRental.book.start_date_at);
          const end_date = new Date(items.bookRental.book.end_date_at);
          date.value = [start_date, end_date];
        }
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
const beforeUpdateHandler = ({ value, preventDefault }) => {
  // console.log("before handle");
  if (value) {
    const startDate = value[0];
    const endDate = value[1];

    // Check if any selected date falls within the reserved range
    const overlap = reservedDays.value.some(
      (date) => date >= startDate && date <= endDate
    );

    // If there is an overlap, prevent the selection
    if (overlap) {
      sendAlert("please pick from available dates", "error");
      preventDefault();
    }
  }
};
function bookRental() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  if(!date.value[0] || !date.value[1])
  {

    sendAlert('the book range must be selected','error')
    return
  }
  else {
    axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/book/book-rental-request/${id}`,
    data: {
      start_date_at: formatDate(date.value[0]),
      end_date_at: formatDate(date.value[1]),
    },
  })
    .then(function (response) {
      // Loading.remove();
      // isLoading.value = false;

      if (response.status === 200) {
        sendAlert("booked succesfully ", "success");
        setTimeout(() => {
          router.push({ name: "RentalsBookedByMe" });
        }, 1000);
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      // isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
  }
  
}
function formatDate(date) {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "UTC",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate.replace(
    /(\d+)\/(\d+)\/(\d+), (\d+:\d+:\d+) (AM|PM)/,
    "$3-$1-$2 $4"
  );
}
function setBookmark() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  isLoading.value = true;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/profile-rentals-request/bookmark`,
    data: {
      rental_id: items.rental.id,
    },
  })
    .then(function (response) {
      isLoading.value = false;

      if (response.status === 200) {
        //
        // sendAlert("bookmark loaded ", "success");
        isBookmarked.value = !isBookmarked.value;
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
const calculatedTotal = computed(() => {
  const differenceInMs = Math.abs(date.value[1] - date.value[0]);

  const daysDifference = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24)) + 1;

  if (!date.value[1] || !date.value[0]) return `not selected`;
  else {
    const total = daysDifference * items.rental.price_per_day;
    return `${total} $`;
  }
});

const copyUrl = () => {
  const currentPageUrl = window.location.href;

  navigator.clipboard.writeText(currentPageUrl).then(
    () => {
      sendAlert("Url Copied ", "success");
    },
    (err) => {
      console.error("Unable to copy URL to clipboard", err);
      sendAlert("Error copying ", "error");
    }
  );
};
// const goBack = () => {
//   router.go(-1);
// };
function chatWithUser() {
  // if (authenticated) {
  // Loading.pulse();
  isLoading.value = true;

  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: "https://api.atdeloop.com/api/message/create-conversation",
    data: {
      user_id: items.rental.user_id,
    },
  })
    .then(function (response) {
      // Loading.remove();
      isLoading.value = false;

      if (response.status === 200) {
        //
        router.push({
          name: "TheChat",
          params: { id: response.data.conversation_id },
          // TODO conversation id instead of userid
        });
      }
    })
    .catch(function (error) {
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
onMounted(() => {
  loadRental();
  loadBookRental();
  // console.log("date", date.value);
});
</script>

<style lang="scss" scoped>
.leftButton {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  align-items: center;
  z-index: 1005;
  left: 21px;
  top: 12px;
}

.fullScreenBtn {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  align-items: center;
  z-index: 1005;
  right: 21px;
  bottom: 12px;
  background-color: #000;
  text-align: center;
  padding: 6px 5px;
  span {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.mainContent {
  overflow: hidden;
  background: #f9f9f9;
  height: 100%;
  padding: 21px 27px;
  .rentals-box {
    display: flex;
    flex-direction: column;
    min-height: 377px;
  }
  .toolsContainer {
    display: flex;
    justify-content: space-between;

    .bookmarkBtn,
    .shareBtn {
      cursor: pointer;
    }
  }

  .itemDetails {
    margin: 13px 0;
    h2 {
      font-size: 12px;
      font-weight: 700;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      line-height: 10px;
    }
    ul li {
      margin: 0;
    }
    .more {
      color: #5d9600;
      text-align: justify;
      font-family: Inter;
      font-size: 10px;
      font-weight: 700;
      text-decoration-line: underline;
    }
    .detailTitle {
      font-size: 10px;
      font-weight: 700;
    }
    .detailValue {
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-weight: 400;
    }
    .priceTitle {
      color: #000;
      text-align: justify;
      font-size: 10px;
      font-weight: 700;
    }
    .priceValue {
      color: #ef0000;
      font-size: 10px;
      font-weight: 700;
    }
    span.locationTitle {
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-weight: 500;
    }
    .total {
      font-family: Inter;
      font-size: 12px;
      font-weight: 700;
      text-wrap: nowrap;
    }
    .base-form {
      width: 80% !important;
      margin-bottom: 0 !important;
      min-height: initial !important;
    }
    .bookBtn.mainBtn {
      width: 100%;
      height: 30px;
      min-height: initial;
      padding: 0;
    }
    .halfBtn.mainBtn {
      width: 50%;
      height: 30px;
      min-height: initial;
      padding: 0;
    }
  }
}
.mapBox {
  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    border-radius: 10px;
  }

  .leaflet-container {
    border: 1px solid #cccccc;
  }
}
.calendarBox {
  width: 100%;
  .reservationStatus {
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    p {
      margin: 10px 0;
    }
  }
  /deep/ {
    .dp__month_year_row {
      border-radius: 20px 20px 0px 0px;
      background: #cacaca;
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 24px;
    }

    .dp__outer_menu_wrap {
      border-radius: 20px 20px 0px 0px;
    }

    .dp__menu.dp__relative {
      border-radius: 20px;
    }

    .dp__month_year_wrap {
      height: 24px;

      button {
        max-height: 24px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }

    .dp__calendar_header_item {
      height: initial !important;
    }

    .dp__calendar_header {
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .dp__instance_calendar {
      border-radius: 20px;
    }

    .dp__outer_menu_wrap {
      width: 100%;
    }

    .dp__menu_inner {
      padding: 0;
      background: transparent transparent;
      border: none none;
    }

    .dp__calendar {
      background: #dedede;
      border-radius: 0px 0px 20px 20px;
      padding-bottom: 7px;

      .dp__calendar_item {
        display: flex;
        justify-content: center;
      }

      .dp__cell_inner {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        /* border: 1px solid red !important; */
        background-color: #fff !important;
        color: #747474;
        font-family: Inter;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .dp__cell_disabled {
        position: relative;
        background-color: red !important;
        color: white;

        &::after {
          content: "";
          width: 1px;
          height: 100%;
          transform: rotate(45deg);
          // background-color: #747474;
          position: absolute;
          opacity: 0.5;

          // background-color: rgb(4, 0, 255);
        }
      }
      .dp__today {
        background-color: rgb(51, 255, 0) !important;
        color: white;
        cursor: pointer;
      }

      .dp__range_end,
      .dp__range_start {
        background-color: rgb(255, 217, 0) !important;
        color: white;
        z-index: 100;
      }

      .dp__range_between {
        background-color: rgb(255, 217, 0) !important;
        color: white;
      }

      .dp__cell_offset {
        color: #cccaca !important;
      }

      .dp__action_row {
        display: none !important;
      }
    }
  }
  /deep/ .greenSelected {
    .dp__range_end,
    .dp__range_start {
      background-color: #6daf00 !important;
      color: white;
      z-index: 100;
    }

    .dp__range_between {
      background-color: #6daf00 !important;
      color: white;
    }
  }
}

.calendarBox /deep/ .dp__action_row {
  display: none !important;
}
.reserved-day {
  background-color: red !important; /* Style for reserved days */
}

.carouselBox {
  /deep/ {
    .carousel__track {
      margin: 0 !important;
    }
  }
}

.leftArrowBtn {
  background: #eadcdc;
  border-radius: 100%;
  opacity: 0.8;
}

.carousel__item img {
  border-radius: 30px;
}
</style>
