<template>
	<main class="fixed top-0 right-0 left-0">
		<section class=" py-4 ps-2 pe-4 w-100 md:px-28 lg:w-4/12 md:auto xl:w-3/12 d-flex justify-content-between align-items-center lg:p-5">
			<slot name="start"></slot>
			<slot name="center"></slot>
			<slot name="end"></slot>
		</section>
	</main>
</template>
<style lang="scss" scoped >
section{
	background-color: #EDEDED;
}
main{
	position: sticky;
	top: 0;
	right: 0;
	z-index: 1000;
}

</style>