import { useAccessToken } from './useAccessToken';

export const AccessTokenPlugin = {
  install: (app, store) => {
    const accessTokenInstance = useAccessToken(store);

    app.config.globalProperties.$accessToken = accessTokenInstance;
    app.provide('accessToken', accessTokenInstance);
  },
};
