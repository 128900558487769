<!-- BaseHeader.vue  -->
<template>
  <div
    class="baseHeader"
    :style="{
      background: !NoBG
        ? 'linear-gradient(180deg, #82d100 0%, #5d9600 100%)'
        : 'none',
    }"
  >
    <button v-if="!noButton" class="leftButton" @click="handleBackButtonClick">
      <span>
        <img v-if="NoBG" src="../../assets/images/left-arrow-grey.svg" alt="" />
        <img v-else   class="leftArrowBtn" src="../../assets/images/LeftArrow.svg" alt="" />
      </span>
    </button>
    <h5 class="title">{{ pageTitle }}</h5>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["pageTitle", "noButton", "NoBG", "leftArrowClick"]);

const handleBackButtonClick = () => {
  props.leftArrowClick();
};
</script>

<style lang="scss" scoped >
.baseHeader {
  position: sticky;
  top: -3px;
  z-index: 1700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 49px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 16px;
}

.leftButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

}



.title {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}
</style>
