<template>
  <div class="homePage h-100">
    <div class="topBox">
      <img src="../assets/logoPlusText.svg" />
      <h1 class="text-center mt-5">Welcome to atdeloop</h1>
    </div>
    <div class="w-100 d-flex justify-content-center getStartedBtn">
      <router-link :to="{ name: 'TheHome2' }">
        <base-button class="homeBtn mt-3">Get Started</base-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheHome",
};
</script>

<style lang="scss" scoped>
.homePage {
  position: relative;
  /* background-image: url(../assets/images/main-bg.svg) center center; */
  background-image: url(../assets/images/bgCenter.jpg);
  background-position: center;
  background-size: 100% 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    // flex: 1;
  }

  img {
    // margin-left: 77px;
    // margin-top: 85px;
    max-width: 107px;
    max-height: 160px;
    // flex: 1;
    margin-top: 30px;
    margin-right: 140px;
  }
  .topBox {
    flex: 2;
  }

  .getStartedBtn {
    flex: 1;
    // margin-bottom: 60px;
    // position: absolute;
    // bottom: 108px;
    // left: 50%;
    // right: 50%;
    // transform: translateX(-50%);

    // &:hover {
    //   transform: translateX(-50%) translateY(-5%);
    // }
  }
}
</style>
