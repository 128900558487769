<template>
  <div class="page position-relative profile-page">
    <base-header :pageTitle="pageTitle" :noButton="true"></base-header>
    <!-- profileBox  -->
    <div
      class="d-flex flex-column justify-content-center align-items-center gap-3 mt-3 mb-3 profileBox"
    >
      <div class="imgWrapper">
        <img :src="MyProfilePic" alt="" />
      </div>
      <h6 class="fw-bold">{{ firstName }} {{ lastName }}</h6>
      <router-link :to="{ name: 'EditProfile' }">
        <base-button>Edit Profile</base-button>
      </router-link>
    </div>

    <profile-menu></profile-menu>

    <base-nav></base-nav>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import ProfileMenu from "../profile/ProfileMenu.vue";
const store = useStore();

const pageTitle = ref("Profile");

const MyProfilePic = computed(() => {
  return store.state.profile.profile_photo;
});
const firstName = computed(() => {
  return store.state.profile.first_name;
});
const lastName = computed(() => {
  return store.state.profile.last_name;
});

onMounted(() => {
  // Access the profile object from the store and set firstName and lastName
  // firstName.value = store.state.profile.first_name;
  // lastName.value = store.state.profile.last_name;
  // MyProfilePic.value = store.state.profile.profile_photo;
});
</script>

<style lang="scss" scoped>
.profileBox {
  // max-width: 82px;
  //   max-height: 82px;
  .imgWrapper {
    
    
    border-radius: 100%;
    max-width: 82px;
    max-height: 82px;
    img {
      max-width: 82px;
      max-height: 82px;
      border-radius: 100%;
    }
  }
}

.page {
  /* height: 926px; */
  background: #fafafa;
}

.menuContent {
  height: 75vh;
  background-color: aqua;
}

// .profile-page {
//   /deep/ .navigation-buttons ul {
//       transform: translateY(100%);
//     }

// }
</style>
