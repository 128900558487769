<template>
  <div v-if="rental" class="wrapper position-relative">

    <div class="imgContainer" v-if="rental.gallery.length">
      <img class="mainImg img-fluid" :src="rental.gallery[0]" alt="" />
    </div>
    <div v-else>
      <img src="../../assets/images/gallery/no-photo.jpg" alt="" class="img-fluid w-100 rounded-2"
                style="max-height: 100px" />

    </div>

    <div class="details">
      <h2 class="fw-700 fs-10">
        {{ props.title }}
      </h2>
      <div class="d-flex flex-column gap-1">
        <span>Number: {{ rental.medias.length }}</span>
        <span>{{ rental.human_readable_date }} </span>
        <span>Amount paid: ${{ rental.price_per_day }}</span>
        <span v-if="rental.status=='publish'" class="statusPill bg-success rounded-pill text-center text-white p-1">published  </span>
        <span v-else class="statusPill px-2 bg-warning rounded-pill  text-center text-white p-1">waiting</span>
      </div>
    </div>
  </div>
  <div class="line"></div>
</template>
<!-- {
          number: "1",
          day: "9",
          amount: "800",
          image: "/path/to/up_arrow_icon.png",
        } -->
<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  title: String,
  number: String,
  day: String,
  amount: String,
  image: String,
  rental: Array,
});

//eslint-disable-next-line
const imageSrc = computed(() => require(`@/${props.image}`));

</script>

<style lang="scss" scoped >
.wrapper {
  width: 100%;
  /* border-radius: 10px; */
  padding: 11px 26px;
  /* background: #dedcdc; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  gap: 15px;

  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      color: #747474;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    h2 {
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .statusPill{
    position: absolute;
    bottom: 5%;
    right: 5%;
  }
}

.line {
  width: 88%;
  height: 1px;
  background: #b4b4b4;
  margin: auto;
}

.imgContainer {
    img {
      width: 132px;
    max-height: 82px;
    border-radius: 10px;
    }
   
    
  }
</style>
