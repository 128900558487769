<template>
  <div class="wrapper">
    <div v-if="rental?.gallery?.length" class="img-fluid rentalImg">
      <img :src="rental.gallery[0]" alt="" />
    </div>

    <div v-else>
      <img src="../../assets/images/gallery/no-photo.jpg" alt="" class="img-fluid w-100 rounded-2"
                style="max-height: 100px" />
    </div>

    <div class="details">
      <h2 class="fw-700 fs-10">
        {{ props.title }}
      </h2>
      <div class="d-flex flex-column gap-1">
          <!-- <span>Number: {{ number }}</span> -->
          <!-- <span>{{ props.day }} day</span> -->
          <span v-show="!isApprove">{{ rental.human_readable_date }}</span>
          <span>Daily: ${{ rental.price_per_day }}</span>
      </div>
    </div>
  </div>
  <div v-show="!isApprove" class="line"></div>
</template>
<!-- {
          number: "1",
          day: "9",
          amount: "800",
          image: "/path/to/up_arrow_icon.png",
        } -->
<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  title: String,
  number: String,
  day: String,
  amount: String,
  image: String,
  rental:Array,
    isApprove: { type: Boolean, default: false },

});
//eslint-disable-next-line
//eslint-disable-next-line
const imageSrc = computed(() => require(`@/${props.image}`));

</script>


<style lang="scss" scoped >
.wrapper {
  width: 100%;
  border-radius: 10px;
  padding: 11px 26px;
  /* background: #dedcdc; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  gap: 15px;

  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      color: #747474;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    h2 {
      color: #000;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.line{
width: 88%;
height: 1px;
background: #B4B4B4;
margin: auto;
}

.rentalImg {
  img {
    width:132px ;
    max-width: 132px;
    border-radius: 10px; 
    max-height: 82px; 
  }
  }
</style>
