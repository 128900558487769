<template>
  <div class="page position-relative h-100 theCategory">
    <base-header
      :pageTitle="pageTitle"
      :leftArrowClick="goToSpecialRoute"
    ></base-header>

    <div class="mainContent h-100">
      <div v-show="step === 'chooseCategory'" class="categoryMenu">
        <ul class="list-unstyled">
          <li
            v-for="(category, index) in items.categories"
            :key="category + index"
          >
            <MenuItem
              @click="setCategory(category)"
              :imgPath="category.img_url"
              :title="category.title"
            ></MenuItem>
          </li>
        </ul>
      </div>

      <div v-show="step === 'chooseType'" class="rentalOrRequest mt-3 h-100">
        <ul class="list-unstyled">
          <li class="mb-2 pb-2">
            <MenuItem
              @click="setType('rentals')"
              :imgPath="require('../assets/images/rentals.svg')"
              title="Rentals"
            ></MenuItem>
          </li>
          <li class="mb-2 pb-2">
            <MenuItem
              @click="setType('request')"
              :imgPath="require('../assets/images/requests.svg')"
              title="Requests"
            ></MenuItem>
          </li>
        </ul>
      </div>
    </div>
  </div>
      <base-nav></base-nav>

</template>

<script setup>
//eslint-disable-next-line
import { ref, defineEmits, defineProps, reactive, onMounted } from "vue";
import MenuItem from "../pages/profile/MenuItem.vue";
import router from "@/router";
import { useStore } from "vuex";
const store = useStore();
const pageTitle = ref("Categories");
const emits = defineEmits(["setCategory"]);
// const props = defineProps(["categories"]);
var items = reactive({ categories: [] , selectedCategory: "", selectedType: ""});

const step = ref("chooseCategory");

function goToSpecialRoute() {
  if (step.value === "chooseCategory") {
    router.push({ name: "TheAdvertisements" });
  } else {
    router.push({ name: "Categories" });
    step.value = "chooseCategory";
  }
}

function setCategory(category) {
  step.value = "chooseType";
  items.selectedCategory=category;
}
function setType( type) {
  items.selectedType=type;
  router.push({
        name: "ChoosedAdd",
        params: { cid: items.selectedCategory.id, type: type },
      });


  emits("setTypeAndCategory" );
}

onMounted(() => {
  items.categories = store.state.categories;
});
</script>

<style lang="scss" scoped>
.theCategory{
  /deep/ {
  .baseNav{
position: absolute !important;
  }
}

}

.selectCategory {
  h3 {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 30px;
  }
}
.requestBox {
  padding: 39px;
}
.mainContent {
  .categoryMenu,
  .rentalOrRequest {
    // border-radius: 50px 50px 0px 0px;
    background: #fff;
    // box-shadow: 4px 0px 250px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 20px 30px 20px;

    ul li {
      border-bottom: solid 2px #747474;
      cursor: pointer;
    }
    ul li:last-child {
      border-bottom: none;
    }
  }
}
.page{
  background-color: #ffffff;
}
</style>
