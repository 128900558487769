<template>
  <div class="page position-relative h-100">
    <loading :active="isLoading" :is-full-page="true"></loading>

    <base-header
      :pageTitle="pageTitle"
      :leftArrowClick="goToSpecialRoute"
    ></base-header>

    <div
      v-if="items.bookmarks?.data?.length == 0"
      class="d-flex w-100 justify-content-center align-items-center noElementsToShow mt-5"
    >
      <h4 class="text-center">No Older Bookmarks </h4>
    </div>
    
    <div class="mainContent">
      <div class="rentals-box">
        <div
          :key="index"
          @click="goToStatus(rental.id, index)"
          v-for="(rental, index) in items.bookmarks.data"
        >
        <SingleRentalAdvertise
            :key="index"
            :title="rental.title"
            :number="rental.number"
            :day="rental.day"
            :amount="rental.amount"
            :image="rental.image"
            :rental="rental"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<!-- v-for="(rental, index) in items.rentals.data" :key="index" -->

<script setup>
import SingleRentalAdvertise from "../pages/rental/SingleRentalAdvertise.vue";
import { ref, onMounted, reactive } from "vue";
import serverError from "@/components/composable/useServerError";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
import router from "@/router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

const store = useStore();
//eslint-disable-next-line
const { accessToken, authenticated } = useAccessToken(store);
const pageTitle = ref("My Saved Items");
const isLoading = ref(false);

var items = reactive({ bookmarks: [] });

const goToSpecialRoute = () => {
  router.push({ name: "TheProfile" });
};

const loadBookmarks = () => {
  isLoading.value = true;

  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: "https://api.atdeloop.com/api/profile/bookmarks",
  })
    .then(function (response) {
      isLoading.value = false;

      if (response.status === 200) {
        items.bookmarks = response.data;
      }
    })
    .catch(function (error) {
      isLoading.value = false;
      serverError(error);
    });
};

const goToStatus = (id, index) => {
  store.commit("setCurrentItem", items.bookmarks.data[index]);
  router.push({
    name: "SavedRentalItems",
    params: { id: id },
  });
};

onMounted(() => {
  loadBookmarks();
});
</script>


<style lang="scss" scoped>
.mainContent {
  overflow: hidden;
  background: #f9f9f9;
  height: 100%;
  .rentals-box {
    display: flex;
    flex-direction: column;
    min-height: 377px;
  }
}
.noElementsToShow {
  color: #969696;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
