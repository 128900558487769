<template>
  <div class="page position-relative h-100">
    <!-- <base-header :pageTitle="pageTitle"></base-header> -->
    <!-- <base-header :pageTitle="pageTitle"
    :leftArrowClick="goToSpecialRoute"
    ></base-header> -->
    <button class="leftButton" @click="goToSpecialRoute">
      <span>
        <img class="leftArrowBtn" src="../assets/images/LeftArrow.svg" alt="" />
      </span>
    </button>
    <div class="bannerImage position-relative">
      <!-- <img
        class="img-fluid"
        src="../assets/images/rental-item-banner.svg"
        alt=""
      /> -->
      <button class="fullScreenBtn rounded-4">
        <span>
          <img src="../assets/images/full-screen.svg" alt="" />
          <span class="text-white fs11 px-2">{{
            items.request.gallery?.length
          }}</span>
        </span>
      </button>

      <div class="carouselBox">
        <Carousel :wrap-around="true">
          <Slide
            style="cursor: pointer"
            v-for="(slide, index) in items.request.gallery"
            :key="index"
          >
            <div class="carousel__item w-100">
              <img
                :src="slide"
                alt=""
                class="img-fluid w-100 bRadios"
                style="max-height: 250px"
                @click="openModal(slide)"
              />
              
            </div>
          </Slide>
       
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
        <img v-if="!galleryLength"
                src="../assets/images/gallery/no-photo.jpg"
                alt=""
                class="img-fluid w-100"
                style="max-height: 250px"
              />

      </div>
    </div>
    <div class="mainContent">
      <div class="itemDetails">
        <h2 class="fw-700 fs-10">{{ items.request.title }}</h2>
        <span class="human_readable_date">{{
          items.request.human_readable_date
        }}</span
        ><br />

        <!-- <ul>
         
          <li>
            <span class="detailTitle">Brand: </span
            ><span class="detailValue">DEWALT</span>
          </li>
          <li>
            <span class="detailTitle">Voltage: </span
            ><span class="detailValue">20 Volts</span>
          </li>
          <li>
            <span class="detailTitle">Power Source: </span
            ><span class="detailValue">Ac_dc</span>
          </li>
          <li>
            <span class="detailTitle">Special Feature: </span
            ><span class="detailValue"
              >Led Light, Variable Speed, Cordless</span
            >
          </li>
          <li class="d-inline">
            <span class="detailTitle">Included Components: </span
            ><span class="detailValue"
              >(1) DCS354 20V MAX* Brushless Oscillating Multi-Tool; (1) Wood
              With Nails Blade; (1) Fast-cut Wood Blade; (1) Universal Accessory
              Adapter</span
            >
          </li>
        </ul> -->

        <div class="priceContainer">
          <span class="priceTitle">Number: </span>
          <span class="reserveDay"> {{ items.request.number }} </span><br />
          <span class="priceTitle">Reservation days: </span>
          <span class="reserveDay mb-2">
            {{ items.request.reserve_day }} days</span
          >
        </div>

        <div class="moreDescription">
          <p class="m-0">
            {{ items.request.description }}
          </p>
        </div>
        <span class="more mt-2">More</span>

        <div><span class="locationTitle">Location:</span></div>
        <!-- <div class="mt-1">
          <img src="../assets/images/map.svg" alt="" />
        </div> -->
        <!-- <div>
          <p v-if="!date[0] || !date[1]" class="text-danger fw-bold">
            The reservation date has not been selected
          </p>
        </div> -->
        <div class="mapBox">
          <div style="height: 135px" class="mt-2">
            <LMap
              v-model:zoom="zoom"
              :center="getMapCenter"
              @move="log('move')"
            >
              <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              ></l-tile-layer>
              <!-- <l-control-layers /> -->
              <l-marker
                :lat-lng="getMapCenter"
                :draggable="false"
                @moveend="log('moveend')"
              >
                <l-tooltip> marker</l-tooltip>
              </l-marker>

              <!-- <l-marker :lat-lng="[47.41322, -1.219482]">
              <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
            </l-marker> -->

              <!-- <l-marker :lat-lng="[50, 50]" draggable @moveend="log('moveend')">
              <l-popup> lol </l-popup>
            </l-marker> -->
            </LMap>
          </div>
        </div>
        <div class="mt-2 calendarBox"
        :class="
              !(
                items.bookRequest?.book?.length == 0 ||
                items.bookRequest?.book?.user_id !== myUserId
              )
                ? 'greenSelected'
                : ''
            ">
          <!-- <img src="../assets/images/calender.svg" alt="" /> -->
          <VueDatePicker
            
            v-model="date"
            inline
            range
            readonly
            month-name-format="short"
            :enable-time-picker="false"
          ></VueDatePicker>

          <!-- :disabled-dates="disabledDates" -->
        </div>
           <div class="d-flex align-items-center justify-content-between mt-3">
          <span class="d-flex total">Proposed price:</span>
          <base-form
          style="width: 65% !important;" 
          readonly textCenter :placeholder="calculatedTotal"> </base-form>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-between mt-3">
          <span class="d-flex total">Total:</span>
          <base-form></base-form>
        </div> -->

        <base-button
          v-if="
            items.bookRequest?.book?.length == 0 
          "
          @click="bookRequest"
          class="mt-3 bookBtn"
          >Book Now</base-button
        >
        <base-button
          v-if="
            !(items.bookRequest?.book?.length == 0) &&
            items.bookRequest?.book?.user_id == myUserId &&
            items.bookRequest?.book?.status == 'waiting'
          "
          class="mt-3 bookBtn"
          >Waiting</base-button
        >
        <base-button
          @click="pay"
          v-if="
            !(items.bookRequest?.book?.length == 0) &&
            items.bookRequest?.book?.user_id == myUserId &&
            items.bookRequest?.book?.status == 'accept'
          "
          class="mt-3 bookBtn"
          >Waiting for Payment</base-button
        >
        <base-button
          v-if="
            !(items.bookRequest?.book?.length == 0) &&
            items.bookRequest?.book?.user_id == myUserId &&
            items.bookRequest?.book?.status == 'pay'
          "
          class="mt-3 bookBtn"
          >Paid Successfully</base-button
        >
        <base-button
          v-if="
            !(items.bookRequest?.book?.length == 0) &&
            items.bookRequest?.book?.user_id == myUserId &&
            items.bookRequest?.book?.status == 'decline'
          "
          class="mt-3 bookBtn"
          >Declined</base-button
        >
        <div class="d-flex mt-3 gap-1">
          <base-button class="halfBtn">Call</base-button>
          <base-button class="halfBtn" @click="chatWithUser">
            Chat Now</base-button
          >
        </div>
      </div>
    </div>

    <!-- <base-nav></base-nav> -->
  </div>
</template>
<script setup>
import { ref, defineProps, computed, onMounted, reactive } from "vue";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
//eslint-disable-next-line
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
import { useRoute, useRouter } from "vue-router";
import "leaflet/dist/leaflet.css";
import { Carousel, Slide } from "vue3-carousel";

// eslint-disable-next-line
import {
  LMap,
  // LIcon,
  LTileLayer,
  LMarker,
  // LControlLayers,
  LTooltip,
  // LPopup,
  // LPolyline,
  // LPolygon,
  // LRectangle,
} from "@vue-leaflet/vue-leaflet";

const props = defineProps({
  title: String,
  number: String,
  day: String,
  amount: String,
  image: String,
});

//eslint-disable-next-line
const imageSrc = computed(() => require(`@/${props.image}`));
const galleryLength = computed(() => {
      return items.request?.gallery?.length;
    });
const zoom = ref(7);
// const iconWidth = ref(25);
// const iconHeight = ref(40);
const store = useStore();
//eslint-disable-next-line
var { accessToken, authenticated } = useAccessToken(store);
const route = useRoute();
const router = useRouter();
var id = route.params.id;
var items = reactive({ request: [], slides: [], bookRequest: [] });
// const slides = reactive({ img_url: [1, 2, 3, 4, 5] });
const calculatedTotal = computed(() => {
  // const differenceInMs = Math.abs(date.value[1] - date.value[0]);

  //const daysDifference = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24)) + 1;

  if (!date.value[1] || !date.value[0]) return `not selected`;
  else {
    //const total = daysDifference * items.request.price_per_day;
    const total =  items.request.price_per_day;
    return `${total} $`;
  }
});
const myUserId = computed(() => {
  return store.state.userId;
});
const date = ref([null, null]);

const getMapCenter = computed(() => {
  const lat = parseFloat(items.request.locations_lat);
  const lng = parseFloat(items.request.locations_lng);

  // Check if both latitude and longitude are valid numbers
  if (!isNaN(lat) && !isNaN(lng)) {
    return [lat, lng];
  } else {
    // Return a default center or handle invalid values as needed
    return [0, 0];
  }
});

// const iconUrl = computed(
//   () => `https://placekitten.com/${iconWidth.value}/${iconHeight.value}`
// );
// const iconSize = computed(() => [iconWidth.value, iconHeight.value]);

// const changeIcon = () => {
//   iconWidth.value += 2;
//   if (iconWidth.value > iconHeight.value) {
//     iconWidth.value = Math.floor(iconHeight.value / 2);
//   }
// };
// const format = (date) => {
//   const day = date.getDate();
//   const month = date.getMonth() + 1;
//   return `Selected date is ${day}/${month}`;
// }
// const disabledDates = computed(() => {
//   const today = new Date();
//   today.setHours(0, 0, 0, 0);
//   const isPastDate = (date) => date < today;
//   return {
//     customPredictor: (date) => isPastDate(date),
//   };
// });
function goToSpecialRoute() {
  const currentRoute = router.currentRoute.value;

  if (currentRoute.fullPath.includes("RequestsBookedByMe"))
    router.push({ name: "RequestsBookedByMe" });
  else router.push({ name: "TheAdvertisements" });
}
function chatWithUser() {
  // if (authenticated) {
  // Loading.pulse();
  // isLoading.value = true;

  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: "https://api.atdeloop.com/api/message/create-conversation",
    data: {
      user_id: items.request.user_id,
    },
  })
    .then(function (response) {
      // Loading.remove();
      // isLoading.value = false;

      if (response.status === 200) {
        //
        router.push({
          name: "TheChat",
          params: { id: response.data.conversation_id },
          // TODO conversation id instead of userid
        });
      }
    })
    .catch(function (error) {
      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
function loadRequest() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/main-rental-request/${id}`,
  })
    .then(function (response) {
      // Loading.remove();
      // isLoading.value = false;

      if (response.status === 200) {
        // sendAlert("data loaded ", "success");
        items.request = response.data.rental;
        const startDate = new Date(items.request.start_day_rent);
        const endDate = new Date(items.request.end_date_rent);
        date.value = [startDate, endDate];
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      // isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
function loadBookRequest() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  // isLoading.value = true;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/book/show-rental-book/${id}`,
  })
    .then(function (response) {
      // isLoading.value = false;

      if (response.status === 200) {
        //
        // sendAlert("data loaded ", "success");
        items.bookRequest = response.data;
        // const startDate = new Date(items.rental.start_day_rent);
        // const endDate = new Date(items.rental.end_date_rent);
        // date.value = [startDate, endDate];
        // isBookmarked.value = response.data.isBookmarked;
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      // isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
function bookRequest() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/book/book-rental-request/${id}`,
    data: {
      start_date_at: formatDate(date.value[0]),
      end_date_at: formatDate(date.value[1]),
    },
  })
    .then(function (response) {
      // Loading.remove();
      // isLoading.value = false;

      if (response.status === 200) {
        sendAlert("booked succesfully ", "success");
        setTimeout(() => {
          router.push({ name: "RequestsBookedByMe" });
        }, 1000);
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      // isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
function formatDate(date) {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "UTC",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate.replace(
    /(\d+)\/(\d+)\/(\d+), (\d+:\d+:\d+) (AM|PM)/,
    "$3-$1-$2 $4"
  );
}
//eslint-disable-next-line
const log = (a) => {};
// const goBack = () => {
//   router.go(-1);
// };

onMounted(() => {
  loadRequest();
  loadBookRequest();
});
</script>

<style lang="scss" scoped>
.leftButton {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  align-items: center;
  z-index: 1005;
  left: 21px;
  top: 12px;
}
.mainContent {
  overflow: hidden;
  background: #f9f9f9;
  height: 100%;
  padding: 21px 27px;
  .rentals-box {
    display: flex;
    flex-direction: column;
  }
  .toolsContainer {
    display: flex;
    justify-content: space-between;
    span {
      margin-top: 5px;
      color: #888;
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .itemDetails {
    .human_readable_date {
      color: #5c5c5c;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
    margin-bottom: 13px;
    h2 {
      font-size: 12px;
      font-weight: 700;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      line-height: 10px;
    }
    ul li {
      margin: 0;
    }
    .more {
      color: #5d9600;
      text-align: justify;
      font-family: Inter;
      font-size: 10px;
      font-weight: 700;
      text-decoration-line: underline;
    }
    .detailTitle {
      font-size: 10px;
      font-weight: 700;
    }
    .detailValue {
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-weight: 400;
    }
    .priceTitle {
      color: #000;
      text-align: justify;
      font-size: 10px;
      font-weight: 700;
    }
    .priceValue {
      color: #ef0000;
      font-size: 10px;
      font-weight: 700;
    }
    .reserveDay {
      font-size: 10px;
      font-weight: 700;
    }
    span.locationTitle {
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-weight: 700;
    }
    .total {
      font-family: Inter;
      font-size: 12px;
      font-weight: 700;
        text-wrap: nowrap;

    }
    .base-form {
      width: 80% !important;
      margin-bottom: 0 !important;
      min-height: initial !important;
    }
    .bookBtn.mainBtn {
      width: 100%;
      height: 30px;
      min-height: initial;
      padding: 0;
    }
    .halfBtn.mainBtn {
      width: 50%;
      height: 30px;
      min-height: initial;
      padding: 0;
    }
  }
  .moreDescription {
    color: #000;
    text-align: justify;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}
.mapBox {
  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    border-radius: 10px;
  }

  .leaflet-container {
    border: 1px solid #cccccc;
  }
}
.fullScreenBtn {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  align-items: center;
  z-index: 1005;
  right: 21px;
  bottom: 12px;
  background-color: #000;
  text-align: center;
  padding: 6px 5px;
  span {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.calendarBox {
  width: 100%;
  .reservationStatus {
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    p {
      margin: 10px 0;
    }
  }
  /deep/ {
    .dp__month_year_row {
      border-radius: 20px 20px 0px 0px;
      background: #cacaca;
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 24px;
    }

    .dp__outer_menu_wrap {
      border-radius: 20px 20px 0px 0px;
    }

    .dp__menu.dp__relative {
      border-radius: 20px;
    }

    .dp__month_year_wrap {
      height: 24px;

      button {
        max-height: 24px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }

    .dp__calendar_header_item {
      height: initial !important;
    }

    .dp__calendar_header {
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .dp__instance_calendar {
      border-radius: 20px;
    }

    .dp__outer_menu_wrap {
      width: 100%;
    }

    .dp__menu_inner {
      padding: 0;
      background: transparent transparent;
      border: none none;
    }

    .dp__calendar {
      background: #dedede;
      border-radius: 0px 0px 20px 20px;
      padding-bottom: 7px;

      .dp__calendar_item {
        display: flex;
        justify-content: center;
      }

      .dp__cell_inner {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        /* border: 1px solid red !important; */
        background-color: #fff !important;
        color: #747474;
        font-family: Inter;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .dp__cell_disabled {
        position: relative;
        &::after {
          content: "";
          width: 1px;
          height: 100%;
          transform: rotate(45deg);
          background-color: #747474;
          position: absolute;
          opacity: 0.5;
          // background-color: rgb(4, 0, 255);
        }
      }
      .dp__today {
        background-color: rgb(51, 255, 0) !important;
        color: white;
        cursor: pointer;
      }

      .dp__range_end,
      .dp__range_start {
        background-color: rgb(255, 217, 0) !important;
        color: white;
        z-index: 100;
      }

      .dp__range_between {
        background-color: rgb(255, 217, 0) !important;
        color: white;
      }

      .dp__cell_offset {
        color: #cccaca !important;
      }

      .dp__action_row {
        display: none !important;
      }
  
    }
  }
}
.calendarBox.greenSelected {
  width: 100%;
  .reservationStatus {
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    p {
      margin: 10px 0;
    }
  }
  /deep/ {
    .dp__month_year_row {
      border-radius: 20px 20px 0px 0px;
      background: #cacaca;
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 24px;
    }

    .dp__outer_menu_wrap {
      border-radius: 20px 20px 0px 0px;
    }

    .dp__menu.dp__relative {
      border-radius: 20px;
    }

    .dp__month_year_wrap {
      height: 24px;

      button {
        max-height: 24px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }

    .dp__calendar_header_item {
      height: initial !important;
    }

    .dp__calendar_header {
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .dp__instance_calendar {
      border-radius: 20px;
    }

    .dp__outer_menu_wrap {
      width: 100%;
    }

    .dp__menu_inner {
      padding: 0;
      background: transparent transparent;
      border: none none;
    }

    .dp__calendar {
      background: #dedede;
      border-radius: 0px 0px 20px 20px;
      padding-bottom: 7px;

      .dp__calendar_item {
        display: flex;
        justify-content: center;
      }

      .dp__cell_inner {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        /* border: 1px solid red !important; */
        background-color: #fff !important;
        color: #747474;
        font-family: Inter;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .dp__cell_disabled {
        position: relative;
        &::after {
          content: "";
          width: 1px;
          height: 100%;
          transform: rotate(45deg);
          background-color: #747474;
          position: absolute;
          opacity: 0.5;
          // background-color: rgb(4, 0, 255);
        }
      }
      .dp__today {
        background-color:white !important;
        
        cursor: pointer;
      }

      .dp__range_end,
      .dp__range_start {
        background-color:#6DAF00 !important;
        color: white;
        z-index: 100;
      }

      .dp__range_between {
        background-color:#6DAF00 !important;
        color: white;
      }

      .dp__cell_offset {
        color: #cccaca !important;
      }

      .dp__action_row {
        display: none !important;
      }
  
    }
  }
}




.calendarBox /deep/ .dp__action_row {
  display: none !important;
}

.leftArrowBtn {
  background: #eadcdc;
  border-radius: 100%;
  opacity: 0.8;
}
/* .calendarBox /deep/ .dp__day__disabled {
  cursor: not-allowed;
} */

.carousel__item img {
  border-radius: 30px;
}
</style>
