<!-- sendOptions.vue -->
<template>
  
  <div class="sendOptions">
    <base-header 
    :NoBG="true"

    :leftArrowClick="goToSpecialRoute"
    ></base-header>
    <h5 class="pb-3">Please select the item</h5>

    <div class="optionBox h-100 py-5 px-5 ">
      <router-link :to="{ name: 'SendRental' }">
        <feature-button
         
          title="Post a Rental"
          :size="large"
          :imgSrc="require('@/assets/images/send-File.svg')"
          color="grey"
        ></feature-button>
      </router-link>
      <router-link :to="{ name: 'SendRequest' }">
        <feature-button
        
          title="Send a Request"
          :size="large"
          :imgSrc="require('@/assets/images/add-properties.svg')"
          color="grey"
        ></feature-button>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
function goToSpecialRoute() {
      router.push({ name: 'TheAdvertisements' });
    }
</script>

<style lang="scss" scoped >
.sendOptions {
  height: 100vh;
  // border-radius: 30px;
  background: #ededed;
  display: flex;
  flex-direction: column;

  > h5 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .optionBox {
    border-radius: 50px 50px 0px 0px;
    background: #fff;
    box-shadow: 4px 2px 50px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
  }
}
</style>
