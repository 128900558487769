<!-- MenuItem.vue -->
<template>
  <div class="menuItem">
    <img :src="imgPath" :alt="title + ' Image'" />
    <h6>{{ title }}</h6>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    imgPath: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.menuItem {
  display: flex;
  align-items: center;
  padding: 7px 0;
  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    &:hover {

transform: translateY(-5px);
    }
  }
  h6 {
    &::after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../../assets/images/Forward.svg") no-repeat center center;
        margin-left: 5px;
        right: 50px;
    position: absolute;
      }
  }
}
</style>
