import { defineStore } from "pinia";

export const useChats = defineStore("chats", {
  state: () => {
    return {
      chats: [
        // {
        //   text: "Sure we are going to plazamart now",
        //   left: true,
        //   id: 1,
        //   date: "10:40 AM",
        // },
        // {
        //   text: "Hi Ahmed! How's are you?",
        //   left: true,
        //   id: 2,
        //   date: "12:40 AM",
        // },
        // {
        //   text: "I am contacting you because am very hungry",
        //   left: true,
        //   id: 3,
        //   date: "03:15 PM",
        // },
        // {
        //   text: "Hi bruuh :>",
        //   left: true,
        //   id: 4,
        //   date: "05:22 AM",
        // },
        // {
        //   text: "hello friend 😀",
        //   left: true,
        //   id: 5,
        //   date: "09:55 AM",
        // },
        // {
        //   text: "😂😂😂😂",
        //   left: true,
        //   id: 6,
        //   date: "02:30 PM",
        // },
        // {
        //   text: "Aowkaoawkoakwokawl",
        //   left: true,
        //   id: 7,
        //   date: "08:10 AM",
        // },
      ],
    };
  },
  actions: {
    addingNewChat(payload) {
      this.chats.push({
        ...payload,
      });
    },
    setChats(newChats) {
      this.chats = newChats;

    },
  },
});
