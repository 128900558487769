<!-- TheAuth.vue Component  -->
<template>
  <loading :active="isLoading" :is-full-page="true"></loading>

  <!-- signIn  -->
  <div class="signInWrapper" v-if="authStep == 'login'">
    <!-- <div class="signInWrapper"> -->
    <div class="homePage section d-flex">
      <router-link :to="{ name: 'TheHome' }">
        <div class="d-flex justify-content-center">
          <img class="logoText" src="../../assets/logoPlusText.svg" />
        </div>
      </router-link>
      <!-- <h1 class="text-center mt-5">Welcome to atdeloop</h1> -->

      <div
        class="d-flex justify-content-center flex-column align-items-center btnGroup w-100"
      >
        <div class="base-form">
          <input
            type="text"
            class="form-control"
            v-model="loginData.email"
            placeholder="enter your email"
          />
        </div>
        <div class="base-form">
          <input
            :type="showPassword ? 'text' : 'password'"
            class="form-control password"
            v-model="loginData.password"
            placeholder="enter your pass"
          />

          <button class="btn toggle-password" @click="toggleShow">
            <i
              :class="{
                'bi-eye-slash': showPassword,
                'bi-eye': !showPassword,
              }"
            ></i>
          </button>
        </div>

        <base-button @click="authorization">sign in</base-button>
        <!-- <router-link
          :to="{
            name: 'TheAuth',
            params: { step: 'confirmation' },
            query: { step: 'confirmation' },
          }"
        > -->
        <a class="forgotPassword" type="button" @click="authStep = 'enterEmail'"
          >Forget Password?</a
        >
        <!-- </router-link> -->
        <!-- data-bs-toggle="modal"
            data-bs-target="#passSentToEmailModal-forgetPass" -->
        <!-- <base-dialog
        :isVisible="isDialogVisible"
        @close="hideForgetPasswordDialog"
      /> -->

        <div
          class="d-flex justify-content-center flex-column align-items-center w-100 signInWith-wrapper"
        >
          <p>Or sign in with</p>
          <div class="d-flex flex-row justify-content-around w-100 signInWith">
            <div class="signInImgWrapper">
              <a> <img src="../../assets/images/Google Plus.svg" alt="" /></a>
            </div>
            <div class="signInImgWrapper">
              <a><img src="../../assets/images/Facebook.svg" alt="" /></a>
            </div>
          </div>
          <p class="haveAccount mt-4">
            Dont have an account?

            <span>
              <router-link
                class="signUpText"
                @click="authStep = 'register'"
                :to="{
                  name: 'TheAuth',
                  params: { step: 'signUp' },
                }"
              >
                <a class="signUpText">Sign up</a>
              </router-link>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- signUp  -->
  <div class="signUpWrapper" v-if="authStep == 'register'">
    <!-- <div class="signUpWrapper"> -->
    <div class="homePage section">
      <router-link :to="{ name: 'TheHome' }">
        <img class="logoText" src="../../assets/logoPlusText.svg" />
      </router-link>
      <!-- <h1 class="text-center mt-5">Welcome to atdeloop</h1> -->

      <div
        class="d-flex justify-content-center flex-column align-items-center btnGroup w-100"
      >
        <div class="d-flex flex-row justify-content-between">
          <div class="base-form">
            <input
              type="text"
              class="form-control"
              v-model="formSignUpData.firstName"
              placeholder="First Name"
            />
          </div>

          <div class="base-form">
            <input
              type="text"
              class="form-control"
              v-model="formSignUpData.lastName"
              placeholder="Last Name"
            />
          </div>
        </div>

        <div class="base-form">
          <input
            type="text"
            class="form-control"
            v-model="formSignUpData.email"
            placeholder="Email"
          />
        </div>
        <div class="base-form">
          <input
            :type="showPassword ? 'text' : 'password'"
            class="form-control password"
            v-model="formSignUpData.password"
            placeholder="Password"
          />
          <button class="btn toggle-password" @click="toggleShow">
            <i
              :class="{
                'bi-eye-slash': showPassword,
                'bi-eye': !showPassword,
              }"
            ></i>
          </button>
        </div>
        <div class="base-form">
          <input
            :type="showConfirmPassword ? 'text' : 'password'"
            class="form-control password"
            v-model="formSignUpData.password_confirmation"
            placeholder="Confirm Password"
            @keyup.enter="handleConfirmation"
          />

          <button class="btn toggle-password" @click="toggleShowConfirm">
            <i
              :class="{
                'bi-eye-slash': showConfirmPassword,
                'bi-eye': !showConfirmPassword,
              }"
            ></i>
          </button>
        </div>
        <base-button @click="handleConfirmation" class="my-3"
          >Sign Up</base-button
        >

        <!-- <button
          class="d-none"
          ref="toggleModalBtn"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#passSentToEmailModal"
        ></button> -->

        <!-- @click="handleConfirmation" -->

        <div class="custom-checkbox">
          <input
            class="termsLabelInput"
            type="checkbox"
            id="privacy-agreement"
          />
          <label for="privacy-agreement">I Agree with privacy and policy</label>
        </div>

        <div
          class="d-flex justify-content-center flex-column align-items-center w-100 signInWith-wrapper"
        >
          <a class="fs10" @click="authStep = 'login'">Back to login page</a>
          <p>Or sign in with</p>
          <div class="d-flex flex-row justify-content-around w-100 signInWith">
            <div class="signInImgWrapper">
              <a> <img src="../../assets/images/Google Plus.svg" alt="" /></a>
            </div>
            <div class="signInImgWrapper">
              <a><img src="../../assets/images/Facebook.svg" alt="" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- verify for after signUP  -->
  <div class="verifyWrapper" v-if="authStep == 'confirmation'">
    <!-- <div class="verifyWrapper"> -->
    <div class="homePage">
      <!-- <base-header :page-title="''" NoBG /> -->
      <img class="mobileSVG" src="../../assets/images/mobile.svg" alt="" />

      <div class="content">
        <h3 class="text-nowrap test-center mb-3">Verify your Email</h3>
        <h6 class="text-nowrap test-center mb-3">Enter the code sent to</h6>
        <p class="text-nowrap test-center fGreen3">
          {{ formSignUpData.email }}
        </p>

        <div class="OTPBox">
          <div style="display: flex; flex-direction: row" class="m-4">
            <v-otp-input
              ref="otpInput"
              v-model:value="inputValue"
              input-classes="otp-input"
              separator=" "
              :num-inputs="5"
              :should-auto-focus="true"
              input-type="letter-numeric"
              :conditionalClass="['one', 'two', 'three', 'four', 'five']"
              :placeholder="['', '', '', '']"
              @on-change="setConfirmationCode"
              @on-complete="checkUserForConfirmCode"
            />
          </div>
          <button
            class="d-none"
            ref="toggleVerifyModalBtn"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#verificationModal"
          ></button>

          <div
            class="resendCodeBox d-flex flex-column justify-content-center align-content-center align-items-center mb-4"
          >
            <p class="m-0">I Didn’t Receive the Code?</p>
            <a
              class="resendCode"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#"
              >Recent Code</a
            >
          </div>

          <!-- <a @click="clearInput()">resend code</a>
    <button @click="fillInput('2929')">Fill Input</button> -->
        </div>

        <div>
          <base-button @click="checkUserForConfirmCode">Verify</base-button>
        </div>
      </div>
    </div>
  </div>

  <!-- Email for Forget pass  -->
  <div class="signInWrapper" v-if="authStep == 'enterEmail'">
    <!-- <div class="signInWrapper"> -->
    <div class="homePage section">
      <router-link :to="{ name: 'TheHome' }">
        <img class="logoText" src="../../assets/logoPlusText.svg" />
      </router-link>
      <!-- <h1 class="text-center mt-5">Welcome to atdeloop</h1> -->

      <div
        class="d-flex justify-content-center flex-column align-items-center btnGroup w-100"
      >
        <div class="base-form d-flex flex-column gap-3 mb-5">
          <label for="userEmailForConfirmationCode" class="ps-2"
            >enter your email</label
          >
          <input
            id="userEmailForConfirmationCode"
            type="text"
            class="form-control"
            v-model="UserEmailData.email"
            placeholder="enter your email"
          />
        </div>

        <base-button @click="ReqConfirmForForgetPass"
          >Request Confirm Code</base-button
        >
        <a class="fs10 mt-4" @click="authStep = 'login'">Back to login page</a>

        <button
          class="d-none"
          ref="togglePassSentToEmailModalForForgetPass"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#passSentToEmailModal-forgetPass"
        ></button>
      </div>
    </div>
  </div>

  <!-- NewPass  -->
  <div class="NewPassWrapper" v-if="authStep == 'newPass'">
    <!-- <div class="NewPassWrapper"> -->
    <div class="homePage section">
      <router-link :to="{ name: 'TheHome' }">
        <img class="logoText" src="../../assets/logoPlusText.svg" />
      </router-link>
      <!-- <h1 class="text-center mt-5">Welcome to atdeloop</h1> -->

      <div
        class="d-flex justify-content-center flex-column align-items-center btnGroup w-100 px-2"
      >
        <div class="base-form">
          <input
            :type="showPassword ? 'text' : 'password'"
            class="form-control password"
            v-model="formNewPassData.password"
            placeholder="enter your new pass"
          />
          <button class="btn toggle-password" @click="toggleShow">
            <i
              :class="{
                'bi-eye-slash': showPassword,
                'bi-eye': !showPassword,
              }"
            ></i>
          </button>
        </div>
        <div class="base-form">
          <input
            :type="showConfirmPassword ? 'text' : 'password'"
            class="form-control password"
            v-model="formNewPassData.password_confirmation"
            placeholder="confirm your new pass"
          />

          <button class="btn toggle-password" @click="toggleShowConfirm">
            <i
              :class="{
                'bi-eye-slash': showConfirmPassword,
                'bi-eye': !showConfirmPassword,
              }"
            ></i>
          </button>
        </div>
        <div class="base-form">
          <input
            :type="ShowVerificationCode ? 'text' : 'password'"
            class="form-control password"
            v-model="forgetPassConfirmCodeData.password"
            placeholder="confirmation Code"
          />

          <button
            class="btn toggle-password"
            @click="toggleShowVerificationCode"
          >
            <i
              :class="{
                'bi-eye-slash': ShowVerificationCode,
                'bi-eye': !ShowVerificationCode,
              }"
            ></i>
          </button>
        </div>

        <base-button @click="ReqChangePasswordForgetPass"
          >Set New Password</base-button
        >
        <a class="fs10 mt-4" @click="authStep = 'login'">Back to login page</a>

        <button
          class="d-none"
          ref="verificationModalForgetPassBtn"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#verificationModalForgetPass"
        ></button>
      </div>
    </div>
  </div>

  <!-- passSentToEmailModal for sign UP START  -->
  <div
    class="modal fade"
    id="passSentToEmailModal"
    tabindex="-1"
    aria-labelledby="passSentToEmailModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered my-0">
      <div
        class="modal-content border-0 d-flex justify-content-center align-content-center align-items-center"
      >
        <div
          class="modal-body m-0 py-0 border-0 d-flex justify-content-center align-content-center align-items-center"
        >
          <loading
            :active="isLoadingForSendEmailModal"
            :is-full-page="true"
          ></loading>
          <div
            class="d-flex flex-column py-0 justify-content-center align-content-center align-items-center"
          >
            <img
              class="img-fluid"
              src="../../assets/images/emailSent.svg"
              alt=""
            />

            <h6 class="fs11 fw400">We sent an email to</h6>
            <h5 class="fs11 fw700">{{ formSignUpData.email }}</h5>
            <h6 class="fs11 fw400">with a link to reset your password.</h6>
            <button
              class="border-0 rounded-5 btn-success"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="authStep = 'confirmation'"
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- passSentToEmailModal for sign UP END  -->

  <!-- passSentToEmailModal for forgetPass START  -->
  <div
    class="modal fade"
    id="passSentToEmailModal-forgetPass"
    tabindex="-1"
    aria-labelledby="passSentToEmailModal-forgetPass"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered my-0">
      <div
        class="modal-content border-0 d-flex justify-content-center align-content-center align-items-center"
      >
        <div
          class="modal-body m-0 py-0 border-0 d-flex justify-content-center align-content-center align-items-center"
        >
          <div
            class="d-flex flex-column justify-content-center align-content-center align-items-center"
          >
            <img
              class="img-fluid"
              src="../../assets/images/emailSent.svg"
              alt=""
            />

            <h6 class="fs11 fw400">We sent an email to</h6>
            <h5 class="fs11 fw700">{{ UserEmailData.email }}</h5>
            <h6 class="fs11 fw400">with a link to reset your password.</h6>
            <button
              class="border-0 rounded-5 btn-success"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="authStep = 'newPass'"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- passSentToEmail Modal for forgetPass END  -->

  <!-- verification Modal for sign Up START   -->
  <div
    class="modal fade"
    id="verificationModal"
    tabindex="-1"
    aria-labelledby="verificationModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered my-0 p-5">
      <div
        class="modal-content border-0 d-flex justify-content-center align-content-center align-items-center"
      >
        <div
          class="modal-body m-0 py-5 border-0 d-flex justify-content-center align-content-center align-items-center"
        >
          <div
            class="d-flex flex-column justify-content-center align-content-center align-items-center"
          >
            <img
              class="img-fluid w-25"
              src="../../assets/images/verified.svg"
              alt=""
            />
            <h1>Verified!</h1>
            <h6 class="fs11 fw600 text-center">
              congratulations! You have successfully verified the account.
            </h6>

            <button
              class="border-0 rounded-5 btn-success"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="authStep = 'login'"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- verificationModal for sign Up END  -->

  <!-- verification Modal for forgetPass START   -->
  <div
    class="modal fade"
    id="verificationModalForgetPass"
    tabindex="-1"
    aria-labelledby="verificationModalForgetPass"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered my-0 p-5">
      <div
        class="modal-content border-0 d-flex justify-content-center align-content-center align-items-center"
      >
        <div
          class="modal-body m-0 border-0 d-flex justify-content-center align-content-center align-items-center"
        >
          <div
            class="d-flex flex-column justify-content-center align-content-center align-items-center"
          >
            <img
              class="img-fluid w-25"
              src="../../assets/images/verified.svg"
              alt=""
            />
            <h1>Verified!</h1>
            <h6 class="fs11 fw600 text-center">
              congratulations! You have successfully changed your password.
            </h6>
            <h6 class="text-info text-center">
              login with your new password now
            </h6>

            <button
              class="border-0 rounded-5 btn-success mt-3"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="authStep = 'login'"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- verificationModal for forgetPass END  -->
</template>

<script>
//eslint-disable-next-line
import { ref, reactive } from "@vue/reactivity";
import { onMounted, watch } from "vue";
import axios from "axios";
// import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import serverError from "../composable/useServerError";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import sendAlert from "../composable/useSendAlert";
// import Loading from "vue-loading-overlay";
// import { onMounted } from "vue";

export default {
  components: {
    Loading,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    // Define reactive variables using ref()
    const title = ref("Verification Required");
    const content = ref("Please enter your verification code to proceed.");
    const isOpen = ref(false);
    const isFormOpen = ref(false);
    const code = ref("");
    const inputValue = ref("");
    const isLoadingForSendEmailModal = ref(false);
    // const toggleModalBtn = ref(false);
    const toggleVerifyModalBtn = ref(null);
    const togglePassSentToEmailModalForForgetPass = ref();
    const verificationModalForgetPassBtn = ref();

    // OTP password
    const openMyModal = () => {
      isOpen.value = true;
    };
    const closeModal = () => {
      isOpen.value = false;
    };
    const onSubmit = () => {
      isFormOpen.value = true;
    };
    const openModal = () => {
      // Accessing modalRef
      //
      // Perform actions related to modal here
      // e.g., modalRef.openModal();
    };
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);
    const ShowVerificationCode = ref(false);
    const toggleShow = () => {
      // showPassword.value = !showPassword.value;
      showPassword.value = !showPassword.value;
    };
    const toggleShowConfirm = () => {
      // showPassword.value = !showPassword.value;
      showConfirmPassword.value = !showConfirmPassword.value;
    };
    const toggleShowVerificationCode = () => {
      // showPassword.value = !showPassword.value;
      ShowVerificationCode.value = !ShowVerificationCode.value;
    };

    // auth :
    const isLoading = ref(false);

    var authStep = ref("login");
    var loginData = reactive({
      email: "",
      password: "",
      code: "",
      kind: "",
    });
    var UserEmailData = reactive({
      email: "",
    });
    var formSignUpData = reactive({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      password_confirmation: "",
    });
    var formNewPassData = reactive({
      password: "",
      password_confirmation: "",
      confirmationCode: "",
    });
    var forgetPassConfirmCodeData = reactive({
      password: "",
    });
    const isForgetPassModalVisible = ref(false);

    function authorization() {
      isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";

      // loading.value = true;
      // loginData.code = "";
      //

      axios({
        method: "post",
        url: "https://api.atdeloop.com/api/login",
        data: {
          email: loginData.email,
          password: loginData.password,
        },
      })
        .then((response) => {
          isLoading.value = false;
          if (response.data.status === "ok") {
            sendAlert("logged in successfully", "success");
            // notify({
            //   type: "success",
            //   title: "Authorization",
            //   text: "وارد شدید",
            // });
           

            store.commit("setAuth", true);
            store.commit(
              "setUserId",
              response.data.token.accessToken.tokenable_id
            );
            store.commit("setToken", response.data.token.plainTextToken);
            store.commit("setUser", {
              email: loginData.email,
              password: loginData.password,
            });
            loginData.email = "";
            loginData.password = "";
             
            router.push({
              name: "TheAdvertisements",
            });
            // TODO after succesfull login go to main page
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            //
            sendAlert("user information are wrong", "error");
          }

          serverError(error);
        })
        .finally(() => {
          isLoading.value = false;
        });
    }
    // after click دریافت کد تایید  send req for getting confirmation code
    function confirmation() {
      isLoadingForSendEmailModal.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";

      //

      axios({
        method: "post",
        url: "https://api.atdeloop.com/api/auth-send-mobile-code",
        data: {
          first_name: formSignUpData.firstName,
          last_name: formSignUpData.lastName,
          password: formSignUpData.password,
          email: formSignUpData.email,
          password_confirmation: formSignUpData.password_confirmation,
        },
      })
        .then((response) => {
          isLoading.value = false;

          if (response.data.status === "code_send") {
            // toggleModalBtn.value.click();
            loginData.kind = response.data.kind;

            sendAlert("verify code sent", "success");

            loginData.email = formSignUpData.email;
            authStep.value = "confirmation";
          }
        })
        .catch((error) => {
          isLoading.value = false;
          if (error.response.data.status === "user_exist") {
            sendAlert("you registered before ", "error");
          }
          serverError(error);
        })
        .finally(() => {
          //
        });
    }
    function checkUserForConfirmCode() {
      isLoading.value = true;

      axios({
        method: "post",
        url: "https://api.atdeloop.com/api/auth-confirm-mobile-code",
        data: {
          code: inputValue.value,
          email: loginData.email,
        },
      })
        .then(function (response) {
          isLoading.value = false;

          if (response.data.status === "ok") {
            toggleVerifyModalBtn.value.click();
            if (authStep.value == "signUp") {
              sendAlert("you registered successfully");
            } else if (authStep.value == "forgetPassConfirmation") {
              sendAlert("successfully done");
              authStep.value = "NewPass";
            } else if (response.data.status === "code_wrong") {
              sendAlert("verify code is wrong", "error");
              //
              loginData.code = "";
            } else if (response.data.status === "error") {
              sendAlert(response.data.msg, "error");
            }
          }
          //
        })
        .catch(function (error) {
          isLoading.value = false;

          //
          if (error.response.status === 401) {
            sendAlert("verify code is wrong", "error");
            loginData.code = "";
          }

          serverError(error);
        });
    }

    function ReqConfirmForForgetPass() {
      isLoading.value = true;
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";

      axios({
        method: "post",
        url: "https://api.atdeloop.com/api/auth-send-mobile-code-forget-pass",
        data: {
          email: UserEmailData.email,
        },
      })
        .then((response) => {
          isLoading.value = false;
          if (response.data.status === "code_send") {
            togglePassSentToEmailModalForForgetPass.value.click();

            sendAlert("verify code sent", "success");
            if (authStep.value == "enterEmail") {
              // authStep.value = "newPass";
            }

            loginData.kind = "email";
            // TODO hard coded
          } else if (response.data.status === "not_exist") {
            sendAlert("you should sign up first", "error");
          }
        })
        .catch((error) => {
          isLoading.value = false;

          //

          serverError(error);
        })
        .finally(() => {
          isLoading.value = false;
        });
    }
    function ReqChangePasswordForgetPass() {
      //
      // ReqConfirmForForgetPass();

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";

      isLoading.value = true;
      axios({
        method: "post",
        url: "https://api.atdeloop.com/api/auth-confirm-mobile-code-forget-pass",
        data: {
          password: formNewPassData.password,
          email: UserEmailData.email,
          password_confirmation: formNewPassData.password_confirmation,
          code: forgetPassConfirmCodeData.password,
          // hard coded
        },
      })
        .then((response) => {
          isLoading.value = false;
          // TODO server error when user exist
          //

          if (response.data.status === "ok") {
            verificationModalForgetPassBtn.value.click();

            sendAlert("password changed successfully");

            authStep.value = "login";

            loginData.email = "";
            loginData.password = "";
            loginData.code = "";
            loginData.kind = "";
          } else if (response.data.status === "code_wrong") {
            sendAlert("code_wrong", "error");
          }
        })
        .catch((error) => {
          isLoading.value = false;

          //

          sendAlert(error.response.data.message, "error");

          serverError(error);
        })
        .finally(() => {
          // loading.value = false;
          //
        });
    }
    function handleForgetPassword() {
      authStep.value = "forgetPassConfirmation";
    }
    function handleAuthorization(forgetPassData) {
      authorization(forgetPassData);
    }
    function handleConfirmation() {
      const isTermsChecked =
        document.getElementsByClassName("termsLabelInput")[0].checked;

      if (isTermsChecked) {
        confirmation();
      } else {
        sendAlert("terms should be checked ", "error");
      }
    }

    function handleSignUp() {
      authStep.value = "register";
    }
    function setConfirmationCode(otpInput) {
      loginData.code = otpInput;
    }
    watch(
      () => authStep.value,
      (newValue) => {
        router.replace({ name: "TheAuth", params: { step: newValue } });
      }
    );

    onMounted(() => {
      if (route.params.step == "signIn") {
        authStep.value = "login";
      } else if (route.params.step == "signUp") {
        authStep.value = "register";
      } else if (route.params.step == "forgetPass") {
        authStep.value = "confirmation";
      }
    });

    // Expose variables and functions to the template
    return {
      title,
      content,
      isOpen,
      isFormOpen,
      code,
      openMyModal,
      closeModal,
      onSubmit,
      openModal,
      loginData,
      UserEmailData,
      toggleShow,
      showPassword,
      setConfirmationCode,
      handleSignUp,
      handleConfirmation,
      handleAuthorization,
      authorization,
      handleForgetPassword,
      isLoading,
      inputValue,
      checkUserForConfirmCode,
      ReqChangePasswordForgetPass,
      authStep,
      formSignUpData,
      confirmation,
      formNewPassData,
      ReqConfirmForForgetPass,
      isForgetPassModalVisible,
      forgetPassConfirmCodeData,
      // toggleModalBtn,
      toggleVerifyModalBtn,
      togglePassSentToEmailModalForForgetPass,
      verificationModalForgetPassBtn,
      toggleShowConfirm,
      showConfirmPassword,
      toggleShowVerificationCode,
      ShowVerificationCode,
    };
  },
};
</script>

<style lang="scss" scoped>
.OTPBox {
  .otp-input {
    background-color: #e4e4e4;
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border: none;
    text-align: center;
  }
  /* Background colour of an input field with value */
  .otp-input.is-complete {
    background-color: #e4e4e4;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
  }
}
</style>

<style lang="scss" scoped>
input.form-control:focus {
  background: #dedcdc !important;
}
.signInWrapper {
  /* margin-top: 60px; */
  .signUpText {
    color: #458200;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
  .haveAccount {
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .signInWith {
    img {
      max-width: 34px;
    }
    .signInImgWrapper {
      display: flex;
      width: 77px;
      height: 44px;
      border-radius: 8px;
      background: #d9d9d9;
      align-items: center;
      justify-content: center;
    }
  }

  a.forgotPassword {
    margin-top: 20px;
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline !important;
  }
  p {
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .homePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    /* border: 1px solid black; */
    /* position: relative; */
    /* height: 926px; */
    min-height: 100vh;
    .logoText {
      /* position: absolute;
      top: 10%;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
      width: 118px; */
    }

    .btnGroup {
      /* position: absolute;
      bottom: 308px;
      right: 50%;
      transform: translateX(50%); */
      max-width: 277px;
      /* max-height: 42px; */
    }
  }
  input::placeholder {
    color: #959595;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .logoText {
    /* top: 6% !important; */
  }
  .btnGroup {
    /* bottom: 245px !important; */
  }
}

.signUpWrapper {
  .logoText {
    /* top: 3% !important; */
  }
  .btnGroup {
    /* bottom: 245px !important; */
  }
}
.signUpWrapper,
.NewPassWrapper {
  .btnGroup {
    /* bottom: 240px !important; */
  }

  base-button {
    margin-top: 23px;
  }
  #privacy-agreement {
    margin-right: 7px;
    /* margin-top: 23px; */
    color: #000;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    vertical-align: middle;
  }
  .signUpText {
    color: #458200;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
  .haveAccount {
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .signInWith-wrapper {
    /* margin-top: 20px; */
  }
  .signInWith {
    img {
      max-width: 34px;
    }
    .signInImgWrapper {
      display: flex;
      width: 77px;
      height: 44px;
      border-radius: 8px;
      background: #d9d9d9;
      align-items: center;
      justify-content: center;
    }
  }

  a.forgotPassword {
    margin-top: 20px;
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline !important;
  }
  p {
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .homePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    /* border: 1px solid black; */
    /* position: relative; */
    /* height: 926px; */
    min-height: 100vh;
    .logoText {
      /* position: absolute;
      top: 10%;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
      width: 118px; */
    }

    .btnGroup {
      /* position: absolute;
      bottom: 308px;
      right: 50%;
      transform: translateX(50%);
      max-width: 277px;
      max-height: 42px; */
    }
  }
  input::placeholder {
    color: #959595;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.verifyWrapper {
  .resendCode {
    color: #5d9600;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline !important;
  }
  .homePage {
    position: relative;
    background-image: url(../../assets/images/mainBg2.png);
    background-position: center;
    background-size: 100% 100%;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;

    .mobileSVG {
      width: 152px;
      height: 316px;
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      max-width: 277px;
    }
  }
}

.base-form {
  display: flex;
  width: 100%;
  min-height: 42px;
  margin-bottom: 10px;

  .half-width {
    width: 48.5%;
  }
  .form-control {
    border-radius: 30px;
    background: #dedcdc;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset;
    color: #959595;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .toggle-password {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 0;
    padding-left: 0;
    margin-left: 0;

    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2) inset,
      0px 0px 0px 0px rgba(0, 0, 0, 0.2);

    color: #959595;
    background: #dedcdc;
  }

  input[type="password"] {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
  input.password {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
  .inputWhileClicked {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
  .base-form .searchInput {
    position: relative;
  }
  .searchIcon {
    position: absolute;
    left: 0;
    transform: translateX(50%);
  }
  button,
  input {
    margin: 0;
  }
}

#passSentToEmailModal,
#passSentToEmailModal-forgetPass {
  width: 238px;
  height: 249px;
  border-radius: 30px;
  background: #fff;
  margin: auto !important;
  right: 0;
  left: 0;
  top: 20%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */

  img {
    width: 87px;
    height: 87px;
  }
  .modal-content,
  .modal-body {
    width: 238px !important;
    height: 249px !important;
  }
  .modal-dialog modal-dialog-centered my-0 {
    margin: 0;
    left: 0;
    right: 0;
  }
}

input {
  min-height: 40px;
}
</style>
