<template>
  <div class="page position-relative h-100">
    <loading :active="isLoading" :is-full-page="true"></loading>

    <base-header
      :pageTitle="pageTitle"
      :leftArrowClick="goToSpecialRoute"
    ></base-header>

    <div 
    v-if="!items.requests?.data?.length"
    class="d-flex w-100 justify-content-center align-items-center noElementsToShow mt-5">
      <h4 class="text-center">No Older Requests</h4>
    </div>

    <div class="mainContent">
      <div class="rentals-box">
        <div
          v-for="(request, index) in items.requests.data"
          :key="index"
          @click="goToMyRequestPage(request.id)"
        >
          <SingleRequest
            :key="index"
            :title="request.title"
            :number="request.number"
            :day="request.day"
            :image="request.image"
            :notifs="request.notifs"
            :request="request"
          />
        </div>
      </div>
    </div>

    <!-- <base-nav></base-nav> -->
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";

import SingleRequest from "./SingleRequest.vue";
import serverError from "@/components/composable/useServerError";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
import router from "@/router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  components: {
    SingleRequest,
    Loading,
  },
  setup() {
    const store = useStore();
    //eslint-disable-next-line
    var { accessToken, authenticated } = useAccessToken(store);
    const pageTitle = "My Requests";
    var items = reactive({ requests: [] });
    const isLoading = ref(false);

    const requests = [
      {
        title: "DEWALT ATOMIC 20V MAX* Oscillating Tool",
        number: "1",
        day: "9",
        image: "../../assets/images/rental-item.png",
        notifs: "3",
      },
      {
        title: "DEWALT ATOMIC 20V MAX* Oscillating Tool",
        number: "2",
        day: "9",
        image: "../../assets/images/rental-item.png",
        notifs: "3",
      },
      {
        title: "DEWALT ATOMIC 20V MAX* Oscillating Tool",
        number: "3",
        day: "9",
        image: "../../assets/images/rental-item.png",
        notifs: "7",
      },
      {
        title: "DEWALT ATOMIC 20V MAX* Oscillating Tool",
        number: "4",
        day: "9",
        image: "../../assets/images/rental-item.png",
        notifs: "3",
      },
    ];

    function goToSpecialRoute() {
      router.push({ name: "TheProfile" });
    }
    function loadRequests() {
      // if (authenticated) {
      // Loading.pulse();
      isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/profile/my-rentals-request",
        data: {
          kind: "request",
        },
      })
        .then(function (response) {
          // Loading.remove();
          isLoading.value = false;

          if (response.status === 200) {
            //
            // sendAlert("requests loaded ", "success");
            items.requests = response.data;
          }

          // items.first_name = response.data.first_name;
          // items.last_name = response.data.last_name;
          // items.address = response.data.address;
          // items.city = response.data.city;
          // items.phone = response.data.phone;
          // items.avatar_URL=response.data.
        })
        .catch(function (error) {
          // //Loading.remove();
          isLoading.value = false;

          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }
    function goToMyRequestPage(id) {
      router.push({
        name: "PublishDone",
        params: { id: id, kind: "request" },
      });
      // router.push({
      // name: 'MyRequestPage', params: { id: id } 
      // });
    }
    onMounted(() => {
      loadRequests();
    });
    return {
      pageTitle,
      isLoading,
      requests,
      items,
      loadRequests,
      goToMyRequestPage,
      goToSpecialRoute,
    };
  },
};
</script>

<style lang="scss" scoped >
.mainContent {
  overflow: hidden;
  background: #f9f9f9;
  height: 100%;
  .rentals-box {
    display: flex;
    flex-direction: column;
    min-height: 377px;
  }
}
.noElementsToShow {
  color: #969696;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
</style>
