<template>
  <div class="page position-relative h-100">
    <loading :active="isLoading" :is-full-page="true"></loading>

    <base-header
      :pageTitle="pageTitle"
      :leftArrowClick="goToSpecialRoute"
    ></base-header>

    <div class="mainContent">
      <div class="cards">
        <div
          v-for="(card, index) in items.cards"
          :key="index"
          class="cardContainer"
          :class="{ uploadedImgStyle: items.cards[index] }"
          @mouseover="toggleHover(index, true)"
          @mouseleave="toggleHover(index, false)"
        >
          <CreditCard :valueFields="card" />
          <!-- Overlay BOX  -->
          <div class="image-overlay">
            <!-- v-if="hoveredIndex === index && items.cards[index]" -->
            <img
              src="../../assets/images/trash.svg"
              class="trash-icon"
              @click="removeCard(items.cards[index].id)"
              alt="Remove card"
            />
          </div>
        </div>
      </div>

      <div style="cursor: pointer" @click="goToAddCard" class="addCart">
        <img src="@/assets/images/creditCart/Add.svg" alt="" />
        <h3>Add card</h3>
      </div>
    </div>

    <!-- <base-nav></base-nav> -->
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import CreditCard from "./CreditCard.vue";
import serverError from "@/components/composable/useServerError";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
import router from "@/router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  components: {
    CreditCard,
    Loading,
  },
  setup() {
    const isLoading = ref(false);

    const store = useStore();
    //eslint-disable-next-line
    var { accessToken, authenticated } = useAccessToken(store);
    var items = reactive({
      cards: {},
    });
    const pageTitle = ref("View Cards");
    const cardData = ref([
      {
        cardNumber: "1234 5678 9012 3456",
        cardName: "John Doe",
        cardMonth: "12",
        cardYear: "25",
        backgroundImage: "@/assets/images/creditCart/cardbg.svg",
      },
      {
        cardNumber: "9876 5432 1098 7654",
        cardName: "Jane Smith",
        cardMonth: "10",
        cardYear: "24",
        cardHolder: "ali arghyani",
        // Other fields for the second card
      },
      // Add more card objects as needed
    ]);

    // const removeCard = (index) => {
    //   items.cards[index] = null;
    // };

    const hoveredIndex = ref(null);
    const toggleHover = (index, isHovered) => {
      hoveredIndex.value = isHovered ? index : null;
    };

    function goToSpecialRoute() {
      router.push({ name: "MyWallet" });
    }

    function removeCard(id) {
      isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: `https://api.atdeloop.com/api/profile/del-card/${id}`,
      })
        .then(function (response) {
          isLoading.value = false;
          if (response.status === 200) {
            sendAlert("card deleted ", "success");
           location.reload()
          }
        })
        .catch(function (error) {
          isLoading.value = false;
          serverError(error);
        });
    }

    function loadCards() {
      // if (authenticated) {
      // Loading.pulse();
      isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/profile/view-cards",
      })
        .then(function (response) {
          // Loading.remove();
          isLoading.value = false;

          if (response.status === 200) {
            //
            // sendAlert("Profile loaded ", "success");

            items.cards = response.data.cards;

            // store.commit("setProfile", response.data);
            // items.bankName = response.data.bank_Card.bank;
            // items.cardNumber = response.data.bank_Card.number;
            // items.bankName = response.data.bank_Card.bank;
            // items.cardBalance = response.data.balance;
          }

          // items.first_name = response.data.first_name;
          // items.last_name = response.data.last_name;
          // items.address = response.data.address;
          // items.city = response.data.city;
          // items.phone = response.data.phone;
          // items.avatar_URL=response.data.
        })
        .catch(function (error) {
          // //Loading.remove();
          isLoading.value = false;

          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }
    function goToAddCard() {
      router.push({
        name: "AddCart",
      });
    }
    onMounted(() => {
      loadCards();
    });

    return {
      isLoading,
      pageTitle,
      cardData,
      items,
      goToAddCard,
      goToSpecialRoute,
      toggleHover,
      hoveredIndex,
      removeCard,
    };
  },
};
</script>

<style lang="scss" scoped>
.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem 0;
  }

  .addCart {
    width: 283px;
    height: 168px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #d9d9d9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    img {
      width: 47px;
      height: 41px;
      flex-shrink: 0;
    }
    h3 {
      width: 73px;
      height: 21px;
      flex-shrink: 0;
      color: #747474;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.cardContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  background: #dedcdc;
  margin-bottom: 10px;
}
.uploadedImgStyle {
  background-color: white;
  // img {
  //   width: initial;
  //   height: auto;
  // }
}
.removeImg {
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 2;
  font-size: 16px;
  color: red;
}
.image-overlay {
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-overlay:hover {
  opacity: 1;
}

.trash-icon {
  width: 24px; /* Adjust the size as needed */
  height: 24px;
  cursor: pointer;
}
</style>
