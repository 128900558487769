import { app, vfm, router ,Toast,Notifications} from './imports';
import store from './store'; 
import { AccessTokenPlugin } from './components/composable/accessTokenPlugin';
import { registerServiceWorker } from './registerServiceWorker'


// eruda.init();


import { createPinia } from "pinia";
app.use(router);
app.use(createPinia());
registerServiceWorker()
app.use(vfm);
app.use(router);
app.use(store); 
app.use(Toast, {
    position: "bottom-right",
    duration: 1000,
    hideProgressBar: false,
    closeButton: false,
  });
  app.use(Notifications);
  app.use(AccessTokenPlugin, store); // Install AccessTokenPlugin and pass the Vuex store



app.mount('#app');
