// this is router.js :
import { createRouter, createWebHistory } from 'vue-router'

import TheHome from "./pages/TheHome.vue";
import TheHome2 from "./pages/TheHome2.vue";
// import SignIn from "./components/Auth/SignIn.vue";
// import SignUp from "./components/Auth/SignUp.vue";
// import TheVerify from "./components/Auth/TheVerify.vue";
import TheAuth from "./components/Auth/TheAuth.vue";
import TheProfile from "./pages/profile/TheProfile.vue";
import EditProfile from "./pages/profile/EditProfile.vue";
import TheAdvertisements from "./pages/TheAdvertisements.vue";
import MyWallet from "./pages/wallet/MyWallet.vue";
import ViewCards from "./pages/wallet/ViewCards.vue";
import AddCart from "./pages/wallet/AddCart.vue";
import TheNotifications from "./pages/TheNotifications.vue";
import TheTransactions from "./pages/wallet/TheTransactions.vue";
import MyRentals from "./pages/rental/MyRentals.vue"
import MyRequests from "./pages/request/MyRequests.vue";
import SingleRentalAdvertise from "./pages/rental/SingleRentalAdvertise.vue";
import SingleRentalPage from "./pages/SingleRentalPage.vue";
import SingleRequestPage from "./pages/SingleRequestPage.vue";
import PublishDone from "./pages/PublishDone.vue";
import TheChat from "@/pages/chat/views/TheChat.vue"
import ChatHome from "@/pages/chat/views/ChatHome.vue"
import SendRequest from '@/pages/request/SendRequest.vue'
import RequestsBookedByMe from '@/pages/request/RequestsBookedByMe.vue'

import SendRental from '@/pages/rental/SendRental.vue'
import RentalsBookedByMe from '@/pages/rental/RentalsBookedByMe.vue'
import sendOptions from "./pages/sendOptions.vue"
// import RequestCategory from '@/pages/RequestCategory.vue'
import MyRentalPage from "./pages/MyRentalPage.vue";
import MyRequestPage from "./pages/MyRequestPage.vue";
import ApproveRequestPage from "./pages/ApproveRequestPage.vue";
import AddView from "./pages/AddView.vue";
import MyBookmarks from "./pages/MyBookmarks.vue";
// import RentedItems from "./pages/RentedItems.vue";
import AboutUs from "./pages/AboutUs.vue";
import TermsConditions from "./pages/TermsConditions.vue";
import TheCategories from "./pages/TheCategories.vue";



const routes = [
  { path: "/", name: "TheHome", component: TheHome },
  { path: "/", name: "Exit", component: TheHome },
  { path: "/enterOptions", name: "TheHome2", component: TheHome2 },
  { path: "/auth/:step", name: "TheAuth", component: TheAuth },
  { path: "/profile", name: "TheProfile", component: TheProfile },
  { path: "/EditProfile", name: "EditProfile", component: EditProfile },
  {
    path: "/advertisement",
    name: "TheAdvertisements",
    component: TheAdvertisements,
  },
  {
    path: "/advertisement/:cid/:type",
    name: "ChoosedAdd",
    component: TheAdvertisements,
  },
  { path: "/MyWallet", name: "MyWallet", component: MyWallet },
  { path: "/ViewCards", name: "ViewCards", component: ViewCards },
  { path: "/AddCart", name: "AddCart", component: AddCart },
  {
    path: "/Notifications",
    name: "TheNotifications",
    component: TheNotifications,
  },
  {
    path: "/MyRentals",
    name: "MyRentals",
    component: MyRentals,
  },
  {
    path: "/MyRequests",
    name: "MyRequests",
    component: MyRequests,
  },
  {
    path: "/Transactions",
    name: "Transactions",
    component: TheTransactions,
  },
  {
    path: "/postRental",
    name: "postRental",
    component: SingleRentalAdvertise,
  },
  {
    name: "Chathome",
    path: "/chathome",
    component: ChatHome,
  },
  {
    name: "TheChat",
    path: "/chat/:id",
    component: TheChat,
  },
  {
    name: "TheRental",
    path: "/rental/:id",
    component: SingleRentalPage,
  },
  {
    name: "PublishDone",
    path: "/:kind/:id/status",
    component: PublishDone,
  },

  {
    name: "SendRequest",
    path: "/SendRequest",
    component: SendRequest,
  },
  {
    name: "EditRequest",
    path: "/editRequest/:id",
    component: SendRequest,
  },
  {
    name: "SendRental",
    path: "/SendRental",
    component: SendRental,
  },
  {
    name: "EditRental",
    path: "/editRental/:id",
    component: SendRental,
  },
  {
    name: "TheRequest",
    path: "/request/:id",
    component: SingleRequestPage,
  },
  // {
  //   name: "TheRequest",
  //   path: "/request/:id",
  //   component: SingleRequestPage,
  // },
  {
    name: "MyRentalPage",
    path: "/MyRental/:id",
    component: MyRentalPage,
  },
  {
    name: "ApproveRequestPage",
    path: "/ApproveRequestPage/:bookId",
    component: ApproveRequestPage,
  },
  {
    name: "MyRequestPage",
    path: "/MyRequest/:id",
    component: MyRequestPage,
  },
  {
    name: "sendOptions",
    path: "/sendOptions",
    component: sendOptions,
  },
  {
    name: "AddView",
    path: "/AddView/:id",
    component: AddView,
  },
  {
    name: "SavedItems",
    path: "/SavedItems",
    component: MyBookmarks,
  },
  {
    name: "SavedRentalItems",
    path: "/SavedItems/rental/:id",
    component: SingleRentalPage,
  },
  {
    name: "BookedRental",
    path: "/Notifications/rental/:id",
    component: SingleRentalPage,
  },
  {
    name: "BookedRequest",
    path: "/Notifications/request/:id",
    component: SingleRequestPage,
  },
  {
    name: "TermsandConditions",
    path: "/TermsandConditions",
    component: TermsConditions,
  },
  {
    name: "AboutUs",
    path: "/AboutUs",
    component: AboutUs,
  },
  // {
  //   name: "RentalsBookedByMe",
  //   path: "/RentalsBookedByMe",
  //   component: RentalsBookedByMe,
  // },
  // {
  //   name: "RequestsBookedByMe",
  //   path: "/RentalsBookedByMe",
  //   component: RequestsBookedByMe,
  // },
  {
    name: "Categories",
    path: "/Categories",
    component: TheCategories,
  },
  {
    name: "RequestsBookedByMe",
    path: "/RequestsBookedByMe",
    component: RequestsBookedByMe,
  },
  {
    name: "RentalsBookedByMe",
    path: "/RentalsBookedByMe",
    component: RentalsBookedByMe,
  },
  {
    name: "bookedRequest",
    path: "/RequestsBookedByMe/:id",
    component: SingleRequestPage,
  },

  {
    name: "bookedRental",
    path: "/RentalsBookedByMe/:id",
    component: SingleRentalPage,
  },
  {
    name: "ApproveRentalShow",
    path: "/ApproveRequestPage/:bookId/rental/:id",
    component: MyRentalPage,
  },
  {
    name: "ApproveRequestShow",
    path: "/ApproveRequestPage/:bookId/request/:id",
    component: MyRequestPage,
  },
];



// { name: "AboutUs", imgName: "6" },
// { name: "TermsAndConditions", imgName: "7" },
// { name: "SavedItems", imgName: "8" },
import store from '@/store'
const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  
    const requiresAuth =
      !["/", "/enterOptions"].includes(to.path) && !to.path.startsWith("/auth/");
    
    //  console.log(requiresAuth, !store.state.auth);

  if ((!to.path.startsWith('/auth/')) && (!to.path.startsWith('/rental/')) && (!to.path.startsWith('/request/'))) {
    const modalBackdrops = document.querySelectorAll(".modal-backdrop");
    modalBackdrops.forEach((backdrop) => {
      backdrop.parentNode.removeChild(backdrop);
    });
    document.body.style.overflow = "initial";
    document.body.style.paddingRight = "initial";
  }
   if (requiresAuth && !store.state.auth) {
     next("/");
   } else{
    next();
   }
  
});


export default router;
