<template>
  <loading :active="isLoading" :is-full-page="true"></loading>

  <div class="page position-relative h-100">
    <!-- <base-header :pageTitle="pageTitle"></base-header> -->
    <!-- <base-header :pageTitle="pageTitle"
    :leftArrowClick="goToSpecialRoute"
    ></base-header> -->
    <button class="leftButton" @click="goToSpecialRoute">
      <span>
        <img class="leftArrowBtn" src="../assets/images/LeftArrow.svg" alt="" />
      </span>
    </button>

    <!-- <SingleRequestAdvertise
        title="hello"
        number="8"
        day="7"
        image="request.image"
        notifs=""
        request=""
      /> -->
    <!-- {
    name: "TheRequest",
    path: "/request/:id",
    component: SingleRequestPage,
  }, -->
    <chat-header class="chatHeader">
      <template v-slot:start>
        <div class="d-flex gap-3 justify-content-center">
          <!-- <i
            
            class="fa fa-chevron-left mr-5 text-xl active:scale-90 duration-300"
            
          ></i> -->

          <img
            style="min-width: 54px"
            class="ms-5 rounded-circle img-fluid"
            :src="items?.book?.user?.profile_photo"
            width="80"
          />
          <img v-if="items?.book?.user?.profile_photo==null"
            style="min-width: 54px"
            class="ms-5 rounded-circle img-fluid"
            :src="items?.book?.user?.profile_photo"
            width="75"
          />
          <!-- v-if="profile[0].avatar !== null" -->

          <!-- TODO :src="profile[0].avatar" -->
          <!-- <span
              style=""
              class="d-grid justify-content-center align-items-center bg-primary py-3 px-3 font-weight-bold rounded"
            >
          </span> -->
          <!-- {{ profile[0].initial }} -->

          <div class="d-flex">
            <h1 class="profileName m-0 d-flex align-items-center">
              {{ items?.book?.user?.first_name + ' '+ items?.book?.user?.last_name}}
            </h1>
            <!-- <small class="text-green-500">{{ profile[0].status }}</small> -->
            <small class="text-green-500 visually-hidden">online</small>
          </div>
        </div>
      </template>

      <template v-slot:end>
        <div class="text-xl me-2">
          <img @click="chatWithUser" src="../assets/images/chat.svg" alt=""
          style="cursor: pointer;" />
          <img
            class="ms-2"
            style="transform: rotate(270deg)"
            src="../assets/images/phone.svg"
            alt=""
          />
          <!-- <i class="active:scale-90 duration-300 fa fa-video d-block "></i> -->
        </div>
      </template>
    </chat-header>
    <div class="mainContent">
      <div class="d-flex justify-content-between" style="cursor: pointer">
        <div
          v-show="items.rental.kind == 'rental'"
          @click="goToMyRental(items.rental)"
        >
          <SingleRentalAdvertise
            isApprove
            :title="items.rental.title"
            :number="items.rental.number"
            :day="items.rental.day"
            :amount="items.rental.amount"
            :image="items.rental.image"
            :rental="items.rental"
          />
        </div>
        <div
          v-show="items.rental.kind == 'request'"
          @click="goToMyRequest(items.rental)"
        >
          <SingleRequestAdvertise
            isApprove
            :title="items.rental.title"
            :number="items.rental.number"
            :day="items.rental.reserve_day"
            :image="items.rental.image"
            :notifs="items.rental.notifs"
            :request="items.rental"
          />
        </div>
        <img src="../assets/images/forwardLeen.svg" alt="" />
      </div>

      <div class="itemDetails">
        <h2 class="fw-700 fs-10">{{ items.rental.title }}</h2>

        <p class="m-0">
          {{ items.rental.description }}
        </p>

        <span class="more mt-2">More</span>
        <!-- <div class="priceContainer">
          <span class="priceTitle"> One day price:</span>
          <span class="priceValue"> {{ items.rental.price_per_day }} $ </span>
        </div> -->

        <!-- <div class="mt-1">
          <img src="../assets/images/map.svg" alt="" />
        </div> -->

        <div class="mt-2 calendarBox">
          <!-- <img src="../assets/images/calender.svg" alt="" /> -->
          <div class="reservationStatus">
            <p v-if="!date[0] || !date[1]" class="text-danger fw-bold">
              The reservation date has not been selected
            </p>
          </div>
          <VueDatePicker class="greenSelected"
            v-model="date"
            inline
            range
            readonly
            month-name-format="short"
            :enable-time-picker="false"
          ></VueDatePicker>
          <!-- :disabled-dates="disabledDates" -->
        </div>
        <div
          v-if="items.rental.kind == 'request'"
          class="d-flex align-items-center justify-content-between mt-3"
        >
          <span class="d-flex total">Requested Days:</span>
          <base-form
            textCenter
            readonly
            :placeholder="items.rental.reserve_day"
          >
          </base-form>
        </div>
        <div
          v-if="items.rental.kind == 'request'"
          class="d-flex align-items-center justify-content-between mt-3"
        >
          <span class="d-flex total">Proposed price:</span>
          <base-form textCenter readonly :placeholder="calculatedTotal">
          </base-form>
        </div>
        <div
          v-if="items.rental.kind == 'rental'"
          class="d-flex align-items-center justify-content-between mt-3"
        >
          <span class="d-flex total">Total Day:</span>
          <base-form
            textCenter
            readonly
            :placeholder="calculatedTotalDay"
          >
          </base-form>
        </div>
        <div
          v-if="items.rental.kind == 'rental'"
          class="d-flex align-items-center justify-content-between mt-3"
        >
          <span class="d-flex total">Total Price:</span>
          <base-form textCenter readonly :placeholder="calculatedTotal">
          </base-form>
        </div>

        <!-- <base-button class="mt-3 bookBtn">Book Now</base-button> -->
        <div class="d-flex mt-3 gap-1">
          <div
            v-show="items.book.status == 'waiting' && isPending"
            class="pending"
          >
            <button @click="changeStatus('accept')" class="approve">
              <img src="../assets/images/done.svg" alt="" />
              <span>Approve</span>
            </button>
            <button @click="changeStatus('decline')" class="decline">
              <img src="../assets/images/close.svg" alt="" />
              <span>Decline</span>
            </button>
          </div>
        </div>
        <base-button

          v-show="
            items.rental.kind == 'rental' &&
            items.rental.user_id == myUserId &&
            items.book.status == 'accept'
          "
          class="mt-3 bookBtn"
          >Waiting For Payment</base-button
        >
        <base-button
          v-show="
            items.rental.kind == 'request' &&
            items.rental.user_id == myUserId &&
            items.book.status == 'accept'
          "
          @click="pay"
          class="mt-3 bookBtn"
          >Pay Now</base-button
        >
        <base-button
          v-if="!(items.book.length == 0) && items?.book?.status == 'decline'"
          class="mt-3 bookBtn"
          >declined</base-button
        >
        <base-button
          v-if="
            !(items.book.length == 0) &&
            items.rental.user_id == myUserId &&
            items?.book?.status == 'pay'
          "
          class="mt-3 bookBtn"
          >Paid Successfully</base-button
        >
      </div>
    </div>

    <!-- <base-nav></base-nav> -->
  </div>
</template>
components: { Loading, },
<script setup>
import { ref, computed, onMounted, reactive } from "vue";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import SingleRentalAdvertise from "../pages/rental/SingleRentalAdvertise.vue";
import SingleRequestAdvertise from "../pages/request/SingleRequestAdvertise.vue";
import { useStore } from "vuex";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
import { useRoute, useRouter } from "vue-router";
// import { Carousel, Slide } from "vue3-carousel";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
// import SingleRequestAdvertise from "../pages/request/SingleRequestAdvertise.vue";
import ChatHeader from "./chat/components/ChatHeader.vue";

//eslint-disable-next-line
const imageSrc = computed(() => require(`@/${props.image}`));
//
// const pageTitle = ref("Transactions");
const store = useStore();
//eslint-disable-next-line
var { accessToken, authenticated } = useAccessToken(store);
const route = useRoute();
const router = useRouter();
var id = route.params.bookId;
// const currentSlide = ref(0);
// function slideTo(val) {
const myUserId = computed(() => {
  // console.log("🚀 ~ myUserId ~ store.state.userId:", store.state.userId);
  return store.state.userId;
});
const isPending = ref(true);
//   currentSlide.value = val;
// }
var items = reactive({ rental: [], slides: [], book: [] });
// const slides = reactive({ img_url: [1, 2, 3, 4, 5] });

const isBookmarked = ref(false);
const isLoading = ref(false);

const date = ref([null, null]);
//eslint-disable-next-line
const log = (a) => {};
function goToMyRental(rental) {
  router.push({
    name: "ApproveRentalShow",
    params: { bookId: route.params.bookId, id: rental.id },
  });
}
function goToMyRequest(rental) {
  router.push({
    name: "ApproveRequestShow",
    params: { bookId: route.params.bookId, id: rental.id },
  });
}
function goToSpecialRoute() {
  router.push({ name: "TheNotifications" });
}
function changeStatus(status) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  isLoading.value = true;

  axios({
    method: "POST",
    url: `https:estirium.ir/api/book/change-status/${route.params.bookId}`,
    data: {
      status: status,
    },
  })
    .then(function (response) {
      isLoading.value = false;

      if (response.status === 200) {
        isPending.value = false;
        //
        sendAlert("changeStatus  ", "success");
        location.reload();
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
function chatWithUser() {
  // if (authenticated) {
  // Loading.pulse();
  // isLoading.value = true;

  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: "https://api.atdeloop.com/api/message/create-conversation",
    data: {
      user_id: items.book.user_id,
    },
  })
    .then(function (response) {
      // Loading.remove();
      // isLoading.value = false;

      if (response.status === 200) {
        //
        router.push({
          name: "TheChat",
          params: { id: response.data.conversation_id },
          // TODO conversation id instead of userid
        });
      }
    })
    .catch(function (error) {
      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
function pay() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  isLoading.value = true;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/book/pay/${route.params.bookId}`,
  })
    .then(function (response) {
      isLoading.value = false;

      if (response.status === 200) {
        //
        sendAlert("pay succesfully  ", "success");
        location.reload();
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}

function loadPending() {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  isLoading.value = true;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/book/show/${id}`,
  })
    .then(function (response) {
      isLoading.value = false;

      if (response.status === 200) {
        //
        // sendAlert("data loaded ", "success");
        items.rental = response.data.book.rental;
        items.book = response.data.book;
        const startDate = new Date(items.rental.start_day_rent);
        const endDate = new Date(items.rental.end_date_rent);
        date.value = [startDate, endDate];
        isBookmarked.value = response.data.isBookmarked;
        const start_date = new Date(items.book.start_date_at);
          const end_date = new Date(items.book.end_date_at);
          date.value = [start_date, end_date];
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
const calculatedTotal = computed(() => {
  // const differenceInMs = Math.abs(date.value[1] - date.value[0]);

  // const daysDifference = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24)) + 1;

  if (!date.value[1] || !date.value[0]) return `not selected`;
  else {
    // const total = daysDifference * items.rental.price_per_day;
    const total = items.rental.price_per_day;
    return `${total} $`;
  }
});
const calculatedTotalDay = computed(() => {
  const differenceInMs = Math.abs(date.value[1] - date.value[0]);

  return  Math.ceil(differenceInMs / (1000 * 60 * 60 * 24)) + 1;

  
});
// const calculatedTotal = computed(() => {
//   const total = items.rental.reserve_day * items.rental.price_per_day;
//   return `${total} $`;
// });

// const goBack = () => {
//   router.go(-1);
// };
// function chatWithUser() {
//   // if (authenticated) {
//   // Loading.pulse();
//   isLoading.value = true;

//   axios.defaults.headers.common["Content-Type"] = "application/json";
//   axios.defaults.headers.common["Accept"] = "application/json";
//   axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

//   axios({
//     method: "POST",
//     url: "https://api.atdeloop.com/api/message/create-conversation",
//     data: {
//       user_id: items.rental.user_id,
//     },
//   })
//     .then(function (response) {
//       // Loading.remove();
//       isLoading.value = false;

//       if (response.status === 200) {
//         //
//         router.push({
//           name: "TheChat",
//           params: { id: response.data.conversation_id },
//           // TODO conversation id instead of userid
//         });
//       }
//     })
//     .catch(function (error) {
//       isLoading.value = false;

//       //sendAlert(error.response.data, "error");
//       serverError(error);
//     });
// }
onMounted(() => {
  loadPending();
  console.log("date", date.value);
});
</script>

<style lang="scss" scoped>
.profileName{
  font-family: inter;
  font-weight: 400 !important;
  font-size: 15px;

}
/deep/ {
  .chatHeader {
    position: static;
    section {
      background-color: #d9d9d9;
    }
  }
}
.page {
  background-color: #d9d9d9;
}
.pending {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5%;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    // min-width: 88px;
    border-radius: 30px;
    min-height: 30px;
    flex: 1;
  }
}
.approve {
  background: linear-gradient(180deg, #82d100 0%, #5d9600 100%);
  border: none;
  font-size: 11px;
  font-weight: 700;
  font-family: Inter;
  color: #ffffff;
}
.decline {
  background-color: #d9d9d9;
  border: none;
  font-size: 11px;
  font-weight: 700;
  color: #646464;
  font-family: Inter;
}
.leftButton {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  align-items: center;
  z-index: 1005;
  left: 21px;
  top: 12px;
}

.fullScreenBtn {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  align-items: center;
  z-index: 1005;
  right: 21px;
  bottom: 12px;
  background-color: #000;
  text-align: center;
  padding: 6px 5px;
  span {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/deep/ {
  .mainContent .wrapper {
    padding: 0;
  }
}
.mainContent {
  overflow: hidden;
  background: #ffffff;
  height: 100%;
  padding: 21px 27px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  min-height: 100vh;
  .rentals-box {
    display: flex;
    flex-direction: column;
    min-height: 377px;
  }
  .toolsContainer {
    display: flex;
    justify-content: space-between;

    .bookmarkBtn,
    .shareBtn {
      cursor: pointer;
    }
  }

  .itemDetails {
    margin: 13px 0;
    h2 {
      font-size: 12px;
      font-weight: 700;
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      line-height: 10px;
    }
    ul li {
      margin: 0;
    }
    .more {
      color: #5d9600;
      text-align: justify;
      font-family: Inter;
      font-size: 10px;
      font-weight: 700;
      text-decoration-line: underline;
    }
    .detailTitle {
      font-size: 10px;
      font-weight: 700;
    }
    .detailValue {
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-weight: 400;
    }
    .priceTitle {
      color: #000;
      text-align: justify;
      font-size: 10px;
      font-weight: 700;
    }
    .priceValue {
      color: #ef0000;
      font-size: 10px;
      font-weight: 700;
    }
    span.locationTitle {
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-weight: 500;
    }
    .total {
      font-family: Inter;
      font-size: 12px;
      font-weight: 700;
        text-wrap: nowrap;

    }
    .base-form {
      width: 65% !important;
      margin-bottom: 0 !important;
      min-height: initial !important;
    }
    .bookBtn.mainBtn {
      width: 100%;
      height: 30px;
      min-height: initial;
      padding: 0;
    }
    .halfBtn.mainBtn {
      width: 50%;
      height: 30px;
      min-height: initial;
      padding: 0;
    }
  }
}
.mapBox {
  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    border-radius: 10px;
  }

  .leaflet-container {
    border: 1px solid #cccccc;
  }
}
.calendarBox {
  width: 100%;
  .reservationStatus {
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    p {
      margin: 10px 0;
    }
  }
  /deep/ {
    .dp__month_year_row {
      border-radius: 20px 20px 0px 0px;
      background: #cacaca;
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 24px;
    }

    .dp__outer_menu_wrap {
      border-radius: 20px 20px 0px 0px;
    }

    .dp__menu.dp__relative {
      border-radius: 20px;
    }

    .dp__month_year_wrap {
      height: 24px;

      button {
        max-height: 24px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }

    .dp__calendar_header_item {
      height: initial !important;
    }

    .dp__calendar_header {
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .dp__instance_calendar {
      border-radius: 20px;
    }

    .dp__outer_menu_wrap {
      width: 100%;
    }

    .dp__menu_inner {
      padding: 0;
      background: transparent transparent;
      border: none none;
    }

    .dp__calendar {
      background: #dedede;
      border-radius: 0px 0px 20px 20px;
      padding-bottom: 7px;

      .dp__calendar_item {
        display: flex;
        justify-content: center;
      }

      .dp__cell_inner {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        /* border: 1px solid red !important; */
        background-color: #fff !important;
        color: #747474;
        font-family: Inter;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .dp__cell_disabled {
        position: relative;
        &::after {
          content: "";
          width: 1px;
          height: 100%;
          transform: rotate(45deg);
          background-color: #747474;
          position: absolute;
          opacity: 0.5;
          // background-color: rgb(4, 0, 255);
        }
      }
      .dp__today {
        background-color: rgb(51, 255, 0) !important;
        color: white;
        cursor: pointer;
      }

      .dp__range_end,
      .dp__range_start {
        // background-color: rgb(255, 217, 0) !important;
              background-color: #6daf00 !important;

        color: white;
        z-index: 100;
      }

      .dp__range_between {
        // background-color: rgb(255, 217, 0) !important;
              background-color: #6daf00 !important;

        color: white;
      }

      .dp__cell_offset {
        color: #cccaca !important;
      }

      .dp__action_row {
        display: none !important;
      }
    }
  }
}

.calendarBox /deep/ .dp__action_row {
  display: none !important;
}

.carouselBox {
  /deep/ {
    .carousel__track {
      margin: 0 !important;
    }
  }
}
  /deep/ .greenSelected {
    .dp__range_end,
    .dp__range_start {
      background-color: #6daf00 !important;
      color: white;
      z-index: 100;
    }

    .dp__range_between {
      background-color: #6daf00 !important;
      color: white;
    }
  }

.leftArrowBtn {
  background: #eadcdc;
  border-radius: 100%;
  opacity: 0.8;
}

.carousel__item img {
  border-radius: 30px;
}
</style>
