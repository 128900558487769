<template>
    <notifications position="top center" duration="5000"/>

<router-view></router-view>


 <!-- <chat-home></chat-home> -->
</template>

<script>
// import { useRouter } from "vue-router";
// import TheNotifications from './pages/TheNotifications.vue'

export default {
  setup() {
    // const router = useRouter();
    // router.push({
    //   name: "TheNotificationsVue",
    //   // name: "TheHistory",
    // });
    return {};
  },
};
</script>

<style lang="scss" scoped >
#app{
  /* overflow-x: hidden; */
}
</style>
