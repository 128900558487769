import './assets/styles/main.scss';
import { createApp } from 'vue';
import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.js";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "vue3-toastify/dist/index.css";
import "vue3-carousel/dist/carousel.css";
import Toast from "vue3-toastify";
import Notifications from "@kyvg/vue3-notification";
import router from "./router";
import VOtpInput from "vue3-otp-input";
import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css'
import BaseButton from './components/base/BaseButton.vue';
import FeatureButton from './components/FeatureButton.vue';
import BaseModal from './components/base/BaseModal.vue';
import BaseHeader from './components/base/BaseHeader.vue';
import TheHome from './pages/TheHome.vue';
import TheHome2 from './pages/TheHome2.vue';
import SignIn from './components/Auth/SignIn.vue';
import SignUp from './components/Auth/SignUp.vue';
import TheVerify from './components/Auth/TheVerify.vue';
import BaseForm from './components/base/BaseForm.vue';
import BaseDialog from './components/base/BaseDialog.vue';
import BaseNav from './components/base//BaseNav.vue';
import TheProfile from './pages/profile/TheProfile.vue';
import EditProfile from './pages/profile/EditProfile.vue';
import MyWallet from './pages/wallet/MyWallet.vue';
import ViewCards from './pages/wallet/ViewCards.vue';
import AddCart from './pages/wallet/AddCart.vue';
import TheNotifications from './pages/TheNotifications.vue';
import TheTransactions from './pages/wallet/TheTransactions.vue';
import RequestCategory from './pages/RequestCategory.vue';
import PublishWaiting from './pages/PublishWaiting.vue';
import PublishDone from './pages/PublishDone.vue';
import AddView from './pages/AddView.vue';
import MyRentals from "./pages/rental/MyRentals.vue";
import MyRequests from "./pages/request/MyRequests.vue";
import SingleRentalPage from "./pages/SingleRentalPage.vue";
import MyRentalPage from "./pages/MyRentalPage.vue";
import SendRequest from "./pages/request/SendRequest.vue";
import TheAdvertisements from "./pages/TheAdvertisements.vue";
import UploadImage from "./pages/UploadImage.vue";
import DatePicker from "vue3-datepicker";
import TheAuth from "./components/Auth/TheAuth.vue";
// chat
import ChatHome from "./pages/chat/views/ChatHome.vue"
import TheContact from "./pages/chat/components/TheContact.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
const app = createApp(App);
const vfm = createVfm()

app.component('v-otp-input', VOtpInput);
app.component('base-button', BaseButton);
app.component('feature-button', FeatureButton);
app.component('the-home', TheHome);
app.component('the-home2', TheHome2);
app.component('sign-in', SignIn);
app.component('sign-up', SignUp);
app.component('the-verify', TheVerify);
app.component('the-profile', TheProfile);
app.component('edit-profile', EditProfile);
app.component('base-form', BaseForm);
app.component('base-nav', BaseNav);
app.component('base-header', BaseHeader);
app.component('base-dialog', BaseDialog);
app.component('base-modal', BaseModal);
app.component('my-wallet', MyWallet);
app.component('view-cards', ViewCards);
app.component('add-cart', AddCart);
app.component('the-notifications', TheNotifications);
app.component('the-transactions', TheTransactions);
app.component('request-category', RequestCategory);
app.component('publish-waiting', PublishWaiting);
app.component('publish-done', PublishDone);
app.component('add-view', AddView);
app.component("my-rentals", MyRentals);
app.component("my-requests", MyRequests);
app.component("single-rental-page", SingleRentalPage);
app.component("my-rental-page", MyRentalPage);
app.component("the-advertisements", TheAdvertisements);
app.component("send-request", SendRequest);
app.component("upload-image", UploadImage);
app.component("vue3-datepicker", DatePicker);
app.component("the-auth", TheAuth);
app.component('VueDatePicker', VueDatePicker);

app.component("VueDatePicker", VueDatePicker);


app.component("chat-home", ChatHome);
app.component("the-contact", TheContact);


export { app, vfm, router,Toast,Notifications };