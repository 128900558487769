<template>
  <main class="chathome pb-24 pt-8">
    <loading :active="isLoading" :is-full-page="true"></loading>

    <!-- <Header>
      <template v-slot:start>
        <span>
          <i class="active:scale-90 duration-300 fa fa-chevron-left text-xl"></i>
        </span>
      </template>
      <template v-slot:center>
        <span class="font-medium text-2xl">Chats</span>
      </template>
      <template v-slot:end>
        <span>
          <i class="active:scale-90 duration-300 fa fa-edit text-xl"></i>
        </span>
      </template>
    </Header> -->
    <base-header
      pageTitle="Chats"
      :leftArrowClick="goToSpecialRoute"
    ></base-header>

    <SearchBar :isBell="false" @search="searchAction" />

    <section style="min-height: 460px">
      <TheContacts :contacts="items.filteredContacts" />
    </section>

    <!-- <TheNavbar /> -->
  </main>
  <base-nav></base-nav>
</template>

<script>
import { useContacts } from "../stores/contacts";
import { computed, onMounted, reactive,ref } from "vue";
// import Header from '@/components/Header.vue'
// import TheNavbar from '../components/TheNavbar.vue'
import SearchBar from "../components/SearchBar.vue";

import TheContacts from "../components/TheContacts.vue";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
import router from "@/router";
import { useStore } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
export default {
  components: {
    SearchBar,
    TheContacts,
    Loading,
  },
  setup() {
    const store = useStore();
//eslint-disable-next-line
var { accessToken, authenticated } = useAccessToken(store);
    const isLoading = ref(false);

    const contacts = computed(() => useContacts().contacts);
    var items = reactive({ filteredContacts: [] });
    const searchAction = () => {
      const currentKeyword = computed(() => useContacts().currentKeyword);
      items.filteredContacts = useContacts().contacts.filter((contact) =>
        contact.name.toLowerCase().includes(currentKeyword.value.toLowerCase())
      );
    };
    function goToSpecialRoute() {
      router.push({ name: "TheAdvertisements" });
    }
    function loadAllconversations() {
      // if (authenticated) {
      // Loading.pulse();
      isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/message/load-all-conversation",
      })
        .then(function (response) {
          // Loading.remove();
          isLoading.value = false;

          if (response.status === 200) {
            //
            // sendAlert("all conversations loaded ", "success");
            // store.commit("setProfile", response.data);
            useContacts().setContacts(response.data);
            items.filteredContacts = response.data;
          }
        })
        .catch(function (error) {
                    isLoading.value = false;

          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }
    onMounted(() => {
      loadAllconversations();
    });

    return {
      contacts,
      searchAction,
      loadAllconversations,
      items,
      goToSpecialRoute,isLoading
    };
  },
};
</script>
<style lang="scss" scoped >
main {
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative; /* Add relative positioning to the container */
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute; /* Set absolute positioning for the scrollbar */
  right: 0;
  z-index: 1000;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
::v-deep .search-container {
  padding: 19px 0px 25px 15px;
  gap: 12px;
  position: sticky;
  top: 30px;
  background: white;
  z-index: 1000;
}
</style>
