<template>
        <loading :active="isLoading" :is-full-page="true"></loading>

  <div
    @click="chatWithUser"
    :class="{ unReadMessageClass: contact.unReadMessage }"
    class="contactContainer w-100 active scale-90 duration-300 d-flex justify-content-between align-items-center py-2 px-3 gap-1"
  >
    <div class="d-flex justify-content-between align-items-center gap-3">
      <div v-if="contact.avatar !== null">
        <img
          width="82"
          class="rounded-circle"
          src="../../../assets/images/profile1.jpg"
        />
        <!-- :src="contact.avatar" -->
      </div>

      <div
        class="text-white p-3 rounded-3 bg-primary mx-3"
        v-else-if="contact.avatar === null"
      >
        {{ contact.initial }}
      </div>

      <div class="">
        <h1 class="title-black-color">{{ contact.name }}</h1>
        <p class="text-black-color">
          {{ generatePreviewText(contact.lastMessage) }}
        </p>
      </div>
    </div>

    <div class="text-muted d-flex flex-column align-items-center gap-2">
      <p class="text-xs mb-4">{{ contact.timestamp }}</p>
      <!-- <i
        v-if="contact.unReadMessage > 0"
        class="fa fa-circle text-xs text-primary"
      ></i> -->
    </div>
  </div>
</template>

<script>
import { onMounted,ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";

import { useAccessToken } from "@/components/composable/useAccessToken";
// import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
export default {
  props: {
    contact: {
      type: Object,
    },
  },components:{
    Loading
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false)
    //eslint-disable-next-line
    var { accessToken, authenticated } = useAccessToken(store);
    const router = useRouter();


    function chatWithUser() {
      // if (authenticated) {
      // Loading.pulse();
      isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/message/create-conversation",
        data: {
          user_id: props.contact.id,
        },
      })
        .then(function (response) {
          // Loading.remove();
          isLoading.value = false;

          if (response.status === 200) {
            //
            setTimeout(() => {
              router.push({
                name: "TheChat",
                params: { id: response.data.conversation_id },
              });
            }, 200);
          }
        })
        .catch(function (error) {
          //sendAlert(error.response.data, "error");
                    isLoading.value = false;

          serverError(error);
        });
    }

    const generatePreviewText = (text) => {
      const textArr = text.split("");
      if (textArr.length < 25) return textArr.join("");
      else return textArr.slice(0, 25).join("") + "...";
    };
    onMounted(() => {});

    return {
      generatePreviewText,chatWithUser,isLoading
    };
  },
};
</script>
<style lang="scss" scoped >
.text-black-color {
  color: #171717;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.title-black-color {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contactContainer {
  min-height: 110px;
  background: linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
}
.unReadMessageClass {
  background: var(--chat, #93c300);
}
</style>
