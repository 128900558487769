<!-- EditProfile.vue -->
<template>
  <div class="page position-relative h-100 bg-white">
    <loading :active="isLoading" :is-full-page="true"></loading>

    <base-header
      :pageTitle="pageTitle"
      :noButton="false"
      :leftArrowClick="goToSpecialRoute"
    ></base-header>

    <!-- profileBox  -->
    <div
      class="d-flex flex-column justify-content-center align-items-center gap-3 mt-3 mb-3"
    >
      <div style="cursor: pointer" class="imgWrapper bg-dark" id="uploadButton">
        <!-- <img src="../../assets/images/profile.svg" alt="" /> -->
        <img v-if="imgPath" :src="imgPath" alt="" />
        <img v-else src="../../assets/images/profile-not-set.svg" alt="" />
        <!-- <img src="../../assets/images/profile-not-set.svg" alt="" /> -->

        <input
          name="attachInput"
          type="file"
          ref="fileInput"
          style="display: none"
          id="fileInput"
          accept="image/*"
          @change="setAttachment"
        />
      </div>
    </div>

    <div class="editProfile h-100">
      <label for="" class="">First Name</label>
      <base-form
        v-model="items.first_name"
        :placeholder="'First Name '"
        class="mb2"
      />
      <label for="" class="">last Name</label>

      <base-form
        v-model="items.last_name"
        :placeholder="'Last Name '"
        class="mb2"
      />

      <label for="" class="">email</label>
      <base-form v-model="items.email" readonly class="mb2" />

      <label for="" class="">city</label>
      <base-form v-model="items.city" :placeholder="'City '" class="mb2" />

      <label for="" class="">Phone</label>
      <base-form v-model="items.phone" :placeholder="'Phone '" class="mb2" />

      <label for="" class="">Address</label>
      <base-form
        v-model="items.address"
        :placeholder="'Address '"
        class="mb2"
      />

      <label for="" class="">location</label>

      <div class="mapBox rounded-2">
        <div style="height: 135px" class="mt-2 rounded-2">
          <LMap v-model:zoom="zoom" :center="getMapCenter" @move="log('move')">
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ></l-tile-layer>
            <!-- <l-control-layers /> -->
            <l-marker
              :lat-lng="getMapCenter"
              @moveend="afterMoveMarker"
              draggable
            >
              <l-tooltip> marker</l-tooltip>
            </l-marker>

            <!-- <l-marker :lat-lng="[47.41322, -1.219482]">
              <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
            </l-marker> -->

            <!-- <l-marker :lat-lng="[50, 50]" draggable @moveend="log('moveend')">
              <l-popup> lol </l-popup>
            </l-marker> -->
          </LMap>
        </div>
      </div>

      <base-button class="w-100 mt-3" @click="saveEditedProfile"
        >Save</base-button
      >
    </div>

    <!-- <base-nav></base-nav> -->
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { useAccessToken } from "@/components/composable/useAccessToken";
import axios from "axios";
import { useStore } from "vuex";
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
import "vue-loading-overlay/dist/css/index.css";
import { useRouter } from "vue-router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet";

const pageTitle = ref("Edit Profile");
const isLoading = ref(false);
const store = useStore();
const fileInput = ref(null);
const router = useRouter();

var { accessToken, authenticated } = useAccessToken(store);
var items = reactive({
  first_name: "",
  last_name: "",
  avatar: null,
  city: "",
  phone: "",
  address: "",
  avatar_file: null,
  avatar_URL: null,
  email: store.state.user.email,
  lat: 0,
  lng: 0,
});

const zoom = ref(7);
//eslint-disable-next-line
const date = ref([null, null]);
//eslint-disable-next-line
const log = (a) => {};

const getMapCenter = computed(() => {
  const lat = parseFloat(items.lat);
  const lng = parseFloat(items.lng);

  if (!isNaN(lat) && !isNaN(lng)) {
    return [lat, lng];
  } else {
    return [35.7219, 51.3347];
  }
});

function afterMoveMarker(e) {
  items.lat = e.target._latlng.lat;
  items.lng = e.target._latlng.lng;
}

function goToSpecialRoute() {
  router.push({ name: "TheProfile" });
}

const imgPath = computed(() => {
  if (
    items.avatar_URL?.includes(
      "http://estirium.ir/storage/http://estirium.ir/storage/http://estirium.ir/storage/http://estirium.ir/storage/http://estirium.ir/storage/avatar/avatar6.png"
    )
  )
    return null;
  return items.avatar_URL;
});

function setAttachment(e) {
  items.avatar_file = e.target.files[0];
  setAvatarUrl();
}

function saveEditedProfile() {
  if (authenticated) {
    isLoading.value = true;

    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    const formData = new FormData();
    formData.append("first_name", items.first_name);
    formData.append("last_name", items.last_name);

    if (items.avatar_URL) {
      formData.append("avatar", items.avatar_URL);
    } else {
      formData.append("avatar", null);
    }

    formData.append("city", items.city);
    formData.append("phone", items.phone);
    formData.append("address", items.address);
    formData.append("lat", items.lat);
    formData.append("lng", items.lng);

    axios({
      method: "POST",
      url: "https://api.atdeloop.com/api/profile/save",
      data: formData,
    })
      .then(function (response) {
        isLoading.value = false;

        if (response.status === 200) {
          sendAlert("Profile updated ", "success");
          store.commit("setProfile", {
            first_name: items.first_name,
            last_name: items.last_name,
            profile_photo: items.avatar_URL,
            city: items.city,
            phone: items.phone,
            address: items.address,
            lat: items.lat,
            lng: items.lng,
          });

          router.push({
            name: "TheProfile",
          });
        }
      })
      .catch(function (error) {
        isLoading.value = false;
        serverError(error);
      });
  }
}

function loadEditedProfile() {
  if (authenticated) {
    isLoading.value = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    axios({
      method: "POST",
      url: "https://api.atdeloop.com/api/profile/load",
    })
      .then(function (response) {
        isLoading.value = false;
        store.commit("setProfile", response.data);

        items.first_name = response.data.first_name;
        items.last_name = response.data.last_name;
        items.address = response.data.address;
        items.city = response.data.city;
        items.phone = response.data.phone;
        items.avatar_URL = response.data.profile_photo;
        items.lat = response.data.lat;
        items.lng = response.data.lng;
      })
      .catch(function (error) {
        isLoading.value = false;
        serverError(error);
      });
  }
}

function setAvatarUrl() {
  items.isImageLoading = true;
  if (items.avatar_URL) {
    const reader = new FileReader();

    reader.onload = () => {
      items.avatar_URL = reader.result;
    };

    reader.readAsDataURL(items.avatar_file);
  }
}

onMounted(() => {
  loadEditedProfile();
  document
    .getElementById("uploadButton")
    .addEventListener("click", function () {
      document.getElementById("fileInput").click();
    });
  imgPath.value = store.state.profile.profile_photo;
});
</script>

<style lang="scss" scoped>
.mapBox {
  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    border-radius: 10px;
  }

  .leaflet-container {
    border: 1px solid #cccccc;
  }
}

.editProfile {
  border-radius: 50px 50px 0px 0px;
  background: #fff;
  box-shadow: 4px 0px 250px 0px rgba(0, 0, 0, 0.25);
  padding: 34px 35px 30px 35px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  label {
    color: #959595;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 5px;
  }
}

.imgWrapper {
  border-radius: 100%;
  img {
    border-radius: 100%;
    max-width: 117px;
  }
}
.page {
  // height: 926px;
  background: #fafafa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.menuContent {
  height: 75vh;
  background-color: aqua;
}

.base-form {
  display: flex;
  width: 100%;
  min-height: 47px;
  margin-bottom: 10px;
}
.form-control {
  border-radius: 30px;
  background: #dedcdc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset;
  color: #959595;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
