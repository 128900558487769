<template>
	<main id ="roomChat" class="mainContent w-full flex-grow-1 ">
		<BallonChat text="The position of your chat will be randomized so ... 😀" :left="false" :date="'10:40 AM'"/>
		<template v-for="(chat, x) in chats" :key="x">
			<BallonChat :text="chat.text" :left="!chat.left" :date="chat.date"/>
		</template>
	</main>
</template>

<script setup>
	// import { useRoute } from 'vue-router'
	import { computed } from 'vue'
	import { useChats } from '../stores/chats'
	import BallonChat from '../components/BallonChat.vue'

	const state = useChats()
	// const route = useRoute()

	// const paramsId = computed(() => route.params.id)
	const chats = computed(() => state.chats)
	
</script>
<style lang="scss" scoped >
.mainContent{
	/* max-height: 85vh; */
	overflow: auto;
	/* max-height: 590px; */
  background: #f9f9f9;
  height: 100%;
	border-radius: 50px 50px 0px 0px;
  background: #fff;
  box-shadow: 4px 0px 250px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 20px 30px 20px;
}
::-webkit-scrollbar {
width: 5px;
height: 5px; }

::-webkit-scrollbar-thumb {
background-color: rgba(0, 0, 0, 0.2);
-webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,0.10),inset 0 -1px 0 rgba(0,0,0,0.07); }
</style>