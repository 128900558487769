<template>
  <div class="page position-relative h-100 d-flex flex-column">
        <loading :active="isLoading" :is-full-page="true"></loading>

    <base-header 
    :NoBG="true"

    :leftArrowClick="goToSpecialRoute"
    ></base-header>

    <div class="titleBox w-100 px-4 py-4">
      <h2>Add your Card</h2>
      <h4>Fill in the fields below or use camera phone</h4>
    </div>
    <div class="mainContent flex-grow-1">
      <div class="BtnBox w-100 mb-4">
        <card-type-btn
          v-for="(method, index) in paymentMethods"
          :key="index"
          :paymentMethod="method"
          @click="items.selectedCard = method"
          :pressed="method == items.selectedCard"
        />
      </div>

      <div class="formBox w-100">
        <div class="d-flex flex-column">
          <label for="expiryDate1 w-100">your card number</label>
          <div class="base-form">
            <input
              id="cardnumber"
              type="text"
              class="form-control w-100"
              placeholder="Enter your card Number"
              :value="formattedCardNumber"
              @input="items.number = $event.target.value.replace(/\D/g, '')"
              maxlength="19"
            />
          </div>
        </div>
        <div class="d-flex flex-row gap-2">
          <div class="d-flex flex-column w-50">
            <label for="expiryDate1 w-100">Expiry Date</label>
            <div class="d-flex flex-row gap-1 w-100">
              <div class="base-form">
                <input
                  id="expiryYear"
                  class="roundedLeft form-control"
                  type="text"
                  v-model="items.year"
                  maxlength="2"
                />
              </div>

              <div class="base-form">
                <input
                  id="expiryMonth"
                  v-model="items.month"
                  class="roundedRight form-control"
                  type="text"
                  maxlength="2"
                />
              </div>
            </div>
          </div>
          <div>
            <label for="CVV2 w-100">CVV2</label>

            <div class="base-form">
              <input
                v-model="items.cvv2"
                id="CVV2"
                class="w-100 form-control half-width"
                half
                type="text"
                max="9999"
                maxlength="4"
              />
            </div>
          </div>
          <!-- <base-button></base-button> -->
        </div>
      </div>

      <!-- <base-button
        class="scanBtn w-100 text-nowrap d-flex flex-row mt-2"
        hasImage
      >
        <span
          ><img
            src="../../assets/images/creditCart/scanning.svg"
            alt="" /></span
        >Scan card info by camera
      </base-button> -->
      <!-- <div class="ScanArea mt-4"></div> -->

      <base-button @click="saveCard">Save My Card</base-button>
    </div>

  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from "vue";
import CardTypeBtn from "./CardTypeBtn.vue";
import serverError from "@/components/composable/useServerError";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

const items = reactive({
  selectedCard: "",
  number: "",
  month: "",
  year: "",
  cvv2: "",
});
const router = useRouter();
const isLoading = ref(false);

const store = useStore();
//eslint-disable-next-line
const { accessToken, authenticated } = useAccessToken(store);
//eslint-disable-next-line
const pageTitle = ref("X");
const paymentMethods = ref(["mastercard", "paypal", "google", "apple"]);

const formattedCardNumber = computed(() => {
  let cardNumber = items.number.replace(/\D/g, "");
  cardNumber = cardNumber.slice(0, 16);
  cardNumber = cardNumber.replace(/(\d{4})/g, "$1 ");
  return cardNumber.trim();
});
//eslint-disable-next-line
const handleCardNumberInput = (event) => {
  const input = event.target.value.replace(/\D/g, "");
  if (input.length > 16) {
    items.number = input.slice(0, 16);
  } else {
    items.number = input;
  }
};
function goToSpecialRoute() {
      router.push({ name: 'MyWallet' });
    }

function saveCard() {
          isLoading.value = true;

  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: "https://api.atdeloop.com/api/profile/add-card",
    data: {
      number: items.number,
      expire_date: `${items.month}/${items.year}`,
      cvv2: items.cvv2,
      bank: items.selectedCard,
    },
  })
    .then((response) => {
              isLoading.value = false;

      if (response.status === 200) {
        sendAlert("card saved", "success");
        
        // cvv2InputRef.value.focus();
        router.push({
          name: "ViewCards"
        });
      }
    })
    .catch((error) => {
              isLoading.value = false;

      serverError(error);
    });
}

onMounted(() => {
  // Your additional initialization logic here
});
</script>

<style scoped lang="scss"  >
.titleBox {
  h2 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  h4 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 36px;
  border-radius: 50px 50px 0px 0px;
  background: #fff;
  box-shadow: 4px 0px 100px 0px rgba(0, 0, 0, 0.25);
  .BtnBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .scanBtn {
    border-radius: 10px;
    background: var(
      --header,
      linear-gradient(180deg, #82d100 0%, #5d9600 100%)
    );
  }

  .ScanArea {
    width: 283px;
    height: 168px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #d9d9d9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  #cardnumber {
    font-size: 20px;
    text-align: center;
  }
}

.half-width {
  width: 48.5%;
}

label {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 7px;
  padding-bottom: 4px;
}

.roundedLeft {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.roundedRight {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.base-form {
  display: flex;
  width: 100%;
  min-height: 47px;
  margin-bottom: 10px;
}
.form-control {
  border-radius: 30px;
  background: #dedcdc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset;
  color: #959595;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
