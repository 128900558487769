<template>
  <div class="wrapper" v-if="request">
    
    <div class="imgContainer" v-if="request.gallery.length">
      <img class="mainImg img-fluid" :src="request.gallery[0]" alt="" />
    </div>
    <div v-else>
      <img src="../../assets/images/gallery/no-photo.jpg" alt="" class="img-fluid w-100 rounded-2"
                style="max-height: 100px" />

    </div>

    <div class="details">
      <h2 class="fw-700 fs-10">
        {{ props.title }}
      </h2>
      <div class="d-flex flex-column gap-1">
        <span>Number: {{ request.medias.length }}</span>
        <span>{{ request.reserve_day }} day</span>
      </div>
      <div style="cursor: pointer;" class="notif-box">
        <img  @click.stop="goToChat" src="../../assets/images/Comments.svg" alt="" />
      </div>
      <span style="cursor: pointer;"  class="notif-number bg-danger text-white">{{request.messages}}</span>
    </div>
  </div>
  <div class="line"></div>
</template>
<!-- {
          number: "1",
          day: "9",
          amount: "800",
          image: "/path/to/up_arrow_icon.png",
        } -->
<script setup>
import { defineProps, computed  } from "vue";
import { useRouter } from "vue-router";
const router = useRouter()
const props = defineProps({
  title: String,
  number: String,
  day: String,
  image: String,
  request: Array,
});

function goToChat (){
  router.push({
    name:'Chathome'
  })
}
//eslint-disable-next-line
//eslint-disable-next-line
const imageSrc = computed(() => require(`@/${props.image}`));

</script>

<style lang="scss" scoped >
.wrapper {
  position: relative;
  width: 100%;
  border-radius: 10px;
  padding: 11px 26px;
  /* background: #dedcdc; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  gap: 15px;
  .imgContainer {
    img {
      width: 132px;
    max-height: 82px;
    border-radius: 10px;
    }
   
    
  }
  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      color: #747474;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    h2 {
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .notif-box {
      position: absolute;
      right: 18px;
      bottom: 9px;
    }
    .notif-number {
      position: absolute;
      padding: 3px;

      right: 18px;
      bottom: 24px;
      border-radius: 50%;
      line-height: 6px;
      border: 1px solid white;
    }
  }
}

.line {
  width: 88%;
  height: 1px;
  background: #b4b4b4;
  margin: auto;
}
</style>
