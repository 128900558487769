<template>
  <div class="page position-relative h-100">
        <loading :active="isLoading" :is-full-page="true"></loading>

    <base-header :pageTitle="pageTitle"
    :leftArrowClick="goToSpecialRoute"
    ></base-header>
    <!-- <base-nav></base-nav> -->
    <div class="h-100 d-flex justify-content-end"></div>

    <div
      v-if="items.transactions?.data?.length == 0"
      class="d-flex w-100 justify-content-center align-items-center noElementsToShow mt-5"
    >
      <h4 class="text-center">No Older Transactions</h4>
    </div>

    <div class="mainContent">
      <div class="transBox p-4">
        <SingleTrans
          v-for="(transaction, index) in items.transactions.data"
          :key="index"
          :transaction="transaction"
        />
        <!-- <SingleTrans
          v-for="(transaction, index) in items.transactions.data"
          :key="index"
          :transactionType="transaction.type"
          :day="transaction.day"
          :month="transaction.month"
          :amount="transaction.amount"
          :transactionIcon="transaction.icon"
          :transaction="transaction"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import SingleTrans from "./SingleTrans.vue";
import { ref, onMounted, reactive } from "vue";
import serverError from "@/components/composable/useServerError";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
import router from "@/router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  components: {
    SingleTrans,Loading
  },
  setup() {
    const isLoading = ref(false);

    const store = useStore();
    //eslint-disable-next-line
    var { accessToken, authenticated } = useAccessToken(store);
        const pageTitle = ref("Transactions");
    var items = reactive({ transactions: [] });

    // const transactions = ref([
    //   {
    //     type: "deposit",
    //     day: "9",
    //     month: "October",
    //     amount: "800",
    //     icon: "/path/to/up_arrow_icon.png",
    //   },
    //   // ... (rest of the transactions)
    // ]);
    function goToSpecialRoute() {
      router.push({ name: 'MyWallet' });
    }
    function loadWalletTransactions() {
      // if (authenticated) {
      // Loading.pulse();
      isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/profile/transactions-wallet",
      })
        .then(function (response) {
          // Loading.remove();
          isLoading.value = false;

          if (response.status === 200) {
            // 
            // sendAlert("rentals loaded ", "success");
            items.transactions = response.data;
          }

          // items.first_name = response.data.first_name;
          // items.last_name = response.data.last_name;
          // items.address = response.data.address;
          // items.city = response.data.city;
          // items.phone = response.data.phone;
          // items.avatar_URL=response.data.
        })
        .catch(function (error) {
          // //Loading.remove();
          isLoading.value = false;

          
          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }

    onMounted(() => {
      loadWalletTransactions();
    });
    return {
      pageTitle,
      loadWalletTransactions,
      items,goToSpecialRoute,isLoading
    };
  },
};
</script>

<style lang="scss" scoped>
.mainContent {
  .transBox {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
}
.page{
  min-height: 100vh !important;
}
.noElementsToShow {
  color: #969696;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
