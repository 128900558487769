<template>
  <div class="page position-relative h-100">
        <loading :active="isLoading" :is-full-page="true"></loading>

    <base-header :pageTitle="items.pageTitle"
    :leftArrowClick="goToSpecialRoute"
    ></base-header>

    <div class="mainContent">
      <div class="topBox d-flex">
        <div class="featureBox">
          <feature-button
            title="Add money"
            size="small"
            :imgSrc="require('../../assets/images/feature/Add Money.svg')"
            color="grey"
          >
          </feature-button>
          <router-link :to="{ name: 'AddCart' }">
            <feature-button
              title="Add Cards"
              size="small"
              :imgSrc="
                require('../../assets/images/feature/Add Credit Card.svg')
              "
              color="grey"
            >
            </feature-button>
          </router-link>
          <router-link :to="{ name: 'Transactions' }">
          <feature-button
            title="Transactions"
            size="small"
            :imgSrc="require('../../assets/images/feature/Transaction.svg')"
            color="grey"
          >
          </feature-button>
        </router-link>
        </div>
        <div class="cardBox">
          <div class="mainCard p-4">
            <h5 class="balance"><span>$ </span>{{ items.cardBalance }}</h5>
            <span class="bank">{{ items.bankName }}</span>
            <h4 class="cardNumber">{{ items.cardNumber }}</h4>
            <img class="cardLogo" :src="items.cardLogoSrc" alt="" />
          </div>
          <div class="sideCard1"></div>
          <div class="sideCard2"></div>
          <div class="sideCard3"></div>
          <div class="sideCard4"></div>
        </div>

        <div class="viewAllCards">
          <img :src="items.creditCardIconSrc" alt="" />

          <a @click="goToViewCards" class="ms-2" href="">View all cards</a>
        </div>
      </div>



      <div class="recentTrans py-4 px-4">
        <div class="moreTrans pb-3">
          <h6>Recent Transactions</h6>
          <router-link :to="{ name: 'Transactions' }">
          <a href="">See all</a>
        </router-link>
        </div>

        <div
      v-if="items.transactions?.length == 0"
      class="d-flex w-100 justify-content-center align-items-center noElementsToShow mt-5"
    >
      <h4 class="text-center">No Older Transactions</h4>
    </div>


        <div class="transBox">
          <SingleTrans
            v-for="(transaction, index) in items.transactions"
            :key="index"
            :transaction="transaction"
          />
        </div>
      </div>
    </div>

    <base-nav></base-nav>
  </div>
</template>

<script>
import { reactive, onMounted,ref } from "vue";
import SingleTrans from "./SingleTrans.vue";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
// import router from "@/router";
import { useRouter } from 'vue-router';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  components: {
    SingleTrans,Loading
  },
  setup() {
    var items = reactive({
      pageTitle: "My Wallet",
      cardBalance: 3000,
      bankName: "",
      cardNumber: "",
      cardLogoSrc: require("../../assets/images/Mastercard.svg"),
  creditCardIconSrc: require("../../assets/images/Credit Card.svg"),
      transactions: [],
    });
    const store = useStore();
    const router = useRouter();
    //eslint-disable-next-line
    var { accessToken, authenticated } = useAccessToken(store);
    const isLoading = ref(false);


    function goToSpecialRoute() {
      router.push({ name: 'TheProfile' });
    }
    function loadWallet() {
      if (authenticated) {
      // Loading.pulse();
      isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/profile/your-wallet",
      })
        .then(function (response) {
          // Loading.remove();
          isLoading.value = false;

          if (response.status === 200) {
            // 
            // sendAlert("Profile loaded ", "success");
            
            // store.commit("setProfile", response.data);
            items.bankName = response.data.bank_Card.bank;
            items.cardNumber = response.data.bank_Card.number;
            items.bankName = response.data.bank_Card.bank;
            items.cardBalance = response.data.balance;
            items.transactions = response.data.wallet_transaction;
          }

          // items.first_name = response.data.first_name;
          // items.last_name = response.data.last_name;
          // items.address = response.data.address;
          // items.city = response.data.city;
          // items.phone = response.data.phone;
          // items.avatar_URL=response.data.
        })
        .catch(function (error) {
          // //Loading.remove();
          isLoading.value = false;

          
          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }}

    function goToViewCards() {
      router.push({
        name: "ViewCards",
      });
    }
    // }
    onMounted(() => {
      loadWallet();
    });
    return {
      items,
      loadWallet,
      goToViewCards,goToSpecialRoute,isLoading
    };
  },
};
</script>

<style lang="scss" scoped >
.mainContent {
  overflow: hidden;
  background: #f9f9f9;
  height: 100%;
  min-height: 100vh;
  .topBox {
    border-radius: 0px 0px 30px 30px;
    background: #f9f9f9;
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.25);

    .featureBox {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 25%;
      height: 100%;
      padding: 40px;
    }

    .cardBox {
      width: 75%;
      height: 100%;
      position: relative;
      margin-top: 40px;

      div {
        position: absolute;
        right: -142px;
      }

      .mainCard {
        width: 283px;
        height: 168px;
        border-radius: 10px;
        background: linear-gradient(
          62deg,
          #5d9600 0.77%,
          #c2ea00 41.28%,
          #125800 84.79%,
          #68a000 87.19%
        );
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        z-index: 50;
        opacity: 1;
        right: -125px;
        &:hover {
          transform: translateX(-10px);
        }

        .balance {
          width: 77px;
          height: 17px;

          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .bank {
          width: 87px;
          height: 9px;

          color: #000;
          font-family: Inter;
          font-size: 8px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .cardNumber {
          color: #000;
          font-family: Inter;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .cardLogo {
          width: 45px;
          height: 45px;
        }
      }

      .sideCard1 {
        width: 283px;
        height: 168px;
        transform: rotate(14.615deg);

        border-radius: 10px;
        opacity: 0.5;
        background: #9bc060;
        z-index: 40;
      }
      .sideCard2 {
        width: 283px;
        height: 168px;
        transform: rotate(7.352deg);

        border-radius: 10px;
        background: rgba(145, 232, 3, 0.61);
        z-index: 30;
      }
      .sideCard3 {
        width: 283px;
        height: 168px;
        transform: rotate(-7.973deg);

        border-radius: 10px;
        background: #b9ee63;
        z-index: 30;
      }
      .sideCard4 {
        width: 283px;
        height: 168px;
        transform: rotate(-14.111deg);

        border-radius: 10px;
        opacity: 0.5;
        background: rgba(93, 150, 0, 0.55);
        z-index: 40;
      }
    }

    .viewAllCards {
      display: flex;
      flex-direction: row;
      position: absolute;
      right: 30px;
      top: 300px;
      a {
        color: #5d9600;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline !important;
        text-wrap: nowrap;
        align-items: center;
        text-align: center;
        vertical-align: middle;
        margin-top: 5px;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .recentTrans {
    .moreTrans {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      h6 {
        color: #000;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      a {
        color: #5d9600;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline !important;
      }
    }
    .transBox {
      display: flex;
      flex-direction: column;
      gap: 13px;
    }
  }
}
.noElementsToShow {
  color: #969696;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
