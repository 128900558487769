<template>
  <div
    :class="[
      'base-form',
      { 'half-width': isHalf },
      { 'half-width': half },
      { 'position-relative': isSearch },
    ]"
  >
    <input :class="{'text-center':textCenter}"
      :type="isPassword ? (showPassword ? 'text' : 'password') : type"
      class="form-control"
      v-model="value"
      :placeholder="placeholderText"
      :max="max"
      :readonly="readonly"
    />
    <span class="searchIcon" v-if="isSearch"
      ><img src="../../assets/images/Search.svg" alt=""
    /></span>
    <button v-if="isPassword" class="btn toggle-password" @click="toggleShow">
      <i
        :class="{
          'bi-eye-slash': showPassword,
          'bi-eye': !showPassword,
        }"
      ></i>
    </button>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    password: {
      type: Boolean,
      default: false,
    },
    half: {
      type: Boolean,
      default: false,
    },
    email: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    placeholder: String,

    modelValue: {
      type: [String, Number],
      default: null,
    },
    max:{
      type:Number,
      default:null
      
    },
    readonly:{
      type:Boolean,
      default:false
      
    },textCenter:{
      type:Boolean,
      default:false
    }
  },
  setup(props, { emit }) {
    const showPassword = ref(false);

    const placeholderText = computed(() => {
      if (props.email) {
        return "email";
      }
      return props.placeholder || "";
    });

    const value = ref(props.modelValue);

    const toggleShow = () => {
      showPassword.value = !showPassword.value;
    };

    watch(
      () => props.modelValue,
      (newValue) => {
        value.value = newValue;
      }
    );

    watch(
      () => value.value,
      (newValue) => {
        emit('update:modelValue', newValue);
      }
    );

    const isHalf = ref(false);
    const isSearch = ref(false);
    const isPassword = ref(false);

    return {
      showPassword,isHalf,isSearch,isPassword,
      placeholderText,
      toggleShow,
      value,
    };
  },
};
</script>

<style lang="scss" scoped >
.base-form {
  display: flex;
  width: 100%;
  min-height: 42px;
  margin-bottom: 10px;
}

.half-width {
  width: 48.5%;
}

.form-control {
  border-radius: 30px;
  background: #dedcdc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset;
  color: #959595;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.toggle-password {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: 0;
  padding-left: 0;
  margin-left: 0;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 0px 0px 0px rgba(0, 0, 0, 0.2);
  color: #959595;
  background: #dedcdc;
}

input[type="password"] {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}

.inputWhileClicked {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}

.base-form .searchInput {
  position: relative;
}

.searchIcon {
  position: absolute;
  left: 0;
  transform: translateX(50%);
}
</style>
