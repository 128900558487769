// import {reactive, ref} from "@vue/reactivity";
// import {useStore} from "vuex";

// const store = useStore()
export const useAccessToken = (store) => {


    // let accessToken=localStorage.getItem("accessToken");
    // let authenticated=localStorage.getItem("authenticated");
    // let accessToken = "199|JyRKRL3WHZpsQW3fPOWUUEYxYAzcXxgVbIZUUwkxe54d9dec";
    let accessToken = store.state.accessToken;
     let authenticated=store.state.auth

    
    return {
        accessToken,authenticated
    }
}
