// handleCorsError.js
import sendAlert from "../composable/useSendAlert.js";

export default function handleCorsError(error) {
  if (error?.response?.status === 500 || error?.response?.status === 503) {
    
    sendAlert("Server Error", "error");
  }

  if (error?.response?.status === 0 && error.message === "Network Error") {
    sendAlert("CORS Error: Unable to connect to the server", "error");
    // You can customize the error message based on your needs
  }

  if (error?.response?.data?.errors) {
    const keys = Object.keys(error.response.data.errors);
    if (keys.length > 0) {
      sendAlert(error.response.data.errors[keys[0]], "error");
    }
  }
}
