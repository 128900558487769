<template>
  <main
    class="w-full mb-4 flex position-relative"
    :class="left ? '' : 'flex-row-reverse'"
  >
    <div
      :class="left ? 'leftMessage' : 'rightMessage'"
      class="break-words text-gray-100 px-3 py-2 w-8/12 rounded-3xl text-sm"
    >
    <p
    style="word-wrap: break-word;">{{ text }}</p>
      
    </div>
    <span class="date" :class="left ? 'leftDate' : 'rightDate'">{{
      date
    }}</span>
  </main>
</template>

<script>
import { defineEmits } from "vue";

export default {
  props: {
    text: {
      type: String,
      default: "Lorem ipsum dolor",
    },
    date: {
      type: String,
    },
    left: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const emits = defineEmits(["newChat"]);

    // Your logic here if needed

    return {
      ...props,
      emits,
    };
  },
};
</script>

<style lang="scss" scoped >
.leftMessage {
  width: 80%;
  margin-right: auto;
  border-radius: 40px 40px 40px 0px;
  background: #d9d9d9;
  color: #000;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rightMessage {
  width: 80%;
  margin-left: auto;
  border-radius: 30px 30px 0px 30px;
  background: #70b400;
  color: #fff;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.date {
  position: absolute;
  color: #747474;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.leftDate {
  bottom: -15px;
}
.rightDate {
  right: 0;
  bottom: -15px;
}
</style>
