<template>
        <loading :active="isLoading" :is-full-page="true"></loading>

  <div v-if="!isLoading.value" class="page position-relative h-100">
    <base-header :pageTitle="pageTitle"
    
    :leftArrowClick="goToSpecialRoute"
    ></base-header>
    <!-- <base-nav></base-nav> -->
    <div
      v-if="items.ad?.rental?.status == 'publish'"
      class="h-100 d-flex justify-content-end isPublished mt-5 flex-column"
    >
      <h3 class="w-100 text-start ps-4 fw-bold">Published</h3>
      <p class="w-100 text-start ps-4 mt-3">
        Your ad is published and now is in the atdeloop list.
      </p>
    </div>
    <div
      v-else
      class="h-100 d-flex justify-content-end waiting mt-5 flex-column"
    >
      <h3 class="w-100 text-start ps-4 fw-bold">Waiting to publish</h3>
      <p class="w-100 text-start ps-4 mt-3">
        Your ad is in the publishing queue. The waiting time will be up to 2
        hours.
      </p>
    </div>

    <div class="mainContent px-4">
      <div class="optionBox">
        <ul class="list-unstyled">
          <router-link
            :to="{ name: `My${items.kind}Page`, params: { id: items.ad.id } }"
          >
            <li>
              <PublishOption preview />
            </li>
          </router-link>

          <router-link
          :to="{ name: 'AddView' }"
          >
          <li>
            <PublishOption adViewStats />
          </li>
           </router-link>
          <router-link
            :to="{ name: `Edit${items.kind}`, params: { id: items.ad.id } }"
          >
            <li>
              <PublishOption edit />
            </li>
          </router-link>
          <li>
            <PublishOption
              delete
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#deletePublished"
            />
          </li>
          <li v-if="items.ad.status == 'publish'">
            <PublishOption contactSupport class="border-0" />
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- starting  delete published  modal  -->
  <!-- Modal -->
  <div
    class="modal fade"
    id="deletePublished"
    tabindex="-1"
    aria-labelledby="deletePublished"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content border-0">
        <div class="modal-body">
          <h3 class="modal-title text-center mb-4" id="deletePublishedText">
            Do you want to delete this request?
          </h3>
        </div>

        <div
          class="modal-footer d-flex justify-content-center align-items-center p-0 m-0"
        >
          <div
            class="d-flex justify-content-center align-items-center p-0 m-0 w-100"
          >
            <base-button
              half
              @click="deleteItem"
              class="mb-0 yes"
              button-color="darkBG"
              >Yes</base-button
            >
            <button
              class="d-none"
              ref="yesBtn"
              data-bs-toggle="modal"
              data-bs-target="#successfullyDeleted"
            ></button>
            <base-button
              half
              class="mb-0 no"
              button-color="darkBG"
              data-bs-dismiss="modal"
              >No</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- starting  successfully deleted  published  modal  -->
  <!-- Modal -->
  <div
    class="modal fade"
    id="successfullyDeleted"
    tabindex="-1"
    aria-labelledby="successfullyDeleted"
    aria-hidden="true"
    ref="successfullyMessage"
  >
    <div class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-body">
          <h3 class="modal-title text-center m-4" id="successfullyDeletedText">
            Your request has been successfully deleted
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
components: { Loading, }
<script setup>
import PublishOption from "../components/PublishOption.vue";
import { onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import serverError from "@/components/composable/useServerError";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import { useAccessToken } from "@/components/composable/useAccessToken";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import axios from "axios";
import { useRoute } from "vue-router";
import router from "@/router";
const pageTitle = "Published";
// const props = defineProps(['']);
const store = useStore();
//eslint-disable-next-line
var { accessToken, authenticated } = useAccessToken(store);
const route = useRoute();
const isLoading = ref(false);

var items = reactive({ ad: [], kind: "Rental" });
const yesBtn = ref(null);
const successfullyMessage = ref(null);

const goToSpecialRoute = () => {
  router.push({
            name: `My${items.kind}s`,
          });
  // router.go(-1); 
};

function loadItem(id) {
isLoading.value = true;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/profile-rentals-request/show/${id}`,
  })
    .then(function (response) {
      if (response.status === 200) {
        // 
        items.ad = response.data;
        items.kind = capitalize(items.ad.rental.kind);
        // sendAlert("data loaded ", "success");
        isLoading.value = false;

        // item.value = response.data;
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      // isLoading.value = false;
      isLoading.value = false;

      
      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
function deleteItem() {
  isLoading.value = true;

  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/profile-rentals-request/delete/${route.params.id}`,
  })
    .then(function (response) {
      if (response.status === 200) {
        // 
        sendAlert("successfully deleted ", "success");
        isLoading.value = false;
        yesBtn.value.click();
        setTimeout(() => {
          successfullyMessage.value.click();
          router.push({
            name: `My${items.kind}s`,
          });
        }, 2000);
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      // isLoading.value = false;
      isLoading.value = false;

      
      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}

onMounted(() => {
  loadItem(route.params.id);
});
</script>

<style lang="scss" scoped>
.isPublished {
  h3 {
    color: #5d9600;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    color: #000;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.waiting {
  h3 {
    color: #e10000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    color: #000;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.mainContent {
}
#deletePublished {
  h3 {
    width: 100%;
    height: 18px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 50px 17px;
  }

  
  /deep/ .mainBtn.yes {
    border-radius: 0;
    border-bottom-left-radius: 15px;
    width: initial !important;
    padding: 12px 87px !important;
    flex: 1;
  }

  /deep/ .mainBtn.no {
    border-radius: 0;
    border-bottom-right-radius: 15px;
    background: #e3e3e3 !important;
    color: #000;
    width: initial !important;
    padding: 12px 76px !important;
    flex: 1;
  }
}

#successfullyDeletedText {
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
