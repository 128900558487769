<template>
  <loading :active="isLoading" :is-full-page="true"></loading>

  <div class="page position-relative h-100" style="min-height: 100vh">
    <base-header
      :pageTitle="pageTitle"
      :leftArrowClick="goToSpecialRoute"
    ></base-header>
    <!-- <base-nav></base-nav> -->
    <div class="h-100 d-flex justify-content-end">
      <div class="settingBox w-50 px-4 py-4 d-flex justify-content-end gap-3">
        <a @click="removeAllNotifs"
          ><img src="../assets/images/delete.svg" alt=""
        /></a>
        <div class="dropdown">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            id="tuneDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src="../assets/images/tune.svg" alt="Tune" />
          </button>
          <ul
            class="dropdown-menu"
            aria-labelledby="tuneDropdown"
            style="width: 50px !important"
          >
            <li>
              <a class="dropdown-item" @click="sortByTime">By Date</a>
            </li>
            <!-- <li>
              <a class="dropdown-item" @click="sortByContent">By Content</a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="items?.notifs?.length ==0"
      class="d-flex w-100 justify-content-center align-items-center noElementsToShow"
    >
      <h4 class="text-center">No Older Notifications</h4>
    </div>
    <div class="mainContent">
      <div v-for="notifGroup in items.notifs" :key="notifGroup.date">
        <div class="dateHeader" style="font-size: 11px !important">
          <h4>{{ formatDateHeader(notifGroup.date) }}</h4>
        </div>
        <!-- <single-notif
          v-for="notif in notifGroup.notifications"
          :key="notif.id"
          :notif="notif"
        ></single-notif> -->
        <single-notif
          v-for="notif in notifGroup.notifications"
          :key="notif.id"
          :notif="notif"
        ></single-notif>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import SingleNotif from "../components/SingleNotif.vue";
import serverError from "@/components/composable/useServerError";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
import router from "@/router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
export default {
  components: {
    SingleNotif,
    Loading,
  },
  setup() {
    const store = useStore();
    //eslint-disable-next-line
    var { accessToken, authenticated } = useAccessToken(store);
    // Using ref to create reactive state
    const pageTitle = ref("Notification");
    var items = reactive({
      notifs: [],
      dummyNotifs: [
        {
          date: "2024-01-20",
          notifications: [
            {
              id: 1,
              created_at: "2024-01-20T10:50:17.000000Z",
              updated_at: null,
              title: "hi",
              desc: "hi bye",
              url: "google.com",
              user_id: 6,
            },
          ],
        },
        {
          date: "2024-01-11",
          notifications: [
            {
              id: 2,
              created_at: "2024-01-11T00:00:00.000000Z",
              updated_at: null,
              title: "byebyebye",
              desc: "hi byebyebyebye",
              url: "yaboo.com",
            },
          ],
        },
      ],
    });
    const isLoading = ref(false);

    function goToSpecialRoute() {
      router.push({ name: "TheAdvertisements" });
    }

    function loadNotifs() {
      // if (authenticated) {
      // Loading.pulse();
      isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/notifications/all",
      })
        .then(function (response) {
          // Loading.remove();
          isLoading.value = false;

          if (response.status === 200) {
            //
            // sendAlert("notifs loaded ", "success");
            items.notifs = response.data;
          }

          // items.first_name = response.data.first_name;
          // items.last_name = response.data.last_name;
          // items.address = response.data.address;
          // items.city = response.data.city;
          // items.phone = response.data.phone;
          // items.avatar_URL=response.data.
        })
        .catch(function (error) {
          // //Loading.remove();
          isLoading.value = false;

          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }
    function formatDateHeader(date) {
      const today = new Date().toISOString().split("T")[0];
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
        .toISOString()
        .split("T")[0];

      if (date === today) {
        return "Today";
      } else if (date === yesterday) {
        return "Yesterday";
      } else {
        return date;
      }
    }
    function removeAllNotifs() {
      items.notifs = [];
      sendAlert("All notifications removed", "success");
    }

    function sortByTime() {
      // Implement logic to sort notifications by time
      items.notifs.sort((a, b) => new Date(b.date) - new Date(a.date));
      sendAlert("Notifications sorted by time", "success");
    }

    // function sortByContent() {
    //   items.notifs.sort((a, b) => a.content.localeCompare(b.content));

    // }
    onMounted(() => {
      loadNotifs();
    });
    return {
      pageTitle,
      goToSpecialRoute,
      loadNotifs,
      items,
      formatDateHeader,
      removeAllNotifs,
      // sortByContent,
      sortByTime,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.settingBox {
  .dropdown {
    button {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
    }
    ul {
      max-width: 90px !important;
      li a {
        font-family: Inter;
        font-size: 12px;
        color: #6f6e6e;
        font-weight: 400;
      }
    }
  }
}
.mainContent {
  .dateHeader {
    margin-left: 20px;
    margin-top: 20px;

    color: #9f9f9f;
    font-family: Inter;
    font-size: 11px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
  }
}
.dropdown-menu.show {
  width: 50px !important;
}
.noElementsToShow {
  color: #969696;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
