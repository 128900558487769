<template>
  <main class="mt-10 mainContent d-flex flex-column ">
    <div
      v-if="contacts?.length == 0"
      class="d-flex w-100 justify-content-center align-items-center noElementsToShow mt-5"
    >
      <h4 class="text-center">No Older Chats So far</h4>
    </div>
    <template v-for="(contact, x) in contacts" :key="x">
      <the-contact :contact="contact" ></the-contact>
    </template>
  </main>
</template>

<script>
import TheContact from "./TheContact.vue";
import {onMounted} from 'vue'
export default {
  components: TheContact,
  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },
  setup(props){
    onMounted(()=>{
      
    })
    return props.contacts
  }
};
</script>
<style lang="scss" scoped >
.mainContent{
  border-radius: 50px 50px 0px 0px;
  /* background-color: gray; */
  overflow: hidden;
  gap: 1px;
}
.noElementsToShow {
  color: #969696;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>