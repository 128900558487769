<template>
  <div>
<h1> About Us page in profile menu </h1>
  </div>
</template>


<script setup>



</script>

<style lang="scss" scoped>

</style>
