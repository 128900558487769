<template>
  <div v-if="items.reqStep == 'sendRental'" class="page position-relative">
    <loading :active="isLoading" :is-full-page="true"></loading>

    <base-header
      :pageTitle="pageTitle"
      :leftArrowClick="goToSpecialRoute"
    ></base-header>

    <!-- profileBox  -->

    <div class="requestDetails">
      <button
        class="categoryBtn mb-3"
        @click="items.reqStep = 'requestCategory'"
      >
        <span v-if="items.selectedCategory == null" class="text">
          Select the category</span
        >
        <span v-else class="text"> {{ items.selectedCategory.title }}</span>
        <span class="img"
          ><img src="../../assets/images/Forward.svg" alt=""
        /></span>
      </button>

      <span class="inputLable">Title:</span>
      <div class="base-form title">
        <textarea
          style="resize: none"
          ref="titleRef"
          type="text"
          class="form-control title"
          v-model="items.title"
          @input="autoExpandInput(titleRef)"
        ></textarea>
      </div>

      <span class="inputLable">Description:</span>
      <textarea
        ref="descRef"
        v-model="items.desc"
        rows="8"
        class="base-form form-control desc"
        @input="autoExpandInput(descRef)"
      ></textarea>
      <span class="inputLable">Upload photos :</span>

      <upload-image
        @setuploadedFile="setuploadedFile"
        @setuploadedImg="setuploadedImg"
        :gallery="loadedGallery"
      ></upload-image>
      <!-- <span class="inputLable">Enter the days you want to rent:</span> -->

      <div class="mt-2 calendarBox">
        <div class="reservationStatus">
          <!-- <p v-if="!date[0] || !date[1]" class="text-danger fw-bold">
            The reservation date has not been selected
          </p> -->
        </div>
        <VueDatePicker
          :min-date="new Date()"
          :model-value="date"
          @update:model-value="setDate"
          readonly
          inline
          range
          auto-apply
          :preset-dates="presetDates"
          month-name-format="short"
          :enable-time-picker="false"
        >
        </VueDatePicker>
        <!-- :disabled-dates="disabledDates" -->

        <span class="inputLable mt-2">Rental fee per day($): </span>
        <div class="base-form">
          <input
            type="number"
            class="form-control text-center"
            v-model="items.price_per_day"
          />
        </div>
      </div>

      <div class="mapBox">
        <div style="height: 135px" class="mt-2">
          <LMap v-model:zoom="zoom" :center="getMapCenter">
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ></l-tile-layer>
            <!-- <l-control-layers /> -->
            <l-marker
              :lat-lng="getMapCenter"
              draggable
              @moveend="afterMoveMarker"
            >
              <l-tooltip> your Location</l-tooltip>
            </l-marker>
          </LMap>
        </div>
      </div>

      <!-- <textarea
        class="base-form form-control"
        placeholder="Add Your Place"
      ></textarea> -->
      <base-button
        v-if="route.params.id"
        @click="updateRental"
        class="w-100 mt-3"
        >save</base-button
      >
      <base-button v-else @click="sendRental" class="w-100 mt-3"
        >post</base-button
      >
    </div>
  </div>
  <RequestCategory
    :categories="items.categories"
    v-if="items.reqStep == 'requestCategory'"
    @setCategory="setCategory"
  ></RequestCategory>
  <PublishDone v-if="items.reqStep == 'publishDone'"></PublishDone>
</template>

<script setup>
//eslint-disable-next-line
import { ref, reactive, computed, onMounted } from "vue";
import RequestCategory from "../RequestCategory.vue";
// import PublishWaiting	 from "../PublishWaiting.vue";
import PublishDone from "../PublishDone.vue";
import axios from "axios";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet";
//eslint-disable-next-line
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
import router from "@/router";
import { useRoute } from "vue-router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

const store = useStore();
//eslint-disable-next-line
var { accessToken, authenticated } = useAccessToken(store);

const pageTitle = ref("Post a Rental");
const titleRef = ref(null);
const descRef = ref(null);
const disableDates = ref([null, null]);
const route = useRoute();
const items = reactive({
  reqStep: "sendRental",
  selectedCategory: null,
  selectedCategoryId: "",
  categories: [],
  title: "",
  desc: "",
  price_per_day: "",
  images: [null, null, null, null, null, null],
  files: [null, null, null, null, null, null],
  lat: 0,
  lng: 0,
  isEdit: false,
  booked_date_range_reserved: [],
  highlightedDates: { from: null, to: null, color: "red" },
});

function goToSpecialRoute() {
  if (items.isEdit)
    // router.push({ name: "MyRentalPage", params: { id: route.params.id } });
    router.push({
      name: "PublishDone",
      params: { id: route.params.id, kind: "rental" },
    });
  else router.push({ name: "TheAdvertisements" });
}
// const startDate = new Date(items.rental.start_day_rent);
// const endDate = new Date(items.rental.end_date_rent);
// date.value = [startDate, endDate];

const zoom = ref(10);
const date = ref([new Date(), null]);
const isLoading = ref(false);

// const handleDateRangeSelection = (value) => {
//

//   date.value = value;
// };
function autoExpandInput(inputRef) {
  const input = inputRef;

  // Reset the height to auto to get the correct scrollHeight
  input.style.height = "24px";

  input.style.height = `${input.scrollHeight}px`;
}

function setDate(value) {
  date.value = value;
}
const convertStringToDate = (dateString) => {
  const date = new Date(dateString);
  return date;
};
const presetDates = ref([
  { label: "Today", value: [new Date(), new Date()] },
  {
    label: "Today (Slot)",
    value: [new Date(), new Date()],
    slot: "preset-date-range-button",
  },
]);

// const getMapCenter = computed(() => {
//   const lat = parseFloat(items.rental.locations_lat);
//   const lng = parseFloat(items.rental.locations_lng);
//   if (!isNaN(lat) && !isNaN(lng)) {
//     return [lat, lng];
//   } else {
//     return [0, 0];
//   }
// });

function setCategory(category) {
  items.selectedCategory = category;
  items.reqStep = "sendRental";
}

function setuploadedImg(img, index) {
  items.images[index] = img;
}
function setuploadedFile(file, index) {
  items.files[index] = file;
}
function validateData() {
  if (!items.selectedCategory) {
    sendAlert("the category field is requierd", "error");
    return false;
  }
  // if (!date.value[0] || !date.value[1]) {
  //   sendAlert("the date field is requierd", "error");
  //   return false;
  // }
  return true;
}
function sendRental() {
  // items.reqStep = "publishDone";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  isLoading.value = true;

  if (!validateData()) {
    isLoading.value = false;

    return;
  }


//  start_day_rent: formatDate(date.value[0]),
//       end_date_rent: formatDate(date.value[1]),

  axios({
    method: "POST",
    url: "https://api.atdeloop.com/api/profile-rentals-request/store",
    data: {
      category_id: items.selectedCategory.id,
      title: items.title,
      phone: "09121234567",
      description: items.desc,
      price_per_day: items.price_per_day,
      locations_address: "00",
      kind: "rental",
      start_day_rent: '',
      end_date_rent: '',
      locations_lat: items.lat,
      locations_lng: items.lng,
      gallery: items.images.filter((item) => item !== null),
    },
  })
    .then((response) => {
      if (response.status === 200) {
        sendAlert("rental posted ", "success");
        isLoading.value = false;

        router.push({
          name: "MyRentals",
        });
        // router.push({
        //   name: "PublishDone",
        //   params: { id: route.params.id, kind: "rental" },
        // });
        // items.reqStep = "publishDone";
        // items.bankName = response.data.bank_Card.bank;
      }
    })
    .catch((error) => {
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
function updateRental() {
  isLoading.value = true;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  if (!validateData()) {
    isLoading.value = false;

    return;
  }

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/profile-rentals-request/edit/${route.params.id}`,
    //  data: formData,
    data: {
      category_id: items.selectedCategory.id,
      title: items.title,
      phone: "09121234567",
      description: items.desc,
      price_per_day: items.price_per_day,
      locations_address: "00",
      kind: "rental",
      // start_day_rent: formatDate(date.value[0]),
      // end_date_rent: formatDate(date.value[1]),
      start_day_rent: '',
      end_date_rent: '',
      locations_lat: items.lat,
      locations_lng: items.lng,
      gallery: items.images.filter((item) => item !== null),
    },
  })
    .then((response) => {
      if (response.status === 200) {
        sendAlert("rental posted ", "success");
        isLoading.value = false;

        router.push({
          name: "PublishDone",
          params: { id: route.params.id, kind: "rental" },
        });
        // router.push({
        //   name: "MyRentalPage",
        //   params: { id: route.params.id },
        // });
        // items.bankName = response.data.bank_Card.bank;
      }
    })
    .catch((error) => {
      isLoading.value = false;

      // //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
// function formatDate(date) {
//   const options = {
//     year: "numeric",
//     month: "2-digit",
//     day: "2-digit",
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit",
//     timeZone: "UTC",
//   };

//   const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
//   return formattedDate.replace(
//     /(\d+)\/(\d+)\/(\d+), (\d+:\d+:\d+) (AM|PM)/,
//     "$3-$1-$2 $4"
//   );
// }
function afterMoveMarker(e) {
  items.lat = e.target._latlng.lat;
  items.lng = e.target._latlng.lng;
}
function getCurrentLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        items.lat = latitude;
        items.lng = longitude;
      },
      (error) => {
        console.error("Error getting current location:", error.message);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser");
  }
}
const getMapCenter = computed(() => {
  var lat = parseFloat(items.lat);
  var lng = parseFloat(items.lng);

  // Check if both latitude and longitude are valid numbers
  if (!isNaN(lat) && !isNaN(lng)) {
    return [lat, lng];
  } else {
    // Return a default center or handle invalid values as needed
    return [0, 0];
  }
});
const loadedGallery = computed(() => {
  return items.images;
});

function loadItem(id) {
  isLoading.value = true;

  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: `https://api.atdeloop.com/api/profile-rentals-request/show/${id}`,
  })
    .then(function (response) {
      isLoading.value = false;
      if (response.status === 200) {
        //
        // sendAlert("data loaded ", "success");
        items.selectedCategoryId = response.data.rental.category_id;
        items.title = response.data.rental.title;
        items.desc = response.data.rental.description;
        items.price_per_day = response.data.rental.price_per_day;
        // items.images = response.data.gallery;
        items.images = response.data.rental.gallery
          .slice(0, 6)
          .concat(
            Array(6 - Math.min(response.data.rental.gallery.length, 6)).fill(
              null
            )
          );
        items.lat = response.data.rental.locations_lat;
        items.lng = response.data.rental.locations_lng;
        date.value = [
          convertStringToDate(response.data.rental.start_day_rent),
          convertStringToDate(response.data.rental.end_date_rent),
        ];
        items.booked_date_range_reserved =
          response.data.booked_date_range_reserved;
        items.highlightedDates.from = new Date(
          items.booked_date_range_reserved.start_date_at
        );
        items.highlightedDates.to = new Date(
          items.booked_date_range_reserved.end_date_at
        );
        disableDates.value = [
          items.highlightedDates.from,
          items.highlightedDates.to,
        ];
        // const startDate = new Date(items.rental.start_day_rent);
        // const endDate = new Date(items.rental.end_date_rent);
        date.value = [items.highlightedDates.from, items.highlightedDates.to];
        // autoExpandInput(titleRef);
        // autoExpandInput(descRef);
        loadCategory();
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      // isLoading.value = false;
      isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}
function loadCategory() {
  // if (authenticated) {
  // Loading.pulse();
  // isLoading.value = true;
  // console.log("Loading category");

  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  axios({
    method: "POST",
    url: "https://api.atdeloop.com/api/category",
  })
    .then(function (response) {
      // Loading.remove();
      // isLoading.value = false;

      if (response.status === 200) {
        //
        // sendAlert("category loaded ", "success");

        items.categories = response.data;
        items.selectedCategory = response.data[items.selectedCategoryId - 1];
      }
    })
    .catch(function (error) {
      // //Loading.remove();
      // isLoading.value = false;

      //sendAlert(error.response.data, "error");
      serverError(error);
    });
}

onMounted(() => {
  getCurrentLocation();
  if (route.params.id) {
    pageTitle.value = "Edit Rental";
    items.isEdit = true;

    // Do something when there is an 'id' parameter in the route

    // Example: Fetch additional data based on the id parameter
    loadItem(route.params.id);
  } else loadCategory();
});
</script>

<style lang="scss" scoped>
.requestDetails {
  // border-radius: 50px 50px 0px 0px;
  background: #fff;
  box-shadow: 4px 0px 250px 0px rgba(0, 0, 0, 0.25);
  padding: 34px 35px 30px 35px;

  .categoryBtn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-radius: 30px;
    height: 48px;
    padding: 19px;
    border: none;

    background: linear-gradient(4deg, #bdbcbc 2.92%, #ebe8e8 118.17%);
    .text {
      color: #4d4d4d;
      font-size: 12px;
      font-weight: 500;
    }
    .img {
      margin-right: -8px;
    }
  }
  .inputLable {
    display: block;
    margin-bottom: 10px;
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-weight: 500;
  }
}

.imgWrapper {
  border-radius: 100%;
}
.page {
  // height: 926px;
  background: #fafafa;
}

.menuContent {
  height: 75vh;
  background-color: aqua;
}

.base-form {
  display: flex;
  width: 100%;
  min-height: 47px;
  margin-bottom: 10px;
}
.form-control {
  border-radius: 30px;
  background: #dedcdc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset;
  color: #959595;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.calendarBox {
  width: 100%;
  .reservationStatus {
    color: #000;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    p {
      margin: 10px 0;
    }
  }
  /deep/ {
    .dp__menu.dp__relative {
      border: none;
    }
    .dp__menu_content_wrapper {
      flex-direction: column;
      .dp--preset-dates {
        display: flex;
        flex-direction: row;
        button,
        span {
          font-size: 10px;
        }
      }
    }
    .dp__month_year_row {
      border-radius: 20px 20px 0px 0px;
      background: #cacaca;
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 24px;
    }

    .dp__outer_menu_wrap {
      border-radius: 20px 20px 0px 0px;
    }

    .dp__menu.dp__relative {
      border-radius: 20px;
    }

    .dp__month_year_wrap {
      height: 24px;

      button {
        max-height: 24px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }

    .dp__calendar_header_item {
      height: initial !important;
    }

    .dp__calendar_header {
      color: #000;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .dp__instance_calendar {
      border-radius: 20px;
    }

    .dp__outer_menu_wrap {
      width: 100%;
    }

    .dp__menu_inner {
      padding: 0;
      background: transparent transparent;
      border: none none;
    }

    .dp__calendar {
      background: #dedede;
      border-radius: 0px 0px 20px 20px;
      padding-bottom: 7px;

      .dp__calendar_item {
        display: flex;
        justify-content: center;
      }

      .dp__cell_inner {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        /* border: 1px solid red !important; */
        background-color: #fff !important;
        color: #747474;
        font-family: Inter;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .dp__cell_disabled {
        position: relative;
        &::after {
          content: "";
          width: 1px;
          height: 100%;
          transform: rotate(45deg);
          background-color: #747474;
          position: absolute;
          opacity: 0.5;
          // background-color: rgb(4, 0, 255);
        }
      }

      .dp__today {
        background-color: rgb(51, 255, 0) !important;
        color: white;
        cursor: pointer;
      }

      .dp__range_end,
      .dp__range_start {
        background-color: rgb(255, 217, 0) !important;
                background-color: #CB0909 !important;

        color: white;
      }

      .dp__range_between {
        background-color: rgb(255, 217, 0) !important;
                background-color: #CB0909 !important;

        color: white;
      }

      .dp__cell_offset {
        color: #cccaca !important;
      }

      .dp__action_row {
        display: none !important;
      }
    }
    .dp__btn.dp--preset-range {
      position: absolute !important;
      width: 40px;
      height: 20px;
      z-index: 999999;
      top: 10px;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
    }
  }
}
.mapBox {
  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    border-radius: 10px;
  }

  .leaflet-container {
    border: 1px solid #cccccc;
  }
}
textarea {
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 16px;
  min-height: 48px;
  height: 48px;
  max-height: 68px;
}
textarea.desc {
  min-height: 156px;
  max-height: 210px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 16px;
}
textarea.title {
  color: #969696;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
