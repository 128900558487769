<template>
  <div class="page position-relative h-100">
    <loading :active="isLoading" :is-full-page="true"></loading>

    <base-header
      :pageTitle="pageTitle"
      :leftArrowClick="goToSpecialRoute"
    ></base-header>
    <div
      v-if="!items.rentals?.length"
      class="d-flex w-100 justify-content-center align-items-center noElementsToShow mt-5"
    >
      <h4 class="text-center">No Older rentals</h4>
    </div>
    <div class="mainContent">
      <div class="rentals-box">
        <div
          :key="index"
          @click="goToBookedRental(rental.rental_id)"
          v-for="(rental, index) in items.rentals"
                    v-show="rental?.rental?.kind == 'rental' && rental.rental"

        >
          <SingleRental
          
            :key="index"
            :title="rental.rental?.title"
            :number="rental.rental?.number"
            :day="rental.rental?.human_readable_date"
            :amount="rental.rental?.amount"
            :image="rental.rental?.image"
            :rental="rental.rental"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<!-- v-for="(rental, index) in items.rentals.data" :key="index" -->

<script>
import SingleRental from "./SingleRental.vue";
import { ref, onMounted, reactive } from "vue";
import serverError from "@/components/composable/useServerError";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
import router from "@/router";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  components: {
    SingleRental,
    Loading,
  },
  setup() {
    const store = useStore();
    //eslint-disable-next-line
    var { accessToken, authenticated } = useAccessToken(store);
    const pageTitle = ref("RentalsBookedByMe");

    const rentals = ref([
      {
        title: "DEWALT ATOMIC 20V MAX* Oscillating Tool",
        number: "1",
        day: "9",
        amount: "800",
        image: "../../assets/images/rental-item.png",
      },
      {
        title: "DEWALT ATOMIC 20V MAX* Oscillating Tool",
        number: "2",
        day: "9",
        amount: "700",
        image: "../../assets/images/rental-item.png",
      },
      {
        title: "DEWALT ATOMIC 20V MAX* Oscillating Tool",
        number: "3",
        day: "9",
        amount: "600",
        image: "../../assets/images/rental-item.png",
      },
      {
        title: "DEWALT ATOMIC 20V MAX* Oscillating Tool",
        number: "4",
        day: "9",
        amount: "500",
        image: "../../assets/images/rental-item.png",
      },
    ]);
    const isLoading = ref(false);

    var items = reactive({ rentals: [] });
    function goToSpecialRoute() {
      router.push({ name: "TheProfile" });
    }
    function loadRentals() {
      // if (authenticated) {
      // Loading.pulse();
      isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/book/book-sends",
      })
        .then(function (response) {
          // Loading.remove();
          isLoading.value = false;

          if (response.status === 200) {
            //
            // sendAlert("rentals loaded ", "success");
            items.rentals = response.data;
          }

          // items.first_name = response.data.first_name;
          // items.last_name = response.data.last_name;
          // items.address = response.data.address;
          // items.city = response.data.city;
          // items.phone = response.data.phone;
          // items.avatar_URL=response.data.
        })
        .catch(function (error) {
          // //Loading.remove();
          isLoading.value = false;

          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }
    function goToStatus(id, index) {
      store.commit("setCurrentItem", items.rentals.data[index]);
      // router.push({
      // name: 'MyRentalPage', params: { id: id }
      // });
      router.push({
        name: "PublishDone",
        params: { id: id, kind: "rental" },
      });
    }
    function goToBookedRental(id) {
      router.push({ name: "bookedRental", params: { id: id } });
    }

    //  name: "MyRentalPage",
    // path: "/MyRequest/:id",
    // component: MyRentalPage,
    onMounted(() => {
      loadRentals();
    });

    return {
      pageTitle,
      rentals,
      loadRentals,
      items,
      goToStatus,
      goToSpecialRoute,
      isLoading,goToBookedRental
    };
  },
};
</script>

<style lang="scss" scoped>
.mainContent {
  overflow: hidden;
  background: #f9f9f9;
  height: 100%;
  .rentals-box {
    display: flex;
    flex-direction: column;
    min-height: 377px;
  }
}
.noElementsToShow {
  color: #969696;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
