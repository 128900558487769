<!-- TheAdvertisements.vue  -->
<template>
  <div class="page position-relative h-100" style="min-height: 100vh">
    <base-header :pageTitle="pageTitle" :noButton="true"></base-header>
    <loading :active="isLoading" :is-full-page="true"></loading>

    <!-- <base-nav></base-nav> -->
    <div class="mainContent">
      <!-- <div
        class="search-container d-flex justify-content-between align-items-center w-100"
      >
        <div>
          <span class="search-text me-2">Search</span>
        </div>

        <base-form search class="search-form"></base-form>
        <router-link :to="{ name: 'TheNotifications' }">
          <span class="search-right-icon"
            ><img src="../assets/images/bell.svg" alt=""
          /></span>
        </router-link>
      </div> -->
      <SearchBar :isBell="true" @search="searchAction" />

      <div class="advertiseFeatureCarousel pt-2 px-4">
        <carousel :items-to-show="5">
          <slide
            style="cursor: pointer"
            class="flex-column gap-2"
            v-for="(category, index) in items.categories"
            @click="setSelectedCategory(category.id)"
            :key="index"
          >
            <div class="feature">
              <div
                class="featureImgContainer"
                :class="{
                  selectedfeature: items.selectedCategory == category.id,
                }"
              >
                <img class="img-fluid" :src="category.img_url" alt="" />
              </div>
            </div>

            <span
              class="featureTitle"
              :class="{ featuretitle: items.selectedCategory == category.id }"
              >{{ category.title }}</span
            >
          </slide>

          <template #addons>
            <navigation>
              <button
                class="carousel__prev"
                @click="goTo(currentSlide - 1)"
                :disabled="currentSlide === 0"
              >
                Previous
              </button>
              <button
                class="carousel__next"
                @click="goTo(currentSlide + 1)"
                :disabled="currentSlide === totalSlides - 1"
              >
                Next
              </button>
            </navigation>
          </template>
        </carousel>
      </div>

      <div class="d-flex mt-3 gap-1 px-3 mb-1">
        <base-button
          class="halfBtn"
          @click="selectBox('rentals')"
          :isSecondary="selectedBox !== 'rentals'"
        >
          Rentals
        </base-button>
        <base-button
          class="halfBtn"
          @click="selectBox('request')"
          :isSecondary="selectedBox !== 'request'"
        >
          Requests
        </base-button>
      </div>

      <div v-show="selectedBox === 'rentals'" class="rentals-box">
        <!-- <router-link :to="{ name: 'TheRental', params: { id: rental.id } }"> -->
        <!-- <router-link
            v-for="(request, index) in items.filteredRequests"
            :key="index"
            :to="{ name: 'TheRental', params: { id: request.id } }"
          > -->

        <div
          v-if="items.filteredRentals?.length == 0"
          class="d-flex w-100 justify-content-center align-items-center noElementsToShow mt-5"
        >
          <h4 class="text-center">No Rentals Found</h4>
        </div>

        <router-link
          v-for="(rental, index) in items.filteredRentals"
          :key="index"
          :to="
            myId != rental.user_id
              ? { name: 'TheRental', params: { id: rental.id } }
              : { name: 'MyRentalPage', params: { id: rental.id } }
          "
          v-show="
            items.selectedCategory == '' ||
            items.selectedCategory == rental.category_id
          "
        >
          <SingleRentalAdvertise
            :key="index"
            :title="rental.title"
            :number="rental.number"
            :day="rental.day"
            :amount="rental.amount"
            :image="rental.image"
            :rental="rental"
          />
        </router-link>
        <!-- </router-link> -->
      </div>

      <div v-show="selectedBox === 'request'" class="requests-box">
        <div
          v-if="items.filteredRequests?.length == 0"
          class="d-flex w-100 justify-content-center align-items-center noElementsToShow mt-5"
        >
          <h4 class="text-center">No Requests found</h4>
        </div>
        <!-- <router-link :to="{ name: 'ChatPage', params: { cid: botId } }"> -->
        <router-link
          v-for="(request, index) in items.filteredRequests"
          :key="index"
          :to="
            myId != request.user_id
              ? { name: 'TheRequest', params: { id: request.id } }
              : { name: 'MyRequestPage', params: { id: request.id } }
          "
          v-show="
            items.selectedCategory == '' ||
            items.selectedCategory == request.category_id
          "
        >
          <SingleRequestAdvertise
            :key="index"
            :title="request.title"
            :number="request.number"
            :day="request.day"
            :image="request.image"
            :notifs="request.notifs"
            :request="request"
          />
          <!-- {
    name: "TheRequest",
    path: "/request/:id",
    component: SingleRequestPage,
  }, -->
        </router-link>
      </div>
    </div>
  </div>
  <base-nav></base-nav>
</template>

<script>
import SingleRentalAdvertise from "../pages/rental/SingleRentalAdvertise.vue";
import SingleRequestAdvertise from "../pages/request/SingleRequestAdvertise.vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import SearchBar from "./chat/components/SearchBar.vue";
import { useRoute } from "vue-router";

// eslint-disable-next-line
// import { useStore } from "vuex";
import axios from "axios";
import { useAccessToken } from "@/components/composable/useAccessToken";
import { useStore } from "vuex";
//eslint-disable-next-line
import sendAlert from "@/components/composable/useSendAlert";
import serverError from "@/components/composable/useServerError";
// eslint-disable-next-line
import { ref, reactive, onMounted, computed } from "vue";
import { useContacts } from "./chat/stores/contacts";
//loading component imports
import "vue-loading-overlay/dist/css/index.css";
import Loading from "vue-loading-overlay";

// import { useAccessToken } from "@/components/composable/useAccessToken";

export default {
  components: {
    SingleRentalAdvertise,
    SingleRequestAdvertise,
    Carousel,
    Slide,
    Navigation,
    //loading should add
    SearchBar,
    Loading,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    //creating loading variable
    const isLoading = ref(false);
    //eslint-disable-next-line
    var { accessToken, authenticated } = useAccessToken(store);
    const pageTitle = ref("Advertisements");
    // eslint-disable-next-line
    // var { accessToken, authenticated } = useAccessToken(store);
    const selectedBox = ref("rentals");
    const myId = computed(() => {
      return store.state.userId;
    });

    var items = reactive({
      rentals: [],
      requests: [],
      categories: [],
      selectedCategory: "",
      filteredRentals: [],
      filteredRequests: [],
      hasSelectedCategory: true,

      first_name: "",
      last_name: "",
      avatar: null,
      city: "",
      phone: "",
      address: "",
      avatar_file: null,
      avatar_URL: null,
    });
    const filteredRentals = computed(() => {
      return items.rentals.data.filter(
        (rental) =>
          rental.category_id == items.selectedCategory ||
          items.selectedCategory == ""
      );
    });

    const filteredRequests = computed(() => {
      return items.requests.data.filter(
        (request) =>
          request.category_id == items.selectedCategory ||
          items.selectedCategory == ""
      );
    });
    const searchAction = () => {
      const currentKeyword = computed(() => useContacts().currentKeyword);
      items.filteredRentals = filteredRentals.value.filter((rental) =>
        rental.title.toLowerCase().includes(currentKeyword.value.toLowerCase())
      );
      items.filteredRequests = filteredRequests.value.filter((request) =>
        request.title.toLowerCase().includes(currentKeyword.value.toLowerCase())
      );
    };
    const selectBox = (box) => {
      selectedBox.value = box;
    };
    function setSelectedCategory(selectedId) {
      if (selectedId == items.selectedCategory){
        items.selectedCategory = "";
        route.params.cid = items.selectedId
      } 
      else items.selectedCategory = selectedId;
    }

    function loadData(kind) {
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      //first loading is true
      isLoading.value = true;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/main-rentals-request",
        data: {
          kind: kind,
        },
      })
        .then(function (response) {
          //then loading is false

          isLoading.value = false;

          if (response.status === 200) {
            //
            // sendAlert("data loaded ", "success");
            if (kind == "rental") {
              items.rentals = response.data;
              items.filteredRentals = response.data.data;
            } else {
              items.requests = response.data;
              items.filteredRequests = response.data.data;
            }
          }
        })
        .catch(function (error) {
          //here loading is false
          isLoading.value = false;

          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }
    function loadCategory() {
      // if (authenticated) {
      // Loading.pulse();
      // isLoading.value = true;

      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      axios({
        method: "POST",
        url: "https://api.atdeloop.com/api/category",
      })
        .then(function (response) {
          // Loading.remove();
          // isLoading.value = false;

          if (response.status === 200) {
            //
            // sendAlert("category loaded ", "success");

            items.categories = response.data;
            if (route.params) {
              items.selectedCategory = route.params.cid || '';
              selectedBox.value = route.params.type || 'rentals';
            }

            store.commit("setCategories", items.categories);
          }
        })
        .catch(function (error) {
          // //Loading.remove();
          // isLoading.value = false;

          //sendAlert(error.response.data, "error");
          serverError(error);
        });
    }

    function loadEditedProfile() {
      if (authenticated) {
        // Loading.pulse();
        isLoading.value = true;

        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;

        axios({
          method: "POST",
          url: "https://api.atdeloop.com/api/profile/load",
        })
          .then(function (response) {
            // Loading.remove();
            isLoading.value = false;

            if (response.status === 200) {
              //
              // sendAlert("Profile loaded ", "success");
              store.commit("setProfile", response.data);
            }

            items.first_name = response.data.first_name;
            items.last_name = response.data.last_name;
            items.address = response.data.address;
            items.city = response.data.city;
            items.phone = response.data.phone;

            items.avatar_URL = response.data.profile_photo;
          })
          .catch(function (error) {
            // //Loading.remove();
            isLoading.value = false;

            //sendAlert(error.response.data, "error");
            serverError(error);
          });
      }
    }

    onMounted(() => {
      loadEditedProfile();
      loadData("rental");
      loadData("request");
      loadCategory();
    });

    return {
      pageTitle,
      selectedBox,

      selectBox,
      loadData,
      items,
      filteredRentals,
      searchAction,
      filteredRequests,
      setSelectedCategory,
      isLoading,
      myId,
    };
  },
};
</script>

<style lang="scss" scoped>
.mainContent {
  overflow: hidden;
  background: #f9f9f9;
  height: 100%;
  /deep/ .carousel__next {
    right: -8% !important;
    top: 25%;
    width: 16px;
    height: 16px;
  }
  /deep/ .carousel__prev {
    left: -8% !important;
    top: 25%;
    width: 16px;
    height: 16px;
  }

  .advertiseFeatureCarousel {
    background-color: #dddcdc;
    border-radius: 0px 0px 30px 30px;
    .feature {
      gap: 10px;
      max-width: 52px;
      max-height: 52px;
      .featureImgContainer {
        background-color: white;
        border-radius: 100%;
        padding: 6px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        img {
          width: 29px;
          height: 29px;
        }
      }
    }
    .featureTitle {
      color: #000;
      font-family: Inter;
      font-size: 10px;
      font-weight: 500;
      text-align: center;
      max-width: 59px;
      min-height: 22px;
    }
  }
  .selectedfeature {
    background: #74ba00 !important;
    font-weight: 700;
  }
  .featuretitle {
    font-weight: 700 !important;
  }
  .search-container {
    padding: 19px 16px 25px;
  }
  /* .search-form {
    position: relative;
  }
  .search-icon {
    position: absolute;
    left: 65px;
  } */
  .search-text {
    color: #afafaf;
    font-size: 11px;
    font-weight: 500;
  }
  .base-form {
    max-width: 262px;
    height: 24px;
    min-height: initial;
    margin: 0;
  }
  .rentals-box {
    display: flex;
    flex-direction: column;
  }
  .search-right-icon {
    width: 38px;
    margin-right: -8px;
  }
  .halfBtn.mainBtn {
    width: 50%;
    height: 30px;
    min-height: initial;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    text-shadow: initial;
  }
}

.requests-box {
  min-height: 377px;
}
.noElementsToShow {
  color: #969696;
  font-family: Inter;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
