<!-- SearchBar.vue  -->
<template>
  <!-- <main
    class="w-full px-2 rounded-xl overflow-hidden text-gray-100 flex mt-8 bg-secondary"
  >
    <span class="p-3 w-1/12"><i class="fa fa-search"></i></span>
    <input
      @input="setKeyword"
      v-model="keyword"
      type="search"
      placeholder="search"
      class="w-11/12 px-2 bg-secondary rounded-xl ring-secondary"
    />
  </main> -->
  <div
    class="search-container d-flex justify-content-between align-items-center w-100"
  >
    <div>
      <span class="search-text me-2">Search</span>
    </div>

    <!-- <base-form search class="search-form"></base-form> -->
    <div class="position-relative d-flex flex-grow-1">
      <input
        class="base-form form-control"
        @input="setKeyword"
        v-model="keyword"
        type="search"
        :placeholder="placeholderText"
      />
      <span class="searchIcon" v-if="isSearch"
        ><img src="../../../assets/images/Search.svg" alt=""
      /></span>
    </div>
    <!-- <span class="search-icon"
          ><img src="../assets/images/Search.svg" alt=""
        /></span> -->
    <span v-if="isBell" class="search-right-icon">
 
      <router-link
        to="/Notifications"
        name="TheNotifications"
        component="TheNotifications"
      >
        <img src="../../../assets/images/bell.svg" alt="" />
      </router-link>
    </span>
    <span v-else class="search-right-icon">
      <img src="../../../assets/images/threeDots.svg" alt="" />
    </span>
  </div>
</template>

<script>
import { useContacts } from "../stores/contacts";

export default {
  props: {
    isBell: {
      type: Boolean,
      default: true, // Default to true if not provided
    },
  },
  setup(props, { emit }) {
    const state = useContacts();

    const setKeyword = (event) => {
      state.setCurrentKeyword(event.target.value);
      emit("search"); // Access emit from the setup function argument
      
    };

    return {
      keyword: state.keyword,
      setKeyword,
    };
  },
};
</script>
<style lang="scss" scoped >
.base-form {
  display: flex;
  width: 100%;
  min-height: 42px;
  margin-bottom: 10px;
}

.half-width {
  width: 48.5%;
}
.form-control {
  border-radius: 30px;
  background: #dedcdc;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25) inset;
  color: #959595;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.toggle-password {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: 0;
  padding-left: 0;
  margin-left: 0;

  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2) inset,
    0px 0px 0px 0px rgba(0, 0, 0, 0.2);

  color: #959595;
  background: #dedcdc;
}

input[type="password"] {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.inputWhileClicked {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.base-form .searchInput {
  position: relative;
}
.searchIcon {
  position: absolute;
  left: 0;
  transform: translateX(50%);
}
</style>
<style lang="scss" scoped >
.search-container {
  padding: 19px 0px 25px 15px;
  gap: 12px;
  position: sticky;
  /* top: 48px; */
  background: white;
  z-index: 1000;
}
/* .search-form {
    position: relative;
  }
  .search-icon {
    position: absolute;
    left: 65px;
  } */
.search-text {
  color: #afafaf;
  font-size: 11px;
  font-weight: 500;
}
.base-form {
  max-width: 262px;
  height: 24px;
  min-height: initial;
  margin: 0;
}
.rentals-box {
  display: flex;
  flex-direction: column;
  min-height: 377px;
}
.search-right-icon {
  width: 38px;
  margin-right: -8px;
}
</style>
