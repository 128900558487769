<template>
  <div class="homePage section">
    <router-link :to="{ name: 'TheHome' }">
      <img class="logoText" src="../../assets/logoPlusText.svg" />
    </router-link>
    <!-- <h1 class="text-center mt-5">Welcome to atdeloop</h1> -->

    <div
      class="d-flex justify-content-center flex-column align-items-center btnGroup w-100"
    >
      <base-form email :placeholder="'Email Address'" />
      <base-form password :placeholder="'password'"></base-form>
      <base-button>sign in</base-button>

      <router-link :to="{ name: 'TheVerify' }">
        <a class="forgotPassword" @click="showForgetPasswordDialog"
          >Forget Password?</a
        >
      </router-link>
      <!-- <base-dialog
        :isVisible="isDialogVisible"
        @close="hideForgetPasswordDialog"
      /> -->

      <div
        class="d-flex justify-content-center flex-column align-items-center w-100 signInWith-wrapper"
      >
        <p>Or sign in with</p>
        <div class="d-flex flex-row justify-content-around w-100 signInWith">
          <div class="signInImgWrapper">
            <a> <img src="../../assets/images/Google Plus.svg" alt="" /></a>
          </div>
          <div class="signInImgWrapper">
            <a><img src="../../assets/images/Facebook.svg" alt="" /></a>
          </div>
        </div>
        <p class="haveAccount mt-4">
          Dont have an account?
          <router-link :to="{ name: 'SignUpAuth' }">
            <span> <a class="signUpText" href="">Sign up</a></span>
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>



<style lang="scss" scoped >
.signUpText {
  color: #458200;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.haveAccount {
  color: #000;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signInWith-wrapper {
  margin-top: 60px;
}
.signInWith {
  img {
    max-width: 34px;
  }
  .signInImgWrapper {
    display: flex;
    width: 77px;
    height: 44px;
    border-radius: 8px;
    background: #d9d9d9;
    align-items: center;
    justify-content: center;
  }
}

a.forgotPassword {
  margin-top: 20px;
  color: #000;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline !important;
}
p {
  color: #000;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.homePage {
  /* border: 1px solid black; */
  position: relative;
  /* height: 926px; */
  min-height: 100vh;
  .logoText {
    position: absolute;
    top: 15%;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    width: 118px;
  }

  .btnGroup {
    position: absolute;
    bottom: 208px;
    right: 50%;
    transform: translateX(50%);
    max-width: 277px;
    max-height: 42px;
  }
}
input::placeholder {
  color: #959595;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
