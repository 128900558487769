<template>
  <div class="profileMenu">
    <ul class="list-unstyled">
      <li v-for="(item, index) in menuItems" :key="index">
        <MenuItem
          @click="goToThisPage(item.name)"
          :imgPath="getImagePath(item.imgName)"
          :title="item.name"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MenuItem from "./MenuItem.vue";
import { useStore } from "vuex";

// import router from "@/router";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    MenuItem,
  },
  setup() {
    const store = useStore();

    const router = useRouter();
    // Define your menu items with names and associated image names
    const menuItems = [
      { name: "My Wallet", imgName: "your-wallet" },
      { name: "Rentals Booked By Me", imgName: "rentals-booked-by-me" },
      { name: "Requests Booked By Me", imgName: "requests-booked-by-me" },
      { name: "My Rentals", imgName: "my-rentals" },
      { name: "My Requests", imgName: "my-requests" },
      { name: "Transactions", imgName: "transactions" },
      { name: "About Us", imgName: "about-us" },
      { name: "Terms and Conditions", imgName: "terms" },
      { name: "Saved Items", imgName: "saved-items" },
      { name: "Exit", imgName: "exit" },
    ];

    // Function to get the image path based on image name
    const getImagePath = (imgName) => {
      return require(`@/assets/images/profile-menu/${imgName}.svg`); // Assuming you're using Vue CLI and have set up aliases
    };
    function goToThisPage(name) {
      if (name == "Exit") {
        store.commit("setAuth", false);
      }
      router.push({
        name: name.replace(/\s/g, ""),
      });
    }

    return {
      menuItems,
      getImagePath,
      goToThisPage,
    };
  },
});
</script>

<style lang="scss" scoped>
.profileMenu {
  border-radius: 50px 50px 0px 0px;
  background: #fff;
  box-shadow: 4px 0px 250px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 20px 30px 20px;

  ul li {
    border-bottom: solid 2px #747474;
    cursor: pointer;

    
  }
  ul li:last-child {
    border-bottom: none;
  }
}
</style>
