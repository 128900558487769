// router.js 
import { createStore } from 'vuex';
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";

var ls = new SecureLS({ isCompression: false });

const store = createStore({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state() {
    return {
      // accessToken: "${accessToken}",
      accessToken: "",
      // accessToken: "61|2QhkMp1JloHYTWZFMs1w4fYCV7KHkmJHQZrGBaCT8e5b6eea",
      auth: false,
      user: {
        email: "fake@gmail.com",
        password: "aaaaaaaa",
      },
      userId: "",
      profile: {
        first_name: "",
        last_name: "",
        avatar_file: null,
        avatar_URL: null,
        profile_photo: null,
        city: "",
        phone: "",
        address: "",
      },
      currentItem: null,
      categories: [],
      // selectedCategory:null
    };
  },
  mutations: {
    setAuth(state, status) {
      state.auth = status;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setToken(state, token) {
      state.accessToken = token;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    setCurrentItem(state, currentItem) {
      state.currentItem = currentItem;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
  },
});




export default store;
